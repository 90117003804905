import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import { login, resetAuthErr, resetFormsAndErrors } from "store/actions";
import eyeImg from "assets/Eye.svg";
import { delToken } from "utils";
import { resetTransactionsData } from "store/actions";
import SinglePageLayout from "layouts/SinglePageLayout";

const authState = (state) => state.Authentication;

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, loginForm, userTrialText } = useSelector(authState);

  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const formRef = useRef(null);

  const validateEmail = (e) => {
    if (!e) return "Please enter your email.";

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(e)) return "Please enter a valid email address.";

    return "";
  };

  const validatePassword = (e) => {
    return e ? "" : "Please enter your password.";
  };

  const handleOnChangeInput = (e, field) => {
    if (error) dispatch(resetAuthErr());

    const { value } = e;
    const newFormErrors = { ...errors };

    if (field === "email") {
      setEmail(value);
      newFormErrors.email = "";
    } else if (field === "password") {
      setPassword(value);
      newFormErrors.password = "";
    }
    setErrors(newFormErrors);
  };

  const handleSignIn = () => {
    const emailValidationResult = validateEmail(email);
    const passwordValidationResult = validatePassword(password);
    setErrors({
      email: emailValidationResult,
      password: passwordValidationResult,
    });

    if (emailValidationResult || passwordValidationResult) {
      if (!email) emailRef.current.focus();
      else if (!password) passwordRef.current.focus();
      return null;
    }

    dispatch(login({ email, password, rememberMe }));
  };

  useEffect(() => {
    dispatch(resetFormsAndErrors());
    dispatch(resetTransactionsData());
    delToken();
    delToken("signUpToken");
  }, []);

  useEffect(() => {
    if (loginForm) {
      setTimeout(() => {
        navigate("/authentication/sign-up");
      }, 1000);
    }
  }, [loginForm]);

  useEffect(() => {
    if (userTrialText === "Admin authorized") {
      navigate("/admin");
    } else if (userTrialText === "User authorized") {
      navigate("/");
    } else if (userTrialText) {
      setTimeout(() => {
        dispatch(resetFormsAndErrors());
      }, 1500);
    }
  }, [userTrialText]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <SinglePageLayout>
      <Grid container>
        <Grid mx={{ sm: "auto" }} maxWidth={{ xs: "100%", sm: "446px" }} width="100%">
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "30px" },
              backgroundColor: "transparent",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <MDBox>
              <img
                src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
                style={{
                  height: "120px",
                  width: "245px",
                }}
              />
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography
                variant="h4"
                color="#3B3229"
                sx={{ fontWeight: 400 }}
                fontSize="16px"
              >
                Log in to your account to access thousands <br /> of aircraft
                transactional data{" "}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ maxWidht: "446px", width: "100%" }}>
              <MDBox
                component="form"
                role="form"
                onSubmit={handleSignIn}
                ref={formRef}
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <MDBox>
                  <MDTypography
                    variant="h2"
                    sx={{ fontWeight: 700 }}
                    color="#3B3229"
                    fontSize="14px"
                    mb={1}
                  >
                    Email
                  </MDTypography>

                  <MDInput
                    inputRef={emailRef}
                    autoFocus
                    onKeyPress={handleKeyPress}
                    className={
                      errors.email !== "" ? "redBorder" : "transparentBorder"
                    }
                    type="email"
                    placeholder="email_address@email.com"
                    variant="standard"
                    fullWidth
                    value={email}
                    onChange={(e) => handleOnChangeInput(e.target, "email")}
                  />
                </MDBox>
                <MDBox>
                  <MDTypography
                    variant="h2"
                    fontWeight="bold"
                    color="#3B3229"
                    fontSize="14px"
                    my={1}
                  >
                    Password
                  </MDTypography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MDInput
                      inputRef={passwordRef}
                      onKeyPress={handleKeyPress}
                      className={
                        errors.password !== ""
                          ? "redBorder"
                          : "transparentBorder"
                      }
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Type Here"
                      fullWidth
                      variant="standard"
                      value={password}
                      onChange={(e) =>
                        handleOnChangeInput(e.target, "password")
                      }
                    />
                    <img
                      src={eyeImg}
                      onClick={() => togglePasswordVisibility()}
                      style={{
                        cursor: "pointer",
                        zIndex: 999,
                        position: "absolute",
                        right: "5%",
                      }}
                    />
                  </div>
                </MDBox>
                <Grid container alignItems="center">
                  <Grid item xs={6} sm={6} md={8}>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                      <MDTypography
                        variant="h2"
                        color="#3B3229"
                        fontSize={{ xs: "10px", md: "14px" }}
                        onClick={handleSetRememberMe}
                        sx={{
                          fontWeight: 400,
                          cursor: "pointer",
                          userSelect: "none",
                          ml: 1,
                        }}
                      >
                        Must 12 character long minimum
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Link to="/authentication/forgot-password">
                      <MDTypography
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          textAlign: "right",
                          textDecoration: "none",
                          fontWeight: 700,
                        }}
                        fontSize={{ xs: "10px", md: "14px" }}
                        variant="body1"
                        color="#3B3229"
                      >
                        Forgot password
                      </MDTypography>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDButton
                variant="gradient"
                className="no-hover-color-change"
                color="baseColor"
                fullWidth
                onClick={handleSignIn}
                sx={{
                  textTransform: "none",
                  padding: "13px 20px",
                  borderRadius: "4px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                Login
                {loading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </MDButton>
            </MDBox>
            <MDBox>
              <MDTypography
                variant="h4"
                color="#3B3229"
                sx={{ fontWeight: 400 }}
                fontSize="14px"
              >
                Don’t have an account?{" "}
                <Link to="/authentication/sign-up">
                  <span
                    style={{
                      color: "#3B3229",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    Sign Up
                  </span>
                </Link>
              </MDTypography>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </SinglePageLayout>
  );
}

export default Basic;
