import { useEffect, useMemo, useState } from "react";
import SinglePageLayout from "layouts/SinglePageLayout";
import { Grid } from "@mui/material";
import PricingCards from "components/Subscription";
import LenderCard from "components/LenderCard";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import infinityIcon from "assets/Infinite.svg";
import satrIcon from "assets/telePhone.svg";
import csvIcon from "assets/csvIcon.svg";
import CustomStepper from "components/CustomStepper";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlans } from "store/actions";
import { authState, planState } from "store/states";
import { loggedInUser } from "utils";

function UserBasedPlans({ isNotLender, prices }) {
  if (isNotLender) {
    return (
      <MDBox
        sx={{
          maxWidth: "446px",
          width: "100%",
          backgroundColor: "#FFFFFF66",
          borderRadius: "16px",
          padding: "20px",
          mb: "16px",
        }}
      >
        <>
          <MDBox pt={{ md: 1 }} display="flex" alignItems="center">
            <img
              src={infinityIcon}
              alt="Infinty Icon"
              className="subscriptions-icon"
            />
            <div style={{ lineHeight: "20px" }}>
              <MDTypography
                ml={1}
                color="#3B3229"
                variant="h5"
                fontSize={{ xs: "14px", md: "16px" }}
                sx={{ fontWeight: 500 }}
              >
                Endless Insights
              </MDTypography>
              <MDTypography
                ml={1}
                variant="p"
                fontSize={{ xs: "12px", md: "14px" }}
                color="#202C39"
                sx={{ fontWeight: 400 }}
              >
                Unlimited Search on Aircraft Pricing
              </MDTypography>
            </div>
          </MDBox>
          <MDBox mt="16px" display="flex" alignItems="center">
            <img
              src={satrIcon}
              alt="satrIcon Icon"
              style={{ width: "24px", height: "24px" }}
              className="subscriptions-icon"
            />
            <div style={{ lineHeight: "20px" }}>
              <MDTypography
                ml={1}
                color="#3B3229"
                variant="h5"
                fontSize={{ xs: "14px", md: "16px" }}
                sx={{ fontWeight: 500 }}
              >
                Above the Market
              </MDTypography>
              <MDTypography
                ml={1}
                variant="p"
                fontSize={{ xs: "12px", md: "14px" }}
                color="#202C39"
                sx={{ fontWeight: 400 }}
              >
                View a Variety of Aircraft Pricing Trends{" "}
              </MDTypography>
            </div>
          </MDBox>
          <MDBox mt="16px" display="flex" alignItems="center">
            <img
              src={csvIcon}
              alt="CSV Icon"
              style={{ width: "24px", height: "24px" }}
              className="subscriptions-icon"
            />
            <div style={{ lineHeight: "20px" }}>
              <MDTypography
                ml={1}
                color="#3B3229"
                variant="h5"
                fontSize={{ xs: "14px", md: "16px" }}
                sx={{ fontWeight: 500 }}
              >
                CSV Export{" "}
              </MDTypography>
              <MDTypography
                ml={1}
                variant="p"
                fontSize={{ xs: "12px", md: "14px" }}
                color="#202C39"
                sx={{ fontWeight: 400 }}
              >
                Get Your Comprehensive Aircraft Reports{" "}
              </MDTypography>
            </div>
          </MDBox>
        </>
      </MDBox>
    );
  }

  return (
    <MDBox my={{ md: 2 }}>
      <LenderCard prices={prices} />
    </MDBox>
  );
}

function Subscription({ activeStep, setStep, labels }) {
  const dispatch = useDispatch();
  const { curUserInfo } = useSelector(authState);
  const { plans } = useSelector(planState);
  const isNotLender = curUserInfo.job_title !== "Lender";

  const allPrices = useMemo(() => {
    return plans.map((plan) => ({
      price_id: plan.id,
      title: plan.lookup_key,
      amount: plan.unit_amount / 100,
    }));
  }, [plans]);

  useEffect(() => {
    if (activeStep === 3 && !plans?.length) {
      dispatch(fetchPlans());
    }
  }, []);

  const userData = loggedInUser("signUpToken");
  const isTrialAcquired = !!userData.trial_end;

  return (
    <SinglePageLayout>
      <Grid
        container
        display="flex"
        flexDirection="column"
        m={{ sm: "auto" }}
        maxWidth={{ xs: "100%", sm: "446px" }}
        width="100%"
        height="100vh"
      >
        <Card
          sx={{
            margin: "auto 0",
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px", md: "30px" },
            backgroundColor: "transparent",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MDBox sx={{ textAlign: "center" }}>
            <img
              src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
              style={{
                height: "120px",
                width: "245px",
              }}
            />
          </MDBox>
          <MDBox textAlign="center">
            {isTrialAcquired ? (
              <MDTypography
                variant="h4"
                color="#3B3229"
                sx={{ fontWeight: 400 }}
                fontSize="16px"
              >
                Sign up for <strong>a premium account</strong> to get instant
                access
                <br />
                or choose a monthly subscription for premium features.
              </MDTypography>
            ) : (
              <MDTypography
                variant="h4"
                color="#3B3229"
                sx={{ fontWeight: 400 }}
                fontSize="16px"
              >
                Sign up for a{" "}
                <span style={{ fontWeight: 700 }}>7 day trial</span> to get
                instant access
                <br />
                by entering your following details below.
              </MDTypography>
            )}
          </MDBox>
          <MDBox sx={{ width: "446px" }}>
            <CustomStepper activeStep={activeStep} labels={labels} />
          </MDBox>
          {curUserInfo?.job_title ? (
            <MDBox sx={{ width: "100%", maxWidth: { xs: "446px" } }}>
              <UserBasedPlans isNotLender={isNotLender} prices={allPrices} />
              <MDBox>
                <PricingCards isNotLender={isNotLender} prices={allPrices} />
              </MDBox>
            </MDBox>
          ) : null}
          <MDBox sx={{ textAlign: "center", margin: "-20px 0 10px 0" }}>
            <MDTypography
              fontWeight="400"
              variant="p"
              color="#202C39"
              sx={{
                fontSize: "14px",
              }}
            >
              Change or cancel your plan anytime.
            </MDTypography>
          </MDBox>
        </Card>
      </Grid>
    </SinglePageLayout>
  );
}

export default Subscription;
