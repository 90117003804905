import { useDispatch, useSelector } from "react-redux";
import { createPortalSession, toggleUpgradeModal } from "store/actions";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";
import { planState } from "store/states";
import LenderCard from "components/LenderCard";

export default function UpgradeSubscriptionModal() {
  const dispatch = useDispatch();
  const { upgradeModal } = useSelector(planState);
  const toggleModal = (val) => dispatch(toggleUpgradeModal(val));
  const lenderPrices = [
    { price_id: 1, amount: 89 },
    { price_id: 2, amount: 99 },
  ];

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={upgradeModal}
        onClose={() => toggleModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Fade in={upgradeModal}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              py: 1,
              border: "none",
              borderRadius: "12px",
              width: { xs: "370px", md: "520px" },
              outline: "none",
            }}
          >
            <MDBox variant="gradient" textAlign="center" position="relative">
              {" "}
              <MDBox sx={{ fontSize: "20px" }}>
                <Icon
                  onClick={() => toggleModal(false)}
                  sx={{
                    position: "absolute",
                    right: "8px",
                    color: "#2C241C",
                    cursor: "pointer",
                  }}
                >
                  close
                </Icon>
              </MDBox>
              <MDTypography
                variant="h4"
                sx={{
                  color: "#2C241C",
                  fontSize: { xs: "21px", md: "24px" },
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Upgrade Your Subscription
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                color="#2C241C"
                fontSize={{ xs: "13px", md: "14px" }}
                my={{ md: 1 }}
                sx={{
                  lineHeight: { xs: "16px", md: "17px" },
                  fontWeight: 400,
                }}
                mx={{ xs: 3, md: 2 }}
              >
                Sign up for
                <span style={{ color: "#2C241C", fontWeight: 700 }}>
                  {" "}
                  a premium account
                </span>{" "}
                <br />
                to get instant access of all features.
              </MDTypography>
            </MDBox>
            <MDBox py={{ xs: 1, md: 2 }} px={{ xs: 2 }} ml={{ sm: "20px" }}>
              <LenderCard
                prices={lenderPrices}
                onClick={() => dispatch(createPortalSession())}
              />
            </MDBox>
            <MDBox
              sx={{
                textAlign: "center",
                margin: { xs: "-10px 0 0 0" },
              }}
            >
              <MDTypography
                fontWeight="400"
                variant="p"
                color="#2C241C"
                sx={{
                  fontSize: "13px",
                }}
              >
                Change or cancel your plan anytime.
              </MDTypography>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </div>
  );
}
