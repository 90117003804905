import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import { useEffect } from "react";
// Otis Admin PRO React example components
import DefaultPricingCard from "components/PricingCard/index";
import { useDispatch, useSelector } from "react-redux";
import {
  startTrial,
  createCheckoutSession,
  resetFormsAndErrors,
} from "store/actions";
import { useNavigate } from "react-router-dom";
import { loggedInUser } from "utils";
import { Box, Button, CircularProgress } from "@mui/material";
import MDTypography from "components/MDTypography";

const authState = (state) => state.Authentication;

function PricingCards({ prices, isNotLender }) {
  const navigate = useNavigate();
  const premiumPrice = prices.find((price) => price.amount === 25) || {};
  const dispatch = useDispatch();
  const { loading, trialLoading, error, subscriptionText } =
    useSelector(authState);

  const gotoLogin = (url) => {
    setTimeout(
      () => navigate(url || "/authentication/sign-in", { replace: true }),
      1000
    );
  };

  useEffect(() => {
    if (subscriptionText === "Trial started") {
      setTimeout(() => {
        dispatch(resetFormsAndErrors());
        gotoLogin("/");
      }, 1200);
    }
  }, [subscriptionText]);

  useEffect(() => {
    if (error) {
      gotoLogin();
    }
  }, [error]);

  const userData = loggedInUser("signUpToken");
  const isTrialAcquired = !!userData.trial_end;

  return (
    <>
      <MDBox zIndex={10}>
        <Grid container spacing={{ xs: "16px", sm: "16px" }} flexDirection="row">
          {!isTrialAcquired && (
            <Grid item xs={isNotLender ? 6 : 12}>
              <Box
                sx={{
                  padding: "24px 0",
                  maxWidth: isNotLender ? "215px" : "100%",
                  width: "100%",
                  backgroundColor: "#564A42",
                  borderRadius: "16px",
                  boxShadow: "0px 20px 40px 0px #3B322933",
                  textAlign: "center",
                }}
                className={isTrialAcquired ? "cursor-not-allowed" : ""}
              >
                <Box
                  sx={{
                    padding: "0px 8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    width="104px"
                    height="28px"
                    mx="auto"
                    sx={{
                      backgroundColor: "#3B32294D",
                      borderRadius: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <MDTypography
                      variant="caption"
                      sx={{ fontSize: "12px", fontWeight: 500 }}
                      color="white"
                    >
                      Free
                    </MDTypography>
                  </Box>
                  <MDTypography
                    variant="caption"
                    sx={{ fontSize: "22px", fontWeight: 700 }}
                    color="white"
                  >
                    7 Day Trial
                  </MDTypography>
                  <Box>
                    <Button
                      mt="8px"
                      onClick={() => {
                        isTrialAcquired ? null : dispatch(startTrial());
                      }}
                      width="133px"
                      fontSize="14px"
                      sx={{
                        fontWeight: 500,
                        backgroundColor: "#3C322D",
                        color: "#FFFFFF",
                        borderRadius: "4px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#3C322D",
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      {trialLoading ? (
                        <CircularProgress size={20} color="white" />
                      ) : (
                        <>Start For Free</>
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={isTrialAcquired ? 12 : 6}>
            {isNotLender && (
              <Box
                sx={{
                  padding: "5px",
                  width: "100%",
                  background:
                    "radial-gradient(circle, rgba(162,135,122,1) 10%, rgba(60,50,45,1) 200%)",
                  borderRadius: "16px",
                  boxShadow: "0px 20px 40px 0px #3B322933",
                  textAlign: "center",
                  maxWidth: isTrialAcquired ? "100%" : "215px",
                }}
                className={isTrialAcquired ? "cursor-not-allowed" : ""}
              >
                <Box
                  sx={{
                    border: "0.25px solid #C8B19C",
                    borderRadius: "12px",
                    padding: "19px 0px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "0px 8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Box
                      width="104px"
                      height="28px"
                      mx="auto"
                      sx={{
                        borderRadius: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        border: "0.25px solid #C8B19C",
                      }}
                    >
                      <MDTypography
                        variant="caption"
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                        color="white"
                      >
                        Premium
                      </MDTypography>
                    </Box>
                    <MDBox>
                      <MDTypography
                        display="inline"
                        component="small"
                        variant="h5"
                        color="#F2F7F2"
                        fontSize="24px"
                        sx={{ fontWeight: 700 }}
                      >
                        ${premiumPrice.amount ? premiumPrice.amount : 25}
                      </MDTypography>
                      <MDTypography
                        display="inline"
                        component="small"
                        sx={{ fontSize: "14px", fontWeight: 400 }}
                        variant="h5"
                        color="#F2F7F2"
                      >
                        /mo
                      </MDTypography>
                    </MDBox>
                    <Box>
                      <Button
                        mt="8px"
                        onClick={() => {
                          dispatch(createCheckoutSession(premiumPrice.price_id));
                        }}
                        width="133px"
                        fontSize={{ xs: "13px", md: "14px" }}
                        sx={{
                          fontWeight: 500,
                          size: { xs: "small", md: "large" },
                          backgroundColor: "#C8B19C",
                          color: "#3C322D",
                          borderRadius: "4px",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#C8B19C",
                            color: "#3C322D",
                          },
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={20} color="white" />
                        ) : (
                          <>Join Premium</>
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.instanceOf(Array).isRequired,
  isNotLender: PropTypes.bool.isRequired,
};

export default PricingCards;
