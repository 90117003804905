import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Box, Checkbox } from "@mui/material";
import MDButton from "components/MDButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalenderIcon from "assets/Calender_Icon.svg";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import searchIconTheme from "assets/searchIconTheme.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState } from "react";
import { TITLE, SUB_TYPES } from "context/constants";
import { CalendarToday, ChevronRight, ExpandMore } from "@mui/icons-material";

function SearchContainer({ filters, setFilters }) {
  const [open, setOpen] = useState({ type: false, status: false });

  return (
    <Grid
      container
      sx={{
        flexDirection: { xs: "column", md: "row" },
        flexWrap: { xs: "wrap", lg: "nowrap" },
      }}
      spacing={2}
      justify="flex-start"
    >
      <Grid item xs={12} md={6} lg={3}>
        <MDBox>
          <MDTypography variant="h6" fontSize="14px"
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              pb: "3px",
              width: "max-content"
            }}
          >
            Created Account Date
          </MDTypography>
          <div class="date-picker-container">
            <DatePicker
              selected={filters.acc_creation_dates?.[0] || null}
              startDate={filters.acc_creation_dates?.[0] || null}
              endDate={filters.acc_creation_dates?.[1] || null}
              maxDate={new Date()}
              onChange={(dates) =>
                setFilters({ ...filters, acc_creation_dates: dates })
              }
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              selectsRange
              placeholderText={"Select Date"}
              className="react-date-picker"
              showIcon
              icon={
                <CalendarToday
                  color="white"
                  sx={{ width: "20px", height: "20px", marginLeft: "8px" }}
                />
              }
            />
          </div>
        </MDBox>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <MDBox>
          <MDTypography variant="h6" fontSize="14px"
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              pb: "3px",
              width: "max-content"
            }}
          >
            Last Account Login
          </MDTypography>
          <div class="date-picker-container">
            <DatePicker
              selected={filters.last_login_dates?.[0] || null}
              startDate={filters.last_login_dates?.[0] || null}
              endDate={filters.last_login_dates?.[1] || null}
              maxDate={new Date()}
              onChange={(dates) =>
                setFilters({ ...filters, last_login_dates: dates })
              }
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              selectsRange
              placeholderText={"Select Date"}
              className="react-date-picker"
              showIcon
              icon={
                <CalendarToday
                  color="white"
                  sx={{ width: "20px", height: "20px", marginLeft: "8px" }}
                />
              }
            />
          </div>
        </MDBox>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <MDBox>
          <MDTypography variant="h6" fontSize="14px"
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              pb: "6px",
            }}
          >
            User Type{" "}
          </MDTypography>
          <Autocomplete
            open={open.type}
            onOpen={() => setOpen({ ...open, type: true })}
            onClose={() => setOpen({ ...open, type: false })}
            value={filters.job_title || ""}
            options={TITLE.slice(1)}
            onInputChange={() => null}
            onChange={(_, newVal) => {
              if (!newVal) return;
              setFilters({ ...filters, job_title: newVal });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="All"
                className="wow2"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <KeyboardArrowDownIcon
                      onClick={() => setOpen({ ...open, type: !open.type })}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color: "white",
                      }}
                    />
                  ),
                  sx: {
                    borderBottom: "none",
                    paddingRight: "8px !important",
                    borderRadius: "4px",
                    paddingLeft: "8px !important",
                    backgroundColor: "#3C322D1A",
                    color: "white !important",
                    "&.Mui-focused": {
                      borderBottom: "none !important",
                      "& .MuiOutlinedInput-notchedOutline, &:after": {
                        borderColor: "transparent",
                      },
                    },
                    "& fieldset": { border: "none" },
                    "& input": {
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 400,
                      "&::placeholder": {
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white !important",
                        opacity: 1,
                      },
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-root, &:before": {
                      borderBottom: "none !important",
                      borderColor: "transparent !important",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            )}
            sx={{ width: "100%" }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MDBox>
          <MDTypography variant="h6" fontSize="14px"
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              pb: "6px",
            }}
          >
            Subs Status{" "}
          </MDTypography>
          <Autocomplete
            open={open.status}
            onOpen={() => setOpen({ ...open, status: true })}
            onClose={() => setOpen({ ...open, status: false })}
            value={filters.sub_type || ""}
            options={SUB_TYPES}
            onInputChange={() => null}
            onChange={(_, newVal) => {
              if (!newVal) return;
              setFilters({ ...filters, sub_type: newVal });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="All"
                className="wow2"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <KeyboardArrowDownIcon
                      onClick={() => setOpen({ ...open, status: !open.status })}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color: "white",
                      }}
                    />
                  ),
                  sx: {
                    borderBottom: "none",
                    paddingRight: "8px !important",
                    borderRadius: "4px",
                    paddingLeft: "8px !important",
                    backgroundColor: "#3C322D1A",
                    color: "white !important",
                    "&.Mui-focused": {
                      borderBottom: "none !important",
                      "& .MuiOutlinedInput-notchedOutline, &:after": {
                        borderColor: "transparent",
                      },
                    },
                    "& fieldset": { border: "none" },
                    "& input": {
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 400,
                      "&::placeholder": {
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white !important",
                        opacity: 1,
                      },
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-root, &:before": {
                      borderBottom: "none !important",
                      borderColor: "transparent !important",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            )}
            sx={{ width: "100%" }}
          />
        </MDBox>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <MDBox>
          <MDTypography variant="h6" fontSize="14px"
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
            }}
          >
            Account Status
          </MDTypography>
          <MDBox sx={{ ml: -1, display : "flex", gap: "8px" }}>
            {" "}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                sx={{
                  cursor: "pointer",
                  "& .MuiSvgIcon-root": {
                    border: "none",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#3C322D24",
                    borderRadius: "4px",
                  },
                }}
                checked={filters.is_active}
                onChange={() =>
                  setFilters({ ...filters, is_active: !filters.is_active })
                }
              />
              <MDTypography
                variant="button"
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  fontWeight: 500,
                }}
              >
                Active
              </MDTypography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                sx={{
                  cursor: "pointer",
                  "& .MuiSvgIcon-root": {
                    border: "none",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#3C322D24",
                    borderRadius: "4px",
                  },
                }}
                checked={filters.is_inactive}
                onChange={() =>
                  setFilters({ ...filters, is_inactive: !filters.is_inactive })
                }
              />
              <MDTypography
                variant="button"
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  fontWeight: 500,
                }}
              >
                Deactive
              </MDTypography>
            </Box>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

const DEFAULT_FILTERS = {
  acc_creation_dates: [],
  last_login_dates: [],
  job_title: "",
  sub_type: "",
  is_active: false,
  is_inactive: false,
};

function ActionButton({ onClick, variant, color, label, className }) {
  return (
    <MDButton
      variant={variant}
      color={color}
      className={className || ""}
      onClick={onClick}
      sx={{
        textTransform: "none",
        color: { color },
        with: "max-content",
        fontSize: { xs: "13px", md: "16px" },
        padding: "8px",
        height: "40px",
        borderRadius: "4px",
        size: { xs: "small", md: "large" },
      }}
    >
      {label}
    </MDButton>
  );
}

function SearchFilter({ gFilters, setGFilters }) {
  const [show, setShow] = useState(true);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const handleClearFilters = () => {
    setFilters(DEFAULT_FILTERS);
    setGFilters({ ...gFilters, ...DEFAULT_FILTERS, page: 1 });
  };

  const handleSaveFilters = () => {
    setGFilters({ ...gFilters, ...filters, page: 1 });
  };

  return (
    <Card sx={{ width: "100%", backgroundColor: "#C8B19C", borderRadius: "4px", padding: {xs: "16px", sm:"24px"}}}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        mr={2}
        alignItems="center"
        sx={{ alignItems: "center", padding: show && {xs: "0 0 16px 0", sm:"0px 0 24px 0px"} }}
      >
        <MDBox display="flex" alignItems="center" gap="5px">
          <MDBox>
            <img
              src={searchIconTheme}
              style={{ display: "block", margin: "auto" }}
            />
          </MDBox>
          <MDTypography
            variant="h6"
            ml={1}
            fontSize={{ xs: "18px", md: "20px" }}
            sx={{ color: "#FFFFFF", fontWeight: 500 }}
          >
            Search Filters
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: "#3C322D1A",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setShow(!show)}
        >
          {show ? <ExpandMore color="white" /> : <ChevronRight color="white" />}
        </MDBox>
      </MDBox>
      {show && (
        <>
          <MDBox container padding="0">
            <SearchContainer filters={filters} setFilters={setFilters} />
          </MDBox>
          <MDBox display="flex" justifyContent="start" container pt={{xs: "16px", sm:"24px"}} gap="16px">
            <MDBox
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <ActionButton
                variant="gradient"
                className="no-hover-color-change"
                color="baseColor"
                label="Apply Filters"
                onClick={handleSaveFilters}
              />
            </MDBox>
            <MDBox
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <ActionButton
                variant="gradient"
                color="baseColorOpacity"
                label="Clear Filters"
                onClick={handleClearFilters}
              />
            </MDBox>
            <Grid
              container
              sx={{ display: { xs: "flex", md: "none" } }}
              justifyContent="flex-end"
            >
              <Grid item xs={12} md={6} alignItems="center">
                <MDBox container display="flex" justifyContent="flex-end" gap="16px">
                  <ActionButton
                    variant="gradient"
                    color="baseColorOpacity"
                    label="Clear Filters"
                    onClick={handleClearFilters}
                  />
                  <ActionButton
                    variant="gradient"
                    className="no-hover-color-change"
                    color="baseColor"
                    label="Apply Filters"
                    onClick={handleSaveFilters}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </Card>
  );
}

export default SearchFilter;
