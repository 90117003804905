import Aircraft from "assets/Aircraft_Icon.svg";

const dropZoneTemplate = `
<div class="drop-zone" style="font-family: Poppins, sans-serif">
  <img src="${Aircraft}" />
  <p>
    Drag and drop your CSV file
    <br/> 
    to view your aircraft analysis.
  </p>

  <div class="browse-btn" style="font-family: Poppins, sans-serif">
    Browse Files
  </div>
</div>`;

export default dropZoneTemplate;
