import { useMemo } from "react";
import PropTypes from "prop-types";

import { Line } from "react-chartjs-2";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CircularProgress from "@mui/material/CircularProgress";

import configs from "./configs";
import stockItem from "assets/stockItem.svg";

function ReportsLineChart({ color, title, description, isStatsExist, chart, avgPrice, statsLoading }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <Card sx={{ height: "100%", backgroundColor: "#C8B19C" }}>
      <MDBox padding={{ xs: "16px", sm: "24px" }}>
        <MDBox display="flex" gap="5px" alignItems="center">
          <MDBox>
            <img src={stockItem} style={{ display: "block", margin: "auto" }} />
          </MDBox>
          <MDTypography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: { xs: "16px", sm: "20px" },
              color: "#FFFFFF",
            }}
          >
            {title}
          </MDTypography>
        </MDBox>
        
        <MDBox display="flex" flexDirection="column" mt="24px">
          <MDTypography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: { xs: "14px", lg: "16px" },
              color: "#3C322D",
            }}
          >
            Average Price
          </MDTypography>
          {isStatsExist && (
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: { xs: "20px", sm: "24px" },
                color: "#FFFFFF",
              }}
            >
              {description}
            </MDTypography>
          )}
        </MDBox>
        {
          useMemo(
            () => (
              <MDBox
                borderRadius="lg"
                coloredShadow="none"
                sx={{ height: "71%" }}
              >
                {
                  statsLoading ? <div style={{ position: 'relative', maxHeight: 300, height: "100vh" }}>
                    {/* Overlay with CircularProgress */}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 9999, // Ensure loader is above other elements
                      }}
                    >
                      <CircularProgress color="inherit" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                    </div>
                  </div> : <Line data={data} options={options} />
                }
              </MDBox>
            ),
            [chart, color, statsLoading]
          )
        }
        <MDBox pt={0.5} px={1}>
          <MDTypography
            variant="h6"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "18px", lg: "21px" },
              color: "#202C39",
            }}
          ></MDTypography>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                color: "#3C322D",
              }}
            >
              {avgPrice}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
  avgPrice: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  avgPrice: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  chart: PropTypes.instanceOf(Object).isRequired,
};

export default ReportsLineChart;
