import { useMemo, useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { unique } from "radash";
import { genPaginationList } from "utils";
import { ExpandMore } from "@mui/icons-material";

function DataTable({
  entriesPerPage,
  showTotalEntries,
  setGFilters,
  table,
  pagination,
  isSorted,
  noEndBorder,
  total,
  filterPage,
  filterPerPage,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "50"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = tableInstance;

  const totalPages = Math.ceil(total / filterPerPage);
  const pageStart = (filterPage - 1) * filterPerPage + 1;
  const pageEnd = Math.min(filterPage * filterPerPage, total);

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setGFilters((oldGFilters) => ({
      ...oldGFilters,
      page: 1,
      per_page: value,
    }));
  };

  useEffect(() => {
    const [sortCol] = sortBy || [];
    const columnMapping = {
      email_address: "email",
      created_date: "created_at",
      user_type: "job_title",
      sub_type: "subscription_type",
      org_name: "organization_name",
      phone_number: "phone_no",
      lastlogin: "last_loggedin_at",
      account_status: "status",
    };
    if (sortCol) {
      setGFilters((oldGFilters) => ({
        ...oldGFilters,
        sort_by: columnMapping[sortCol.id] || sortCol.id,
        sort_order: sortCol.desc ? "desc" : "asc",
      }));
    }
  }, [sortBy])

  const canPreviousPage = filterPage > 1;
  const canNextPage = filterPage < totalPages;

  const shouldRenderPagination = true;

  const renderPagination = genPaginationList(totalPages, filterPage).map(
    (page, i) => {
      const isVisible = page != "...";
      if (isVisible) {
        return (
          <MDPagination
            key={i}
            item
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              borderRadius: "4px",
              color: "#FFFFFF",
              marginRight: "8px !important",
              background: filterPage === page ? "#3B3229" : "#BAA491",
            }}
            onClick={() => {
              gotoPage(page);
              setGFilters((oldGFilters) => ({
                ...oldGFilters,
                page: page,
              }));
            }}
            active={filterPage === page}
          >
            {page}
          </MDPagination>
        );
      } else {
        return (
          <MDPagination
            key={i}
            item
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              borderRadius: "4px",
              color: "#FFFFFF",
              background: "#BAA491",
              marginRight: "8px !important",
            }}
          >
            ...
          </MDPagination>
        );
      }
    }
  );

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <>
      {entriesPerPage ? (
        <MDBox display="flex" justifyContent="end" alignItems="center" py={2}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                className="dropdownFont"
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                popupIcon={
                  <ExpandMore
                    color="white"
                    sx={{ fontWeight: 400, paddingLeft: "8px" }}
                  />
                }
                size="small"
                sx={{ width: "58px" }}
                renderInput={(params) => <MDInput {...params} sx={{ padding: "0 !important" }} />}
              />
              <MDTypography
                variant="p"
                fontWeight="400"
                color="#33312E"
                fontSize="14px"
                sx={{ fontWeight: 500, width: "max-content", color: "#FFFFFF" }}
              >
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <TableContainer
        sx={{
          boxShadow: "none",
          backgroundColor: "transparent !important",
        }}
      >
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(
                      isSorted && column.getSortByToggleProps()
                    )}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody
            {...getTableBodyProps()}
            sx={{ backgroundColor: "#BAA491", borderRadius: "4px !important" }}
          >
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className="hover-highlight">
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{xs: "normal", sm: "space-between" }}
        alignItems={{ xs: "normal", sm: "center" }}
        py={!showTotalEntries && pageOptions.length === 1 ? 0 : 2}
      >
        <MDBox>
          <MDTypography
            variant="button"
            fontWeight="regular"
            fontSize={{ xs: "13px", md: "14px" }}
            sx={{ color: "#FFFFFF" }}
          >
            Showing {pageStart} to {pageEnd} of {(total || 0).toLocaleString()} items
          </MDTypography>
        </MDBox>
        {shouldRenderPagination && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            <MDPagination
              item
              onClick={
                canPreviousPage
                  ? () => {
                      previousPage();
                      setGFilters((oldGFilters) => ({
                        ...oldGFilters,
                        page: oldGFilters.page - 1,
                      }));
                    }
                  : () => null
              }
              sx={{
                background: "#BAA491",
                color: "white !important",
                cursor: canPreviousPage ? "poiner" : "not-allowed",
                marginRight: "8px !important",
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
            {shouldRenderPagination && renderPagination}

            <MDPagination
              item
              onClick={
                canNextPage
                  ? () => {
                      nextPage();
                      setGFilters((oldGFilters) => ({
                        ...oldGFilters,
                        page: oldGFilters.page + 1,
                      }));
                    }
                  : () => null
              }
              sx={{
                background: "#BAA491",
                color: "white !important",
                cursor: canNextPage ? "poiner" : "not-allowed",
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          </MDPagination>
        )}
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [10, 25, 50] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  total: 0,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.instanceOf(Array),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.instanceOf(Object).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "blue",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  total: PropTypes.number,
};

export default DataTable;
