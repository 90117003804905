import { createSlice } from "@reduxjs/toolkit";
import { basicStarter, toggleBoolean } from "utils";

const initialState = {
  loading: false,
  error: null,
  plans: [],
  upgradeModal: false,
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    fetchPlans: basicStarter,
    fetchPlansSuccess: (state, action) => {
      state.loading = false;
      state.plans = action.payload;
    },
    toggleUpgradeModal: toggleBoolean("upgradeModal"),
    error: (state, action) => {
      state.loading = false;
      state.error = action.payload.message || "Internal Server Error";
    },
  },
});

export const actions = planSlice.actions;

export default planSlice.reducer;
