import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";

function DefaultCellDashboard({
  value,
  suffix,
  color = "#FFFFFF",
  fontWeight = 600,
}) {
  return (
    <MDTypography
      variant="caption"
      fontSize="14px"
      color={color}
      sx={{ fontWeight }}
    >
      {value}
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

// Setting default values for the props of DefaultCell
DefaultCellDashboard.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
DefaultCellDashboard.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCellDashboard;
