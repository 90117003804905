import { Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SimplePageLayout from "layouts/SimplePageLayout";
import React from "react";
export default function PrivacyPolicyPage() {
  return (
    <SimplePageLayout>
      <Box>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
            display: "flex",
            justifyContent: "center",
          }}
        >
          ONLINE PRIVACY POLICY AGREEMENT
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC{" "}
          <a
            href="aiclynx.com"
            style={{ color: "#2C241C", fontWeight: "bold" }}
          >
            (AIC Lynx)
          </a>{" "}
          values its users' privacy. This Privacy Policy ("Policy") will help
          you understand how we collect and use personal information from those
          who visit our website or make use of our online facilities and
          services, and what we will and will not do with the information we
          collect. Our Policy has been designed and created to ensure those
          affiliated with AIC Lynx, LLC of our commitment and realization of our
          obligation not only to meet, but to exceed, most existing privacy
          standards.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          We reserve the right to make changes to this Policy at any given time.
          If you want to make sure that you are up to date with the latest
          changes, we advise you to frequently visit this page. If at any point
          in time AIC Lynx, LLC decides to make use of any personally
          identifiable information on file, in a manner vastly different from
          that which was stated when this information was initially collected,
          the user or users shall be promptly notified by email. Users at that
          time shall have the option as to whether to permit the use of their
          information in this separate manner.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          This Policy applies to AIC Lynx, LLC, and it governs any and all data
          collection and usage by us. Through the use of{" "}
          <a
            href="aiclynx.com"
            style={{ color: "#2C241C", fontWeight: "bold" }}
          >
            aiclynx.com
          </a>{" "}
          , you are therefore consenting to the data collection procedures
          expressed in this Policy.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          Please note that this Policy does not govern the collection and use of
          information by companies that AIC Lynx, LLC does not control, nor by
          individuals not employed or managed by us. If you visit a website that
          we mention or link to, be sure to review its privacy policy before
          providing the site with information. It is highly recommended and
          suggested that you review the privacy policies and statements of any
          website you choose to use or frequent to better understand the way in
          which websites garner, make use of and share the information
          collected,
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          Specifically, this Policy will inform you of the following
        </MDTypography>

        <MDBox px={5}>
          <MDTypography
            variant="h6"
            component="ol"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#2C241C",
            }}
          >
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              What personally identifiable information is collected from you
              through our website;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              Why we collect personally identifiable information and the legal
              basis for such collection;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              How we use the collected information and with whom it may be
              shared;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              What choices are available to you regarding the use of your data;
              and
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              The security procedures in place to protect the misuse of your
              information.
            </MDTypography>
          </MDTypography>
        </MDBox>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Information We Collect
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          It is always up to you whether to disclose personally identifiable
          information to us, although if you elect not to do so, we reserve the
          right not to register you as a user or provide you with any products
          or services. This website collects various types of information, such
          as:
        </MDTypography>
        <MDBox px={5}>
          <MDTypography
            variant="h6"
            component="ul"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#2C241C",
            }}
          >
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              Voluntarily provided information which may include your name,
              address, email address, billing and/or credit card information
              etc. which may be used when you purchase products and/or services
              and to deliver the services you have requested.
            </MDTypography>
          </MDTypography>
        </MDBox>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          <b>In addition,</b> AIC Lynx, LLC may have the occasion to collect
          non-personal anonymous demographic information, such as age, gender,
          household income, political affiliation, race and religion, as well as
          the type of browser you are using, IP address, or type of operating
          system, which will assist us in providing and maintaining superior
          quality service.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          Please rest assured that this site will only collect personal
          information that you knowingly and willingly provide to us by way of
          surveys, completed membership forms, and emails. <b>It is</b> the
          intent of{" "}
          <b>this site to use personal information only for the purpose</b> for
          which it was requested, and any additional uses specifically provided
          for on this Policy.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Why We Collect Information and For How Long
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          We are collecting your data for several reasons:
        </MDTypography>
        <MDBox px={5}>
          <MDTypography
            variant="h6"
            component="ul"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#2C241C",
            }}
          >
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              To better understand your needs and provide you with the services
              you have requested;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              To fulfill our legitimate interest in improving our services and
              products;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              To send you promotional emails containing information we think you
              may like when we have your consent to do so;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              To contact you to fill out surveys or participate in other types
              of market research, when we have your consent to do so;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              To customize our website according to your online behavior and
              personal preferences.{" "}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          The data we collect from you will be stored for no longer than
          necessary. The length of time we retain said information will be
          determined based upon the following criteria: the length of time your
          personal information remains relevant; the length of time it is
          reasonable to keep records to demonstrate that we have fulfilled our
          duties and obligations; any limitation periods within which claims
          might be made; any retention periods prescribed by law or recommended
          by regulators, professional bodies or associations; the type of
          contract we have with you, the existence of your consent, and our
          legitimate interest in keeping such information as stated in this
          Policy.
        </MDTypography>

        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Use of Information Collected
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC does not now, nor will it in the future, sell, rent or
          lease any of its customer lists and/or names to any third parties.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC may collect and may make use of personal information to
          assist in the operation of our website and to ensure delivery of the
          services you need and request. At times, we may find it necessary to
          use personally identifiable information as a means to keep you
          informed of other possible products and/or services that may be
          available to you from{" "}
          <a
            href="aiclynx.com"
            style={{ color: "#2C241C", fontWeight: "bold" }}
          >
            aiclynx.com
          </a>
        </MDTypography>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC may also be in contact with you with regards to
          completing surveys and/or research questionnaires related to your
          opinion of current or potential future services that may be offered.
        </MDTypography>

        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Disclosure of Information{" "}
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC may not use or disclose the information provided by you
          except under the following circumstances:
        </MDTypography>
        <MDBox px={5}>
          <MDTypography
            variant="h6"
            component="ul"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#2C241C",
            }}
          >
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              as necessary to provide services or products you have ordered;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              in other ways described in this Policy or to which you have
              otherwise consented;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              in the aggregate with other information in such a way so that your
              identity cannot reasonably be determined;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              as required by law, or in response to a subpoena or search
              warrant;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              to outside auditors who have agreed to keep the information
              confidential;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              as necessary to enforce the Terms of Service;
            </MDTypography>
            <MDTypography
              variant="h6"
              component="li"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#2C241C",
              }}
            >
              as necessary to maintain, safeguard and preserve all the rights
              and property of AIC Lynx, LLC.
            </MDTypography>
          </MDTypography>
        </MDBox>

        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Non-Marketing Purposes
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC greatly respects your privacy. We do maintain and
          reserve the right to contact you if needed for non-marketing purposes
          (such as bug alerts, security breaches, account issues, and/or changes
          in AIC Lynx, LLC products and services). In certain circumstances, we
          may use our website, newspapers, or other public means to post a
          notice.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Children under the age of 13
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC's website is not directed to, and does not knowingly
          collect personal identifiable information from, children under the age
          of thirteen (13). If it is determined that such information has been
          inadvertently collected on anyone under the age of thirteen (13), we
          shall immediately take the necessary steps to ensure that such
          information is deleted from our system's database, or in the
          alternative, that verifiable parental consent is obtained for the use
          and storage of such information. Anyone under the age of thirteen (13)
          must seek and obtain parent or guardian permission to use this
          website.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Unsubscribe or Opt-Out
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          All users and visitors to our website have the option to discontinue
          receiving communications from us by way of email or newsletters. To
          discontinue or unsubscribe from our website please send an email that
          you wish to unsubscribe to{" "}
          <a
            href="mailto:drehrman@aictitle.com"
            style={{ color: "#2C241C", fontWeight: "bold" }}
          >
            drehrman@aictitle.com.
          </a>{" "}
          If you wish to unsubscribe or opt-out from any third-party websites,
          you must go to that specific website to unsubscribe or opt-out. AIC
          Lynx, LLC will continue to adhere to this Policy with respect to any
          personal information previously collected.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Links to Other Websites
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          Our website does contain links to affiliate and other websites. AIC
          Lynx, LLC does not claim nor accept responsibility for any privacy
          policies, practices and/or procedures of other such websites.
          Therefore, we encourage all users and visitors to be aware when they
          leave our website and to read the privacy statements of every website
          that collects personally identifiable information. This Privacy Policy
          Agreement applies only and solely to the information collected by our
          website.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Notice to European Union Users
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC's operations are located primarily in the United States.
          If you provide information to us, the information will be transferred
          out of the European Union (EU) and sent to the United States. (The
          adequacy decision on the EU-US Privacy became operational on August 1,
          2016. This framework protects the fundamental rights of anyone in the
          EU whose personal data is transferred to the United States for
          commercial purposes. It allows the free transfer of data to companies
          that are certified in the US under the Privacy Shield.) By providing
          personal information to us, you are consenting to its storage and use
          as described in this Policy.
        </MDTypography>

        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Security
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC takes precautions to protect your information. When you
          submit sensitive information via the website, your information is
          protected both online and offline. Wherever we collect sensitive
          information (e.g. credit card information), that information is
          encrypted and transmitted to us in a secure way. You can verify this
          by looking for a lock icon in the address bar and looking for "https"
          at the beginning of the address of the webpage.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job (for example, billing
          or customer service) are granted access to personally identifiable
          information. The computers and servers in which we store personally
          identifiable information are kept in a secure environment. This is all
          done to prevent any loss, misuse, unauthorized access, disclosure or
          modification of the user's personal information under our control.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          Acceptance of Terms
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          By using this website, you are hereby accepting the terms and
          conditions stipulated within the Privacy Policy Agreement. If you are
          not in agreement with our terms and conditions, then you should
          refrain from further use of our sites. In addition, your continued use
          of our website following the posting of any updates or changes to our
          terms and conditions shall mean that you agree and acceptance of such
          changes.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            color: "#2C241C",
          }}
        >
          How to Contact Us
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          If you have any questions or concerns regarding the Privacy Policy
          Agreement related to our website, please feel free to contact us at
          the following email, telephone number or mailing address.
        </MDTypography>
        <br />
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          <b>Email:</b> mburget©aictitle.coms
        </MDTypography>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          <b>Telephone Number:</b> 4059481811
        </MDTypography>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          <b>Mailing Address:</b>
        </MDTypography>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          AIC Lynx, LLC
        </MDTypography>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          6350 W Reno Ave
        </MDTypography>
        <MDTypography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#2C241C",
          }}
        >
          Oklahoma City, Oklahoma 73127
        </MDTypography>
        <br />
      </Box>
    </SimplePageLayout>
  );
}
