import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import SearchFilter from "components/SearchFilters";
import DataTable from "components/DataTable";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { fetchUsers } from "store/admin/actions";
import TableCheckBox from "components/TableCheckBox";
import DefaultCell from "components/DefaultCell";
import { debounce } from "radash";
import moment from "moment";
import { updateUser, deleteUser } from "store/actions";
import {
  DATE_FORMAT,
  SHOW_DATE_FORMAT,
  SUB_TYPES_FOR_ADMIN,
} from "context/constants";
import { adminState, globalState } from "store/states";
import DeleteAction from "components/DeleteAction";
import { loggedInUser } from "utils";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserPrompt from "components/UserPrompt";
import { ROLES } from "context/constants";

const TABLE_COLS = (
  updateUserAction,
  deleteUserAction,
  updateUserId,
  delUserId
) => [
  {
    Header: "FIRST NAME",
    accessor: "first_name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "LAST NAME",
    accessor: "last_name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "EMAIL ADDRESS ",
    accessor: "email_address",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "CREATED DATE/TIME ",
    accessor: "created_date",
    width: "200px",
    Cell: ({ value }) => (
      <DefaultCell value={moment(value).format(SHOW_DATE_FORMAT)} />
    ),
  },
  {
    Header: "USER TYPE ",
    accessor: "user_type",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "SUBS STATUS ",
    accessor: "sub_type",
    Cell: ({ value, row }) => {
      const { pk_id: pkId, role } = row.original;
      if (role === ROLES.adm) return "";

      const [open, setOpen] = useState(false);
      const [newVal, setNewVal] = useState("");
      const [isModalOpen, setIsModalOpen] = useState(false);
      const isLoading = pkId === updateUserId;

      return (
        <>
          <UserPrompt
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setNewVal("");
            }}
            onSubmit={() => {
              updateUserAction(pkId, {
                subscription_type: newVal,
              });
            }}
            isSubs={true}
          />
          {isLoading ? (
            <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
          ) : (
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              value={newVal || value || ""}
              options={SUB_TYPES_FOR_ADMIN}
              sx={{ width: "100px" }}
              onInputChange={() => null}
              onChange={(_, val) => {
                if (!val) return;
                setNewVal(val);
                setIsModalOpen(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="All"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <KeyboardArrowDownIcon
                        onClick={() => setOpen({ ...open, type: !open.type })}
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    ),
                    sx: {
                      paddingRight: "0 !important",
                      color: "white !important",
                      "&.Mui-focused": {
                        borderBottom: "1px solid transparent !important",
                      },
                    },
                  }}
                />
              )}
            />
          )}
        </>
      );
    },
  },
  {
    Header: "SUBS START DATE ",
    accessor: "subs_start_date",
    width: "200px",
    Cell: ({ value }) => (
      <DefaultCell value={moment(value).format(SHOW_DATE_FORMAT)} />
    ),
  },
  {
    Header: "ORG NAME ",
    accessor: "org_name",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "ADDRESS ",
    accessor: "address",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "PHONE NUMBER ",
    accessor: "phone_number",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "LAST LOGIN ",
    accessor: "lastlogin",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? moment(value).format(SHOW_DATE_FORMAT) : ""}
      />
    ),
  },
  {
    Header: "ACCOUNT STATUS ",
    accessor: "account_status",
    width: "200px",
    Cell: ({ value, row }) => {
      const pkId = row.original.pk_id;

      return  loggedInUser().id === pkId ? (
        <DefaultCell value={value === "ACTIVE" ? "Active" : "Deactive"} />
      ) : (
        <TableCheckBox
          value={value}
          onClick={() =>
            updateUserAction(row.original.pk_id, {
              status: value === "ACTIVE" ? "IN_ACTIVE" : "ACTIVE",
            })
          }
        />
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ({ row }) => {
      const pkId = row.original.pk_id;

      return loggedInUser().id === pkId ? null : (
        <DeleteAction
          onSubmit={() => deleteUserAction(pkId)}
          loading={pkId === delUserId}
        />
      );
    },
  },
];

function Admin() {
  const dispatch = useDispatch();
  const { loading, delAccId, updateAccId, usersData } = useSelector(adminState);
  const { searchStr } = useSelector(globalState);

  const [filters, setFilters] = useState({ page: 1, per_page: 10 });
  const asyncSetFilters = useRef(debounce({ delay: 500 }, setFilters));

  useEffect(() => {
    const compiledFilters = {
      page: filters.page,
      per_page: filters.per_page,
    };
    if (filters.search) compiledFilters.search = filters.search;
    if (filters.job_title) compiledFilters.job_title = filters.job_title;
    if (filters.sub_type) compiledFilters.subscription_type = filters.sub_type;
    if (filters.is_active && !filters.is_inactive) {
      compiledFilters.status__eq = "ACTIVE";
    } else if (!filters.is_active && filters.is_inactive) {
      compiledFilters.status__eq = "IN_ACTIVE";
    }
    if (filters.last_login_dates?.length) {
      const [startDate, endDate] = filters.last_login_dates;
      if (startDate) {
        compiledFilters.last_loggedin_at__gte =
          moment(startDate).format(DATE_FORMAT);
      }
      if (endDate) {
        compiledFilters.last_loggedin_at__lte = moment(endDate).format(
          DATE_FORMAT + " 23:59:59"
        );
      }
    }

    if (filters.acc_creation_dates?.length) {
      const [startDate, endDate] = filters.acc_creation_dates;
      if (startDate) {
        compiledFilters.created_at__gte = moment(startDate).format(DATE_FORMAT);
      }
      if (endDate) {
        compiledFilters.created_at__lte = moment(endDate).format(
          DATE_FORMAT + " 23:59:59"
        );
      }
    }
    if (filters.sort_by && filters.sort_order) {
      compiledFilters.sort_by = filters.sort_by;
      compiledFilters.sort_order = filters.sort_order;
    }

    dispatch(fetchUsers(compiledFilters));
  }, [filters]);

  useEffect(() => {
    asyncSetFilters.current({
      ...filters,
      search: searchStr,
      page: 1,
    });
  }, [searchStr]);

  const tableRows = useMemo(() => {
    return usersData.data?.map((row) => ({
      pk_id: row.id,
      first_name: row.first_name,
      last_name: row.last_name,
      email_address: row.email,
      created_date: row.created_at,
      user_type: row.role === ROLES.adm ? "Admin" : row.job_title,
      sub_type: row.subscription_type,
      org_name: row.organization_name,
      address: row.address,
      phone_number: row.phone_no,
      lastlogin: row.last_loggedin_at,
      account_status: row.status,
      role: row.role,
    }));
  }, [usersData]);

  return (
    <DashboardLayout>

      <MDBox pb={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          sx={{ alignItems: "center", padding: "10px 0" }}
        >
          <Grid container spacing={2} pb={2}>
            <Grid item xs={6} md={12} lg={4} xl={5}>
              <MDBox>
                <MDTypography
                  variant="h6"
                  sx={{ fontSize: { xs: "24px", md: "32px" },
                    fontWeight: 275,
                    color: "#2C241C",
                  }}
                >
                  User Accounts
                  {loading ? (
                    <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                  ) : null}
                </MDTypography>{" "}
              </MDBox>
            </Grid>
            <Grid item xs={6} md={12} lg={8} xl={7}>
              <MDBox />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container>
          <SearchFilter gFilters={filters} setGFilters={setFilters} />
        </Grid>
        <MDBox mt="2px">
          <Card
            sx={{
              backgroundColor: "#C8B19C",
              borderRadius: "4px",
              padding: { xs: "0 16px", sm: "0 24px" },
            }}
          >
            {tableRows ? (
              <DataTable
                table={{
                  columns: TABLE_COLS(
                    (userId, body) => dispatch(updateUser(userId, body)),
                    (userId) => dispatch(deleteUser(userId)),
                    updateAccId,
                    delAccId
                  ),
                  rows: tableRows,
                }}
                total={usersData.total || 0}
                setGFilters={setFilters}
                filterPage={filters.page}
                filterPerPage={filters.per_page}
              />
            ) : null}
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Admin;
