import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  eventsLoading: false,
  statsLoading: false,
  error: null,
  delTrxId: -1,
  firstLoad: false,
  transactionData: {},
  transactionStats: {},
  showEventModal: false,
  eventAlerts: []
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    getTransactions: (state) => {
      state.loading = true;
      state.error = null;
    },
    getTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.transactionData = action.payload;
    },
    setFirstLoad: (state) => {
      if (!state.firstLoad) state.firstLoad = true;
    },
    deleteTransaction: (state, action) => {
      state.delTrxId = action.payload;
      state.error = null;
    },
    deleteTransactionSuccess: (state, action) => {
      const _index = state.transactionData.data?.findIndex(
        (row) => row.id === action.payload
      );
      if (_index !== -1) state.transactionData.data.splice(_index, 1);

      state.delTrxId = -1;
    },
    getTransactionStats: (state) => {
      state.loading = true;
      state.error = null;
    },
    setStatsLoading: (state, action) => {
      state.statsLoading = action.payload;
      state.error = null;
    },
    getTransactionStatsSuccess: (state, action) => {
      state.loading = false;
      state.transactionStats = action.payload;
    },
    requestTransactionsReport: (state) => {
      state.transactionReportLoading = true;
      state.error = null;
      state.reportId = null;
    },
    requestTransactionsReportSuccess: (state, action) => {
      state.transactionReportLoading = false;
      state.reportId = action.payload.report_id;
    },
    uploadTransactionsReport: (state) => {
      state.error = null;
      state.fileUploading = true;
      state.uploadReportId = null;
    },
    uploadTransactionsReportSuccess: (state, action) => {
      state.fileUploading = false;
      state.uploadReportId = action.payload.task_id;
    },
    resetReportId: (state) => {
      state.reportId = null;
      state.uploadReportId = null;
    },
    getMakers: (state) => {
      state.loading = true;
      state.error = null;
    },
    getMakersSuccess: (state, action) => {
      state.loading = false;
      state.transactionMakers = action.payload;
    },
    getModels: (state) => {
      state.loading = true;
      state.error = null;
    },
    getModelsSuccess: (state, action) => {
      state.loading = false;
      state.transactionModels = action.payload;
    },
    getEventAlerts: (state) => {
      state.eventsLoading = true;
      state.error = null;
      state.showEventModal = true;
    },
    getEventAlertsSuccess: (state, action) => {
      state.eventsLoading = false;
      state.eventAlerts = action.payload;
      state.showEventModal = true;
    },
    getManufacturerYears: (state) => {
      state.transactionManufacturerYears = undefined;
      state.loading = true;
      state.error = null;
    },
    getManufacturerYearsSuccess: (state, action) => {
      state.loading = false;
      state.transactionManufacturerYears = action.payload;
    },
    setManufacturerYears: (state, action) => {
      state.transactionManufacturerYears = action.payload;
    },
    setShowEventModal: (state, action) => {
      state.showEventModal = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetData: (state) => {
      state.transactionData = [];
      state.transactionStats = {};
    },
    resetError: (state) => {
      state.error = null;
    },
    error: (state, action) => {
      state.loading = false;
      state.delTrxId = -1;
      state.fileUploading = false;
      state.transactionReportLoading = false;
      state.eventsLoading = false;
      state.error = action.payload.message || "Internal Server Error";
    },
  },
});

export const actions = transactionSlice.actions;

export default transactionSlice.reducer;
