import { combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from "./authentication/reducer";
import adminReducer from "./admin/reducer";
import transactionReducer from "./transaction/reducer";
import savedSearchReducer from "./saved_search/reducer";
import planReducer from "./plans/reducer";
import lenderReducer from "./lender/reducer";
import globalReducer from "./global/reducer";

export default combineReducers({
  Authentication: authenticationReducer,
  Admin: adminReducer,
  Transaction: transactionReducer,
  SavedSearch: savedSearchReducer,
  Plan: planReducer,
  Lender: lenderReducer,
  Global: globalReducer,
});
