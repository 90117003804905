import PropTypes from "prop-types";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import React from "react";
import { isLenderPlus, validateSubType } from "utils";

function PriceCellDashboard({
  value,
  suffix,
  color = "#2C241C",
  fontWeight = 600,
}) {
  return (
    <MDBox alignItems="center">
      {isLenderPlus() || validateSubType("Trial") ? (
        <MDTypography
          variant="caption"
          fontSize="13px"
          color={color}
          sx={{ fontWeight }}
        >
          {value}
          {suffix && (
            <MDTypography
              variant="caption"
              fontWeight={fontWeight}
              color="secondary"
            >
              &nbsp;&nbsp;{suffix}
            </MDTypography>
          )}
        </MDTypography>
      ) : (
        <MDTypography
          fontSize="14px"
          color={color}
          sx={{ fontWeight, filter: "blur(4px)" }}
        >
          $XX.XX
        </MDTypography>
      )}
    </MDBox>
  );
}

// Setting default values for the props of DefaultCell
PriceCellDashboard.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
PriceCellDashboard.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default PriceCellDashboard;
