import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DataTable from "components/DataTableDashboard";
import Card from "@mui/material/Card";
import DefaultCellDashboard from "components/DataTable/DefaultCellDashboard";
import PriceCellDashboard from "components/PriceCellDashboard";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import SearchFilter from "components/AircraftSearch";
import MDButton from "components/MDButton";
import moment from "moment";
import CardGraphContainer from "components/CardGraph";
import { SHOW_DATE_FORMAT, DATE_FORMAT, MONTH_FORMAT } from "context/constants";
import { debounce, isEmpty, omit } from "radash";
import { omitBy, isEqual } from "lodash";
import { sanitizeObj, loggedInUser } from "utils";
import {
  fetchTransactions,
  fetchTransactionsStats,
  requestTransactionsReport,
  downloadTransactionsReport,
  toggleDropZone,
  deleteTransaction,
  getTransactionStatsSuccess,
  setFirstLoad,
  setStatsLoading,
} from "store/actions";
import { actions } from "store/transaction/reducer";
import ExportIcon from "assets/uploadNewIcon.svg";
import MDDropzone from "components/MDDropzone";
import DeleteAction from "components/DataTable/DeleteAction";
import { lenderState, globalState, transactionState } from "store/states";
import TransitionsModal from "components/DashboardModal/transition";
import UpgradeSubscriptionModal from "components/DashboardModal/upgrade";
import dropZoneTemplate from "components/DashboardModal/dropzoneTemplate";
import axios from "axios";

const TABLE_COLS = [
  {
    Header: "TRANSACTION DATE",
    accessor: "date",
    width: 135,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "AIRCRAFT MANUFACTURER",
    accessor: "aircraft_maker",
    width: 180,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "AIRCRAFT MODEL",
    accessor: "aircraft_model",
    width: 125,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "MFR. YEAR",
    accessor: "manufacture_year",
    width: 90,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "PRICE",
    accessor: "price",
    textAlign: "right",
    width: 55,
    Cell: ({ value }) => (
      <DefaultCellDashboard
        value={`$${value?.toLocaleString?.() || 0}`}
        color="#FFFFFF"
      />
    ),
  },
];

const TABLE_COLS_LENDER = (deleteTrxAction, delTrxId) => [
  {
    Header: "REG DATE",
    accessor: "date",
    width: 135,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "AIRCRAFT MANUFACTURER",
    accessor: "aircraft_maker",
    width: 180,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "AIRCRAFT MODEL",
    accessor: "aircraft_model",
    width: 125,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "SERIAL NUMBER",
    accessor: "serial_number",
    width: 155,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "MFR YEAR",
    accessor: "manufacture_year",
    width: 75,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "N NUMBER",
    accessor: "n_number",
    width: 75,
    Cell: ({ value }) => <DefaultCellDashboard value={value} />,
  },
  {
    Header: "AVERAGE PRICES",
    accessor: "price",
    width: 155,
    Cell: ({ value }) => (
      <PriceCellDashboard
        value={`$${value?.toLocaleString?.() || 0}`}
        color="white"
      />
    ),
  },
  {
    Header: "Action",
    accessor: "action",
    width: 60,
    Cell: ({ row }) => {
      const { pk_id: pkId } = row.original;
      return (
        <DeleteAction
          onSubmit={() => deleteTrxAction(pkId)}
          loading={pkId === delTrxId}
        />
      );
    },
  },
];

const getFinalFilters = (filters) => {
  const compiledFilters = sanitizeObj(filters);
  if (compiledFilters.start_date) {
    compiledFilters.transaction_date__gte = moment(
      compiledFilters.start_date,
      SHOW_DATE_FORMAT
    ).format(DATE_FORMAT);
  }
  if (compiledFilters.end_date) {
    compiledFilters.transaction_date__lte = moment(
      compiledFilters.end_date,
      SHOW_DATE_FORMAT
    ).format(DATE_FORMAT + " 23:59:59");
  }
  if (!isEmpty(compiledFilters.mfr_years)) {
    compiledFilters.manufacture_year__in = JSON.stringify(
      compiledFilters.mfr_years
    );
  }
  return omit(compiledFilters, ["start_date", "end_date", "mfr_years"]);
};

function MainSection({
  isNotLender,
  lenderDropZone,
  filters,
  setFilters,
  searchStr,
  loading,
  statsLoading
}) {
  const {
    firstLoad,
    transactionData,
    transactionStats,
    fileUploading,
    delTrxId,
  } = useSelector(transactionState);
  const dispatch = useDispatch();

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        "https://15-rocks-resource.s3.amazonaws.com/lender_report_template.csv",
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/octet-stream",
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "lender_report_template.csv");

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const tableRows = useMemo(() => {
    return transactionData.data?.map((row) => ({
      pk_id: row.id,
      date: moment(row.transaction_date).format(MONTH_FORMAT),
      aircraft_maker: row.make,
      aircraft_model: row.model,
      serial_number: row.serial_number,
      n_number: row.nnumber,
      manufacture_year: row.manufacture_year,
      price: row.price,
      has_events: row.has_events,
    }));
  }, [transactionData]);

  if (!firstLoad) return <></>;

  if (!isNotLender && lenderDropZone) {
    return (
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "50vh", md: "80vh" },
          flexDirection: "column",
        }}
      >
        <MDBox
          className="dropzone-custom"
          sx={{ width: { xm: "100%", xl: "726px" } }}
        >
          <MDDropzone
            loading={fileUploading}
            options={{
              addRemoveLinks: true,
              autoProcessQueue: false,
              dictDefaultMessage: dropZoneTemplate,
              maxFiles: 1,
              acceptedFiles: ".csv",
            }}
          />
        </MDBox>

        <MDTypography
          sx={{
            mt: 2,
            cursor: "pointer",
            userSelect: "none",
            textAlign: "right",
            textDecoration: "underline",
            fontFamily: "Poppins, sans-serif",
          }}
          variant="body1"
          fontWeight="medium"
          color="#2C241C"
          fontSize="14px"
          onClick={handleDownload}
        >
          Click here for CSV file template
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <MDBox>
      <Grid container>
        <SearchFilter gFilters={filters} setGFilters={setFilters} />
      </Grid>
      {isNotLender && (
        <MDBox mt="2px">
          <Grid container>
            <CardGraphContainer
              search={searchStr}
              stats={transactionStats}
              filters={filters}
              loading={loading}
              statsLoading={statsLoading}
            />
          </Grid>
        </MDBox>
      )}
      <MDBox mt="2px">
        <Card sx={{ backgroundColor: "#C8B19C", borderRadius: "4px", padding: { xs: "16px", sm: "24px" } }}>
          {tableRows ? (
            <DataTable
              table={{
                columns: isNotLender
                  ? TABLE_COLS
                  : TABLE_COLS_LENDER(
                    (trxId) => dispatch(deleteTransaction(trxId)),
                    delTrxId
                  ),
                rows: tableRows,
              }}
              setGFilters={setFilters}
              total={transactionData.total || 0}
              filterPage={filters.page}
              filterPerPage={filters.per_page}
              tableRows={tableRows}
            />
          ) : null}
        </Card>
      </MDBox>
    </MDBox>
  );
}

function useStateWithPreUpdate(initialValue, preUpdateFn) {
  const [state, setState] = useState(initialValue);

  const setStateWithPreUpdate = useCallback(
    (newValue) => {
      if (typeof preUpdateFn === 'function') preUpdateFn();
      setState(newValue);
    },
    [preUpdateFn, state]
  );

  return [state, setStateWithPreUpdate];
}

function Dashboard() {
  const dispatch = useDispatch();
  const isNotLender = loggedInUser().job_title !== "Lender";

  const {
    loading,
    statsLoading,
    transactionData,
    transactionStats,
    reportId,
    transactionReportLoading,
    fileUploading,
    firstLoad,
    showEventModal
  } = useSelector(transactionState);
  const { lenderDropZone } = useSelector(lenderState);
  const { searchStr } = useSelector(globalState);

  const [filters, setFilters] = useStateWithPreUpdate({ page: 1, per_page: 10 }, () => isNotLender && dispatch(setStatsLoading(true)));
  const oldFilters = useRef({});
  const [transitionModal, setTransitionModal] = useState(false);
  const asyncSetFilters = useRef(debounce({ delay: 500 }, setFilters));

  const toggleDropzone = (val) => dispatch(toggleDropZone(val));

  useEffect(() => {
    setTransitionModal(showEventModal);
  }, [showEventModal]);

  useEffect(() => {
    showEventModal !== transitionModal && dispatch(actions.setShowEventModal(transitionModal));
  }, [transitionModal]);

  useEffect(() => {
    dispatch(setStatsLoading(true));
    const oldFiltersObj = oldFilters.current;
    oldFilters.current = filters;
    const compiledFilters = getFinalFilters(filters);

    dispatch(fetchTransactions(compiledFilters)).then(() => dispatch(setStatsLoading(false)));

    const diff = omitBy(filters, (value, key) => isEqual(value, oldFiltersObj[key]));
    if (!Object.keys(diff).length) return;

    const filtersApplied = Object.keys(filters).some(
      (filterKey) =>
        !isEmpty(filters[filterKey]) &&
        !["sort_by", "sort_order", "page", "per_page"].includes(filterKey)
    );

    if (
      isNotLender &&
      (!oldFiltersObj ||
        (filters.page === oldFiltersObj.page &&
          filters.per_page === oldFiltersObj.per_page))
    ) {
      if (filtersApplied) {
        dispatch(
          fetchTransactionsStats(
            omit(compiledFilters, ["sort_by", "sort_order", "page", "per_page"])
          )
        ).then(() => dispatch(setStatsLoading(false)));
      } else {
        dispatch(setStatsLoading(false));
        dispatch(getTransactionStatsSuccess({}));
      }
    }
  }, [filters]);

  useEffect(() => {
    let interval;
    if (reportId) {
      interval = setInterval(() => {
        dispatch(downloadTransactionsReport(reportId));
      }, 3000);
    }
    return () => interval && clearInterval(interval);
  }, [reportId]);

  useEffect(() => {
    if (!firstLoad && Object.keys(transactionData).length) {
      dispatch(setFirstLoad());
      if (!isNotLender && !transactionData.total) toggleDropzone();
    }
  }, [transactionData]);

  useEffect(() => {
    asyncSetFilters.current({
      ...filters,
      search: searchStr,
      page: 1,
      sort_by: "transaction_date",
      sort_order: "desc",
    })
    dispatch(getTransactionStatsSuccess({ ...transactionStats, total: '' }));
  }, [searchStr])

  return (
    <DashboardLayout>
      <TransitionsModal open={transitionModal} setOpen={setTransitionModal} />
      <UpgradeSubscriptionModal />
      <MDBox pb={{ md: 3 }}>
        <Grid container spacing={1} pb={2}>
          <Grid item xs={isNotLender ? 9 : 6} md={isNotLender ? 9 : 6} lg={isNotLender ? 9 : 6} xl={isNotLender ? 9 : 6}>
            <MDBox>
              <MDTypography
                variant="h6"
                sx={{
                  fontSize: { xs: "18px", md: "32px" },
                  fontWeight: 275,
                  color: "#2C241C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isNotLender ? "Dashboard" : "Lender Dashboard"}
                {isNotLender && (
                  <span
                    style={{
                      padding: "4px 4px",
                      fontSize: "12px",
                      lineHeight: "12px",
                      backgroundColor: "#C8B19C",
                      color: "white",
                      borderRadius: "2px",
                      marginLeft: "8px",
                    }}
                  >
                    Beech and Cessna aircraft may be found under Textron
                    Aviation
                  </span>
                )} 
                {loading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </MDTypography>{" "}
            </MDBox>
          </Grid>
          <Grid item xs={isNotLender ? 3 : 6} md={isNotLender ? 3 : 6} lg={isNotLender ? 3 : 6} xl={isNotLender ? 3 : 6}>
            <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
              {firstLoad && isNotLender ? (
                <MDButton
                  variant="gradient"
                  color="baseColorSecondary"
                  sx={{
                    textTransform: "none",
                    alignItems: "center",
                    color: "#F2F7F2",
                    backgroudColor: "#C8B19C",
                    fontSize: "14px",
                    width: { md: "145px" },
                    height: "40px",
                    p: "8px",
                    borderRadius: "4px",
                    cursor:
                      transactionReportLoading ||
                        reportId ||
                        !transactionData.total
                        ? "not-allowed"
                        : "pointer",
                  }}
                  disabled={!transactionData.total}
                  onClick={() =>
                    transactionData.total &&
                      !(transactionReportLoading || reportId)
                      ? dispatch(
                        requestTransactionsReport(
                          omit(getFinalFilters(filters), ["page", "per_page"])
                        )
                      )
                      : null
                  }
                >
                  {transactionReportLoading || reportId ? (
                    <CircularProgress size={20} sx={{ mr: 1 }} color="white" />
                  ) : (
                    <img src={ExportIcon} style={{ marginRight: "8px", width: "24px", height: "24px" }} />
                  )}
                  Export Report{" "}
                </MDButton>
              ) : null}
              {firstLoad && !isNotLender && !lenderDropZone ? (
                <MDButton
                  className="no-hover-color-change"
                  variant="gradient"
                  color="baseColorSecondary"
                  sx={{
                    alignItems: "center",
                    color: "#F2F7F2",
                    fontSize: "14px",
                    width: "max-content",
                    mr: { md: 2 },
                    p: "8px",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleDropzone(true)}
                >
                  <img src={ExportIcon} style={{ marginRight: "8px" }} />
                  Upload Reports{" "}
                </MDButton>
              ) : null}
            </MDBox>
          </Grid>
        </Grid>
        <MainSection
          filters={filters}
          setFilters={setFilters}
          isNotLender={isNotLender}
          lenderDropZone={lenderDropZone}
          searchStr={searchStr}
          loading={loading}
          statsLoading={statsLoading}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
