import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import { useMaterialUIController, setLayout } from "context";
import { ToastContainer } from "react-toastify";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <>
      <ToastContainer position="top-right" style={{ width: "fit-content" }} />
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: "24px",
          position: "relative",
          background: "#E2CDBB",

          [breakpoints.up("lg")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(240),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
          [breakpoints.between("md", "lg")]: {
            marginLeft: pxToRem(200),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
