import { useMemo, useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";

import { useDispatch } from "react-redux";
import { fetchEventAlerts } from "store/actions";


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "components/FormField";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { humanize, genPaginationList } from "utils";
import { Box, CircularProgress, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ExpandMore } from "@mui/icons-material";
import swapIcon from "assets/swapIcon.svg";
import { loggedInUser } from "utils";
import { transactionState } from "store/states";
import { useSelector } from "react-redux";

const CollapsibleTable = ({ data }) => {
  const [open, setOpen] = useState({});
  const handleToggle = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  return (
    <div>
      {data.map((aircraft) => (
        <div key={aircraft.pk_id}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              cursor: "pointer",
              padding: "0 10px",
              justifyContent: "space-between",
            }}
            onClick={() => handleToggle(aircraft.pk_id)}
          >
            <div>
              <MDTypography
                variant="h6"
                fontSize="14px"
                color="#3C322D"
                mb={0.5}
              >
                DATE:{" "}
                <span
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                    color: "white",
                    fontWeight: 500,
                  }}
                >
                  {aircraft.date}
                </span>
              </MDTypography>
              <MDTypography
                variant="h6"
                fontSize="14px"
                color="#3C322D"
                mb={0.5}
              >
                AIRCRAFT MAKER:{" "}
                <span
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                    color: "white",
                    fontWeight: 500,
                  }}
                >
                  {aircraft.aircraft_maker}
                </span>
              </MDTypography>
            </div>
            <IconButton>
              {open[aircraft.pk_id] ? (
                <KeyboardArrowDownIcon
                  sx={{ color: "white", width: "16px", height: "16px" }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  sx={{ color: "white", width: "16px", height: "16px" }}
                />
              )}
            </IconButton>
          </div>
          <div
            style={{
              display: !open[aircraft.pk_id] ? "block" : "none",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
              color: "#3C322D",
              fontWeight: 500,
              padding: "0 10px",
            }}
          >
            <MDTypography variant="h6" fontSize="14px" color="#3C322D" mb={0.5}>
              AIRCRAFT MODEL:{" "}
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                {aircraft.aircraft_model}
              </span>
            </MDTypography>
            <MDTypography variant="h6" fontSize="14px" color="#3C322D" mb={0.5}>
              MFR. YEAR:{" "}
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                {aircraft.manufacture_year}
              </span>
            </MDTypography>
            <MDTypography variant="h6" fontSize="14px" color="#3C322D" mb={0.5}>
              PRICE:{" "}
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                ${humanize(aircraft.price)}
              </span>
            </MDTypography>
          </div>
          <div style={{ margin: "0 10px" }}>
            <hr
              style={{
                height: "1px",
                backgroundColor: "white",
                border: "none",
                padding: "0 20px 0 20px",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

function DataTable({
  entriesPerPage,
  showTotalEntries,
  table,
  setGFilters,
  pagination,
  isSorted,
  noEndBorder,
  total,
  filterPage,
  filterPerPage,
  tableRows,
}) {
  const {loading} = useSelector(transactionState);
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "50"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const [sortBy, setSortBy] = useState([]);
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const totalPages = Math.ceil(total / filterPerPage);
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setGFilters((oldGFilters) => ({
      ...oldGFilters,
      page: 1,
      per_page: value,
    }));
  };

  useEffect(() => {
    const [sortCol] = sortBy || [];
    const columnMapping = {
      date: "transaction_date",
      aircraft_maker: "make",
      aircraft_model: "model",
      n_number: "nnumber",
    };
    if (sortCol) {
      setGFilters((oldGFilters) => ({
        ...oldGFilters,
        sort_by: columnMapping[sortCol.id] || sortCol.id,
        sort_order: sortCol.desc ? "desc" : "asc",
      }));
    }
  }, [sortBy])

  const canPreviousPage = filterPage > 1;
  const canNextPage = filterPage < totalPages;
  const pageStart = (filterPage - 1) * filterPerPage + 1;
  const pageEnd = Math.min(filterPage * filterPerPage, total);

  const renderPagination = genPaginationList(totalPages, filterPage).map(
    (page, i) => {
      const isVisible = page != "...";
      if (isVisible) {
        return (
          <MDPagination
            key={i}
            item
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              borderRadius: "4px",
              color: "#FFFFFF",
              background: filterPage === page ? "#3B3229" : "#BAA491",
              marginRight: "8px !important",
            }}
            onClick={() => {
              gotoPage(page);
              setGFilters((oldGFilters) => ({
                ...oldGFilters,
                page: page,
              }));
            }}
            active={filterPage === page}
          >
            {page}
          </MDPagination>
        );
      } else {
        return (
          <MDPagination
            key={i}
            item
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              borderRadius: "4px",
              color: "#FFFFFF",
              background: "#BAA491",
              marginRight: "8px !important",
            }}
          >
            ...
          </MDPagination>
        );
      }
    }
  );

  const handleSort = (column) => {
    const isDesc = sortBy.length > 0 && sortBy[0].id === column.id && sortBy[0].desc;
    setSortBy([{ id: column.id, desc: !isDesc }]);
  };

  const dispatch = useDispatch();
  const isLender = loggedInUser().job_title === "Lender";

  const handleEventAlerts = (transactionId) => {
    if(isLender){
      dispatch(fetchEventAlerts(transactionId))
    }
  };

  const shouldRenderPagination = total > 0 && showTotalEntries;

  return (
    <>
      {entriesPerPage ? (
        <MDBox
          display="flex"
          flexDirection="row"
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
        >
          <MDBox sx={{ lineHeight: "18px", display: "flex", gap: "5px", margin: "auto 0"}}>
            <MDBox>
              <img src={swapIcon} />
            </MDBox>
            <MDTypography
              variant="h1"
              fontSize={{ xs: "18px", md: "20px" }}
              sx={{ fontWeight: 500, color: "#FFFFFF" }}
            >
              Aircraft Transactions
            </MDTypography>
          </MDBox>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                className="dropdownFont"
                value={pageSize.toString()}
                options={entries}
                onChange={(_, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "58px" }}
                popupIcon={
                  <ExpandMore
                    color="white"
                    sx={{ fontWeight: 400, paddingLeft: "8px" }}
                  />
                }
                renderInput={(params) => <MDInput {...params} sx={{ padding: "0 !important" }} />}
              />
              <MDTypography
                variant="caption"
                fontSize="14px"
                sx={{
                  fontWeight: 500,
                  width: "max-content",
                  color: "#FFFFFF",
                  display: "block",
                  "@media (max-width: 1241px)": {
                    display: "none",
                  },
                }}
              >
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <TableContainer
        sx={{
          marginTop: "16px",
          boxShadow: "none",
          display:  "block",
          backgroundColor: "transparent !important",
          borderRadius: "4px !important",
          "@media (max-width: 1241px)": {
            display: "none",
          },
        }}
      >
        <Table {...getTableProps()}>
          {loading ? (
            <Box justifyContent="center" display="flex">
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : (
            <>
              <MDBox component="thead">
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <DataTableHeadCell
                        {...column.getHeaderProps(
                          isSorted && column.getSortByToggleProps()
                        )}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                      </DataTableHeadCell>
                    ))}
                  </TableRow>
                ))}
              </MDBox>
              <TableBody
                {...getTableBodyProps()}
                sx={{ backgroundColor: "#BAA491" }}
              >
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      className="hover-highlight"
                    >
                      {row.cells.map((cell) => (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={
                            cell.column.textAlign
                              ? cell.column.textAlign
                              : "left"
                          }
                          {...cell.getCellProps()}
                          fn={
                            cell.column["Header"] != "Action" && isLender && row.original.has_events
                              ? () => handleEventAlerts(row.original.pk_id)
                              : undefined
                          }
                        >
                          {cell.render("Cell")}
                        </DataTableBodyCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      <MDBox
        sx={{
          display: "none",
          "@media (max-width: 1241px)": {
            display: "block",
          },
        }}
      >
        <CollapsibleTable data={tableRows} />
      </MDBox>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "left", sm: "center" }}
        justifyContent={"space-between"}
        py="12px"
        gap={{ xs: "16px", sm: "0" }}
      >
        <MDBox>
          <MDTypography
            variant="button"
            fontWeight="regular"
            fontSize={{ xs: "13px", md: "14px" }}
            sx={{ color: "#FFFFFF" }}
          >
            Showing {pageStart} to {pageEnd} of {(total || 0).toLocaleString()} entries
          </MDTypography>
        </MDBox>
        {shouldRenderPagination && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            <MDPagination
              item
              onClick={
                canPreviousPage
                  ? () => {
                      previousPage();
                      setGFilters((oldGFilters) => ({
                        ...oldGFilters,
                        page: oldGFilters.page - 1,
                      }));
                    }
                  : () => null
              }
              sx={{
                background: "#BAA491",
                color: "white !important",
                cursor: canPreviousPage ? "poiner" : "not-allowed",
                marginRight: "8px !important",
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
            {shouldRenderPagination && renderPagination}
            <MDPagination
              item
              onClick={
                canNextPage
                  ? () => {
                      nextPage();
                      setGFilters((oldGFilters) => ({
                        ...oldGFilters,
                        page: oldGFilters.page + 1,
                      }));
                    }
                  : () => null
              }
              sx={{
                background: "#BAA491",
                color: "white !important",
                cursor: canNextPage ? "poiner" : "not-allowed",
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          </MDPagination>
        )}
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [10, 25, 50] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  total: 0,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.instanceOf(Array),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.instanceOf(Object).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "blue",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  total: PropTypes.number,
};

export default DataTable;
