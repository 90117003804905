import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Box, Button } from "@mui/material";

function LenderCardComponent({ badge, price, action, className, boxData }) {
  return (
    <Card sx={{ height: "100%" }}>
      <Box
        sx={{
          padding: "5px",
          width: "100%",
          background:
            "radial-gradient(circle, rgba(162,135,122,1) 10%, rgba(60,50,45,1) 200%)",
          borderRadius: "16px",
          boxShadow: "0px 20px 40px 0px #3B322933",
          textAlign: "center",
          maxWidth: { xs: "100%", sm: "215px" },
        }}
        height={{ xs: "100%", md: "296px" }}
        display="flex"
        flexDirection="column"
      >
        <Box
          width="104px"
          mx="auto"
          mt={1}
          sx={{
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: "4px 8px",
            border: "0.25px solid #C8B19C",
          }}
        >
          <MDTypography
            variant="caption"
            sx={{
              fontSize: "12px",
              fontWeight: 500,
            }}
            color="white"
          >
            {badge.label}
          </MDTypography>
        </Box>
        <MDBox textAlign="center">
          <MDBox>
            <MDBox>
              <MDTypography
                display="inline"
                component="small"
                variant="h5"
                color="#F2F7F2"
                fontSize={{ xs: "36px", md: "42px" }}
                sx={{ fontWeight: 700 }}
              >
                {price.currency}
                {price.value}
              </MDTypography>
              <MDTypography
                display="inline"
                component="small"
                sx={{ fontWeight: 400 }}
                fontSize={{ xs: "24px", md: "36x" }}
                variant="h5"
                color="#F2F7F2"
              >
                /{price.type}
              </MDTypography>
            </MDBox>
            <MDBox
              px={{ xs: 2, md: 1 }}
              sx={{ lineHeight: "17px", textAlign: "left" }}
            >
              {boxData.map((box, index) => (
                <MDBox
                  key={index}
                  pt={1}
                  display="flex"
                  alignItems="flex-start"
                >
                  {box.icon}
                  <div style={{ lineHeight: "17px" }}>
                    <MDTypography
                      variant="h5"
                      color="#F2F7F2"
                      sx={{
                        fontSize: "13px",
                        fontWeight: 700,
                        width: "max-content",
                      }}
                    >
                      {box.title}
                    </MDTypography>
                    <MDTypography
                      variant="p"
                      color="#F2F7F2"
                      sx={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {box.description}
                    </MDTypography>
                  </div>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox pb={1} mt="auto">
          <MDBox mt={2}>
            <Button
              onClick={action.onClick}
              className={`${className} ${
                action.disabled ? "cursor-not-allowed" : ""
              }`}
              mt="8px"
              width="133px"
              fontSize={{ xs: "13px", md: "14px" }}
              sx={{ fontWeight: 500, size: { xs: "small", md: "large" },
                backgroundColor: "#C8B19C",
                color: "#3C322D",
                borderRadius: "4px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#C8B19C",
                  color: "#3C322D",
                },
              }}
            >
              {action.loading ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <>{action.label}</>
              )}
            </Button>
          </MDBox>
        </MDBox>
      </Box>
    </Card>
  );
}

// Setting default props for the LenderCardComponent
LenderCardComponent.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the LenderCardComponent
LenderCardComponent.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.instanceOf(Array).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  boxData: PropTypes.instanceOf(Array).isRequired,
};

export default LenderCardComponent;
