import axios from "utils/axios";
import { actions } from "./reducer";
import { tryit } from "radash";
import { apiAction, showCustomToast } from "utils";

export const fetchPlans = () => (dispatch) => {
  dispatch(actions.fetchPlans());
  return apiAction(
    tryit(axios.get)("/payments/plans"),
    (resp) => dispatch(actions.fetchPlansSuccess(resp.data.data)),
    (errResp) => {
      dispatch(actions.error(errResp));
      showCustomToast("Internal Server Error", { isSuccess: false });
    }
  );
};

export const toggleUpgradeModal = (val) => (dispatch) =>
  dispatch(actions.toggleUpgradeModal(val));
