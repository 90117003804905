import { ArrowDropDown } from "@mui/icons-material";
import { Box, InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import NavBarLogo from "assets/navBarLogo.svg";
import newSearchIcon from "assets/newSearchIcon.svg";
import MDTypography from "components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { loggedInUser, getToken, getInitials } from "utils";
import menu from "assets/Menu.svg";
import { setMiniSidenav, useMaterialUIController } from "context";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { globalState } from "store/states";
import { setSearchStr } from "store/actions";

const NavBar = () => {
  const rdxDispatch = useDispatch();
  const { searchStr } = useSelector(globalState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;

  const token = getToken();
  const userData = loggedInUser();
  useEffect(() => {
    loggedInUser();
  }, [token]);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleOnSearch = () => {
    setShow(true);
    setTimeout(() => {
      searchRef.current.focus();
    }, 100);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchRef = useRef(null);
  const searchStrRef = useRef(searchStr);

  useEffect(() => {
    searchStrRef.current = searchStr;
  }, [searchStr]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        !event.target.closest(".MuiMenu-paper") &&
        !searchStrRef.current
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    window.location.href = "/authentication/sign-in";
  };
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <>
      <Box
        sx={{
          height: { xs: "56px", sm: "72px" },
          width: "100%",
          backgroundColor: "#C8B19C",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 24px",
          alignItems: "center",
          position: "fixed",
          zIndex: 5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            onClick={handleMiniSidenav}
            sx={{ display: { sm: "block", md: "none" } }}
          >
            <img src={menu} style={{ display: "block", margin: "auto" }} />
          </Box>
          <Box>
            <img
              src={NavBarLogo}
              style={{ display: "block", margin: "auto" }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {show ? (
            <TextField
              inputRef={searchRef}
              variant="outlined"
              value={searchStr}
              placeholder="Search here"
              onChange={(e) => rdxDispatch(setSearchStr(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      color="white"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#3C322D1A",
                  height: "40px",
                  color: "white",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    color: "white !important",
                    "&::placeholder": {
                      color: "white !important",
                      opacity: 1,
                    },
                  },
                },
                width: "100%",
              }}
            />
          ) : (
            <img
              src={newSearchIcon}
              style={{
                display: "block",
                margin: "auto",
              }}
              onClick={handleOnSearch}
            />
          )}
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              padding: "8px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                backgroundColor: "#3C322D",
                color: "#FFFFFF",
                width: "28px",
                height: "28px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {getInitials(userData.fullname)}
            </Box>
            <MDTypography
              variant="h4"
              color="#FFFFFF"
              sx={{
                width: "max-content",
                fontWeight: 500,
                fontSize: "14px",
                display: { xs: "none", md: "block" },
              }}
            >
              {userData.fullname}
            </MDTypography>
            <ArrowDropDown
              color="white"
              sx={{ height: "24px", width: "24px" }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                "& .MuiPaper-root": {
                  padding: "0",
                  borderRadius: "4px !important",
                  backgroundColor: "#C8B19C !important",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
                },
                "& .MuiMenuItem-root": {
                  color: "white !important",
                  margin: "4px 8px",
                  borderRadius: "4px !important",
                  "&:hover": {
                    backgroundColor: "#3B3229 !important",
                  },
                },
              }}
            >
              <MenuItem
                color="#FFFFFF"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  display: { xs: "block", md: "none" },
                }}
              >
                {userData.fullname}
              </MenuItem>
              <MenuItem
                color="#FFFFFF"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {userData.email}
              </MenuItem>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "white",
                  border: "none",
                }}
              />
              <MenuItem
                color="#FFFFFF"
                sx={{ fontWeight: 500, fontSize: "14px" }}
                onClick={handleLogout}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NavBar;
