import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "components/MDTypography";
import searchIconTheme from "assets/searchIconTheme.svg";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { transactionState, savedSearchState } from "store/states";
import {
  fetchMakers,
  fetchModels,
  fetchManufacturerYears,
  saveSearch,
  setSaveSearch,
} from "store/actions";
import { actions } from "store/transaction/reducer";
import { debounce, isEmpty, omit, pick } from "radash";
import { showCustomToast, sanitizeObj, getAllYears, loggedInUser } from "utils";
import moment from "moment";
import { SHOW_DATE_FORMAT } from "context/constants";
import Select, { defaultTheme } from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import {
  CalendarToday,
  ChevronRight,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import MaskedInput from "react-maskedinput";

const YEARS_SINCE_1990 = getAllYears();

function SearchContainer({
  models,
  makers,
  manufacturerYears,
  loading,
  filters,
  setFilters,
  setModelFilters,
  setManufactureYearFilters,
}) {
  const asyncSetFilters = useRef(debounce({ delay: 500 }, setModelFilters));
  const [open, setOpen] = useState({ maker: false, model: false });
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: "#C8B19C",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "0 8px 10px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        borderColor: state.isFocused ? provided.borderColor : null,
      },
      border: "none",
      borderBottomLeftRadius: 0, 
      borderBottomRightRadius: 0, 
      borderTopLeftRadius: "4px", 
      borderTopRightRadius: "4px",   
      fontSize: "13px",
      minHeight: "0",
      paddingLeft: "25px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      backgroundColor: "white",
      fontWeight: 400,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isSelected ? "black" : "#C8B19C",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#E0E5E0",
      },
      borderRadius: "4px !important"
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: "2px",
      height: "18px",
      width: "20px",
      color: "#C8B19C !important",
    }),
  };

  const { colors } = defaultTheme;

  const DropdownIndicator = () => (
    <div>
      <SearchIcon
        style={{
          left: "10px",
          color: colors.neutral20,
          height: 20,
          width: 20,
          position: "absolute",
          top: "20%",
          color: "#ccc",
        }}
      />
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Grid
      container
      sx={{
        flexDirection: { xs: "column", md: "row" },
        flexWrap: { xs: "wrap", lg: "nowrap" },
      }}
      spacing={2}
      justify="flex-start"
    >
      <Grid item xs={12} md={4} lg={3}>
        <MDBox mt={{ md: 0.1 }}>
        <MDTypography variant="h6" fontSize="14px" 
          sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              pb: "5px",
              width: "max-content",
          }}>
            Aircraft Manufacturer{" "}
          </MDTypography>
          <Autocomplete
            open={open.maker}
            onOpen={() => setOpen({ ...open, maker: true })}
            onClose={() => setOpen({ ...open, maker: false })}
            value={filters.make || ""}
            onChange={(_, newInputValue) => {
              setModelFilters({ make: newInputValue, per_page: 250 });
              setManufactureYearFilters({ make: newInputValue, per_page: 250 });
              setFilters((prevFilters) => ({
                ...prevFilters,
                make: newInputValue,
                model: "",
                mfr_years: [],
              }));
            }}
            options={makers}
            onInputChange={() => null}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="All"
                className="wow2"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <KeyboardArrowDownIcon
                      onClick={() => setOpen({ ...open, maker: !open.maker })}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color: "white",
                      }}
                    />
                  ),
                  sx: {
                    borderBottom: "none",
                    paddingRight: "8px !important",
                    borderRadius: "4px",
                    paddingLeft: "8px !important",
                    backgroundColor: "#3C322D1A",
                    color: "white !important",
                    "&.Mui-focused": {
                      borderBottom: "none !important",
                      "& .MuiOutlinedInput-notchedOutline, &:after": {
                        borderColor: "transparent",
                      },
                    },
                    "& fieldset": { border: "none" },
                    "& input": {
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 400,
                      "&::placeholder": {
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white !important",
                        opacity: 1,
                      },
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-root, &:before": {
                      borderBottom: "none !important",
                      borderColor: "transparent !important",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            )}
            sx={{
              width: "100%",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <MDBox mt={{ md: 0.1 }}>
        <MDTypography variant="h6" fontSize="14px" sx={{ color: "#FFFFFF", fontWeight: 500, pb: "5px" }}>
            Aircraft Model{" "}
          </MDTypography>
          <Autocomplete
            open={open.model}
            onOpen={() => setOpen({ ...open, model: true })}
            onClose={() => setOpen({ ...open, model: false })}
            value={filters.model || ""}
            onChange={(_, newInputValue) => {
              setManufactureYearFilters((prev) => ({ ...prev, model: newInputValue, per_page: 250 }));
              setFilters((prevFilters) => ({
                ...prevFilters,
                model: newInputValue,
              }));
            }}
            options={models}
            onInputChange={(_, newInputValue) => {
              if (!filters.make) {
                asyncSetFilters.current((prevFilters) => ({
                  ...prevFilters,
                  make: newInputValue,
                }));
              }
            }}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="All"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <KeyboardArrowDownIcon
                      onClick={() => setOpen({ ...open, model: !open.model })}
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color: "white",
                      }}
                    />
                  ),
                  sx: {
                    borderBottom: "none",
                    borderRadius: "4px",
                    paddingRight: "8px !important",
                    paddingLeft: "8px !important",
                    backgroundColor: "#3C322D1A",
                    color: "white !important",
                    "&.Mui-focused": {
                      borderBottom: "none !important",
                      "& .MuiOutlinedInput-notchedOutline, &:after": {
                        borderColor: "transparent",
                      },
                    },
                    "& fieldset": { border: "none" },
                    "& input": {
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 400,
                      "&::placeholder": {
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "white !important",
                        opacity: 1,
                      },
                    },
                    "& .MuiInput-underline, &:before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-root:before": {
                      borderBottom: "none !important",
                      borderColor: "transparent !important",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            )}
            sx={{ width: "100%" }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <MDBox sx={{ width: "100%" }}>
          <MDTypography
            variant="h6"
            mb={0.5}
            fontSize="14px"
            sx={{ color: "#FFFFFF", fontWeight: 500, pb: "2px", width: "max-content"}}
          >
            Manufacturer Year
          </MDTypography>
          <div
            className="date-picker-container"
            style={{
              backgroundColor: "#3C322D1A", paddingRight: "8px", paddingLeft: "8px", paddingBottom: "4px", paddingTop: "4px", borderRadius: "4px"}} ref={datePickerRef}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span style={{ position: "absolute", bottom: "-10px" }}>
                <CalendarToday
                  color="white"
                  sx={{ width: "20px", height: "20px" }}
                />
              </span>
              <div
                isSelected={isOpen}
                style={{
                  borderRadius: "0",
                  padding: 0,
                  fontSize: "13px",
                  fontWeight: 400,
                  paddingLeft: "25px",
                  cursor: "pointer",
                  maxWidth: "190px",
                  minWidth: "100%",
                  color: filters.mfr_years?.length ? "black" : "#A4A4A4",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "50px",
                  color: "white",
                  fontWeight: 400,
                }}
              >
                <span>
                  {filters.mfr_years?.length
                    ? filters.mfr_years.join(", ")
                    : "Select Years"}
                </span>
                <span
                  style={{
                    position: "absolute",
                    right: "1px",
                  }}
                >
                  <KeyboardArrowDownIcon
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "white",
                    }}
                  />
                </span>
              </div>
            </div>
            <div
              className="date-picker-container"
              style={{
                position: "absolute",
                width: "100%",
                marginTop: "8px",
                marginLeft: "-8px",
                display: isOpen ? "block" : "none",
                zIndex: 3,
              }}
            >
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={
                  manufacturerYears?.map((year) => ({
                    value: year,
                    label: year.toString(),
                  })) || YEARS_SINCE_1990
                }
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    mfr_years: newValue.map((item) => item.value),
                  }));
                }}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                styles={customStyles}
                value={
                  filters.mfr_years?.map((value) => ({
                    value,
                    label: value,
                  })) || []
                }
                menuIsOpen={isOpen}
              />
            </div>
          </div>
        </MDBox>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <MDBox>
        <MDTypography variant="h6" fontSize="14px" sx={{ color: "#FFFFFF", fontWeight: 500, pb: "3px"}}>
            Start Date
          </MDTypography>
          <div class="date-picker-container">
            <DatePicker
              selected={
                filters.start_date ? moment(filters.start_date).toDate() : ""
              }
              onChange={(date) =>
                setFilters({
                  ...filters,
                  start_date: date ? moment(date).format(SHOW_DATE_FORMAT) : "",
                })
              }
              maxDate={
                filters.end_date
                  ? moment(filters.end_date).toDate()
                  : new Date()
              }
              dateFormat="MM/dd/yyyy"
              placeholderText={"MM/DD/YYYY"}
              className="react-date-picker"
              calendarClassName="custom-react-date-picker"
              showIcon
              icon={
                <CalendarToday
                  color="white"
                  sx={{ width: "20px", height: "20px", marginLeft: "8px" }}
                />
              }
              customInput={
                <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
              }
            />
          </div>
        </MDBox>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <MDBox>
        <MDTypography variant="h6" fontSize="14px" sx={{ color: "#FFFFFF", fontWeight: 500, pb: "3px"}}>
            End Date
          </MDTypography>
          <div class="date-picker-container">
            <DatePicker
              selected={
                filters.end_date ? moment(filters.end_date).toDate() : ""
              }
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              maxDate={new Date()}
              minDate={
                filters.start_date
                  ? moment(filters.start_date).toDate()
                  : undefined
              }
              onChange={(date) =>
                setFilters({
                  ...filters,
                  end_date: date ? moment(date).format(SHOW_DATE_FORMAT) : "",
                })
              }
              dateFormat="MM/dd/yyyy"
              placeholderText={"MM/DD/YYYY"}
              className="react-date-picker"
              showIcon
              customInput={
                <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />
              }
              icon={
                <CalendarToday
                  color="white"
                  sx={{ width: "20px", height: "20px", marginLeft: "8px" }}
                />
              }
            />
          </div>
        </MDBox>
      </Grid>
    </Grid>
  );
}

const DEFAULT_FILTERS = {
  start_date: "",
  end_date: "",
  model: "",
  make: "",
  mfr_years: [],
};

const filterKeys = Object.keys(DEFAULT_FILTERS);

function TransitionsModal({
  open,
  loading,
  onSubmit,
  filters,
  setFilters,
  handleClose,
}) {
  const [fieldErr, setFieldErr] = useState(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Fade in={open}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              p: "32px",
              border: "none",
              borderRadius: "16px",
              width: { xs: "370px", md: "531px" },
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: { xs: "16px", sm: "24px" },
              }}
            >
              <MDTypography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#2C241C",
                  fontSize: { xs: "21px", md: "24px" },
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Save Search Name{" "}
              </MDTypography>
              <MDBox onClick={handleClose}>
                <Close color="#3C322D" />
              </MDBox>
            </MDBox>
            <MDTypography
              id="transition-modal-description"
              sx={{
                mt: 1,
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                fontFamily: "Poppins, sans-serif",
                color: "#2C241C",
              }}
            >
              Name your customized search based on the selected
              <br /> filters in the AIC dashboard for easy future reference.
              <br />
              <br /> You’ll also receive notifications on any aircraft
              <br />
              transactional changes, keeping you informed and up-to-
              <br />
              date.{" "}
            </MDTypography>
            <MDBox mt={{ xs: "16px", sm: "24px" }}>
              <MDTypography
                variant="h2"
                fontSize="14px"
                sx={{ fontWeight: 700, color: "#3B3229" }}
                my={1}
              >
                Enter Search Name{" "}
              </MDTypography>
              <MDInput
                type="text"
                variant="standard"
                placeholder="e.g. Aircraft Jets"
                fullWidth
                value={filters.name || ""}
                className={fieldErr ? "redBorder" : "transparentBorder"}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (!filters.name) {
                      setFieldErr(true);
                    } else onSubmit();
                  }
                }}
                onChange={(e) => {
                  const content = e.target.value;
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    name: content,
                  }));
                  if (content && fieldErr) {
                    setFieldErr(false);
                  }
                }}
                sx={{ borderBottom: "1px solid black" }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              flexDirection="column"
              container
              gap="8px"
              pt={4}>
              <MDButton
                variant="gradient"
                className="no-hover-color-change"
                color="baseColor"
                onClick={() => {
                  if (!filters.name) {
                    setFieldErr(true);
                  } else onSubmit();
                }}
                sx={{
                  size: { xs: "small", md: "medium" },
                  fontSize: { xs: "13px", md: "16px" },
                  width: "100%",
                  height: "47px",
                  borderRadius: "4px",
                  pr: 0,
                  pl: 0,
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
              >
                Save{" "}
                {loading ? (
                  <CircularProgress color="inherit" sx={{ ml: 1 }} size={20} />
                ) : null}
              </MDButton>
              <MDBox
                onClick={() => {
                  handleClose();
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    name: "",
                  }));
                  if (fieldErr) setFieldErr(false);
                }}
                sx={{
                  cursor: "pointer",
                  color: "#3C322D",
                  fontSize: { xs: "13px", md: "14px" },
                  width: "100%",
                  height: "47px",
                  padding: "13px 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Cancel{" "}
              </MDBox>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </div>
  );
}

function FilterCard({ value, removeCard }) {
  return (
    <div style={{ marginLeft: "10px", marginTop: "5px" }}>
      <Card
        variant="outlined"
        sx={{
          pr: 1,
          borderRadius: "16px",
          backgroundColor: "#3B322933",
          height: "33px",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        <MDBox
          display="flex"
          width="max-content"
          justifyContent="space-around"
          alignItems="center"
        >
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="p"
              sx={{
                px: 2,
                fontSize: "12px",
                fontWeight: 400,
                color: "#3B3229",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              width={
                value.includes("MFR") ? { xs: "145px", md: "100%" } : "100%"
              }
            >
              {value}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="p"
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#3B3229",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={removeCard}
            >
              <CloseIcon />
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </div>
  );
}

function FilterCards({ cardsObj, removeCard }) {
  if (!Object.keys(cardsObj).length) return <></>;

  const dates = [cardsObj.start_date, "-", cardsObj.end_date].filter(Boolean);
  const dateCard =
    dates.length > 1 ? (
      <FilterCard
        value={dates.join(" ")}
        removeCard={() => removeCard(["start_date", "end_date"])}
      />
    ) : undefined;

  const otherCards = Object.entries(
    omit(cardsObj, ["start_date", "end_date"])
  ).map(([key, value]) => (
    <FilterCard
      key={key}
      value={Array.isArray(value) ? `MFR: ${value.join(", ")}` : value}
      removeCard={() => removeCard([key])}
    />
  ));

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {dateCard}
      {otherCards}
    </div>
  );
}

function ActionButton({ onClick, variant, color, label, className }) {
  return (
    <MDButton
      variant={variant}
      color={color}
      className={className || ""}
      onClick={onClick}
      sx={{
        textTransform: "none",
        color: { color },
        with: "max-content",
        fontSize: { xs: "13px", md: "16px" },
        padding: "8px",
        height: "40px",
        borderRadius: "4px",
        size: { xs: "small", md: "large" },
      }}
    >
      {label}
    </MDButton>
  );
}

function SearchFilter({ gFilters, setGFilters }) {
  const {
    loading,
    transactionMakers,
    transactionModels,
    transactionManufacturerYears,
  } = useSelector(transactionState);
  const { loading: saveLoading, curSaveSearch } = useSelector(savedSearchState);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [modelFilters, setModelFilters] = useState({ page: 1, per_page: 250 });
  const [manufactureYearFilters, setManufactureYearFilters] = useState({
    page: 1,
    per_page: 250,
  });
  const [show, setShow] = useState(true);
  const [cards, setCards] = useState({});
  const [open, setOpen] = useState(false);

  const isIconDisabled = !Object.keys(cards).length;

  useEffect(() => {
    dispatch(fetchModels(modelFilters));
  }, [modelFilters]);


  useEffect(() => {
    if (manufactureYearFilters.make) {
      dispatch(fetchManufacturerYears(manufactureYearFilters))
    }
    else dispatch(actions.setManufacturerYears(undefined));
  }, [manufactureYearFilters]);

  useEffect(() => {
    const [newObj, newCards] = [{}, {}];
    filterKeys.forEach((key) => {
      newObj[key] = filters[key];
      if (!isEmpty(filters[key])) newCards[key] = filters[key];
    });

    if (filterKeys.some((key) => filters[key] !== gFilters[key])) {
      setGFilters((prevFilters) => ({
        ...prevFilters,
        ...newObj,
        page: 1,
        sort_by: "transaction_date",
        sort_order: "desc",
      }));
      setCards(newCards);
    }
  }, [filters]);

  useEffect(() => {
    if (!transactionMakers) dispatch(fetchMakers());
  }, []);

  useEffect(() => {
    if (curSaveSearch.search_filters) {
      const searchObj = curSaveSearch.search_filters;
      setCards(searchObj);
      setOpen(false);
      setShow(false);

      setFilters((prevFilters) => ({
        ...omit(prevFilters, filterKeys),
        ...searchObj,
        name: "",
      }));
    }
  }, [curSaveSearch]);

  const removeCard = (searchKeys) => {
    setCards((prevCards) => {
      const newObj = omit(prevCards, searchKeys);

      if (Object.keys(curSaveSearch).length) dispatch(setSaveSearch({}));
      if (!Object.keys(newObj).length) setShow(true);

      setFilters((prevFilters) => ({
        ...omit(prevFilters, filterKeys),
        ...newObj,
      }));

      return newObj;
    });

    if (!["make", "model"].some((key) => searchKeys.includes(key))) return;

    if (searchKeys.includes("make")) {
      setModelFilters((prev) => {
        return omit(prev, ["make"]);
      });
    }

    setManufactureYearFilters((prev) => {
      const omitKeys = searchKeys.includes("make")
        ? ["make", "model"]
        : ["model"];

      return omit(prev, omitKeys);
    });
  };

  const createSavedSearch = () => {
    const savedSearchObj = sanitizeObj(
      pick(filters, ["start_date", "end_date", "model", "make", "mfr_years"])
    );

    dispatch(
      saveSearch({
        name: filters.name,
        search_filters: JSON.stringify(savedSearchObj),
      })
    );
  };

  const handleClearSearch = () => {
    setFilters(DEFAULT_FILTERS);
    setGFilters({ ...gFilters, ...DEFAULT_FILTERS });
    dispatch(setSaveSearch({}));
    dispatch(fetchModels({ per_page: 250 }))
    setCards({});
  };

  const handleSaveSearch = () => {
    if (Object.keys(DEFAULT_FILTERS).some((key) => !isEmpty(filters[key]))) {
      setOpen(true);
    } else {
      showCustomToast("Choose at least one filter", {
        autoClose: 1200,
        isSuccess: false,
      });
    }
  };

  const isNotLender = loggedInUser().job_title !== "Lender";

  return (
    <Card
      sx={{ width: "100%", backgroundColor: "#C8B19C", borderRadius: "4px", padding: { xs: "16px", sm: "24px" }}}
    >
      <TransitionsModal
        filters={filters}
        setFilters={setFilters}
        open={open}
        loading={saveLoading}
        onSubmit={createSavedSearch}
        handleClose={() => setOpen(false)}
      />
      <MDBox
        display="flex"
        justifyContent="space-between"
        sx={{ alignItems: "center" }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={10}
            display="flex"
            alignItems="center"
            gap="5px"
            pb={{ xs: 2, sm: 0 }}
          >
            <MDBox>
              <img
                src={searchIconTheme}
                style={{ display: "block", margin: "auto" }}
              />
            </MDBox>
            <MDTypography
              variant="h6"
              fontSize={{ xs: "18px", md: "20px" }}
              sx={{ color: "#FFFFFF", fontWeight: 500 }}
            >
              Aircraft Search
            </MDTypography>
            {show && <FilterCards cardsObj={cards} removeCard={removeCard} />}
          </Grid>
          <Grid
            xs={2}
            item
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            pb={{ xs: 2, md: 0 }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#3C322D1A",
                borderRadius: "4px",
                cursor: isIconDisabled ? "not-allowed" : "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => (isIconDisabled ? null : setShow(!show))}
            >
              {show ? (
                <ExpandMore color="white" />
              ) : (
                <ChevronRight color="white" />
              )}
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      {!show ? (
        <MDBox sx={{ pl: 2, pr: 2, pb: 2 }}>
          <FilterCards cardsObj={cards} removeCard={removeCard} />
        </MDBox>
      ) : null}
      {show ? (
        <MDBox>
          <MDBox container pt={3}>
          <SearchContainer
              makers={transactionMakers || []}
              models={transactionModels || []}
              manufacturerYears={transactionManufacturerYears}
              loading={loading}
              setModelFilters={setModelFilters}
              setManufactureYearFilters={setManufactureYearFilters}
              setFilters={setFilters}
              filters={filters}
          />
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="start"
            container
            pt={3}
            gap="16px"
          >
            {isNotLender && (
              <MDBox
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <ActionButton
                  variant="gradient"
                  className="no-hover-color-change"
                  color="baseColor"
                  label="Save Search"
                  onClick={handleSaveSearch}
                />
              </MDBox>
            )}
            <MDBox
              sx={{
                display: { xs: "none", md: "block" },
                mr: 2,
              }}
            >
              <ActionButton
                variant="gradient"
                color="baseColorOpacity"
                label="Clear Search"
                onClick={handleClearSearch}
              />
            </MDBox>
            <Grid
              container
              alignItems={{ xs: "center" }}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Grid item xs={12} alignItems="center">
                <MDBox container display="flex" justifyContent="flex-end" gap={2}>
                  <ActionButton
                    variant="gradient"
                    color="baseColorOpacity"
                    label="Clear Search"
                    onClick={handleClearSearch}
                  />
                  {isNotLender && (
                    <MDBox container display="flex" justifyContent="flex-end">
                      <ActionButton
                        variant="gradient"
                        className="no-hover-color-change"
                        color="baseColor"
                        label="Save Search"
                        onClick={handleSaveSearch}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      ) : (
        ""
      )}
    </Card>
  );
}

export default SearchFilter;
