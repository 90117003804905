import { useState, useEffect, useMemo } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import Sidenav from "components/Sidenav";

import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import routes from "routes";

import { useMaterialUIController, setMiniSidenav } from "context";

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { loggedInUser, getUserStep } from "utils";
import moment from "moment";
import NavBar from "components/NavBar";
import { Box } from "@mui/material";

const PrivateRoute = ({ element, isPublic, isAdmin }) => {
  const { pathname, search } = window.location;
  const query = queryString.parse(search);

  if (query.success === "true" && query.token && pathname === "/") {
    return <Navigate to={`/token-verification${search}`} />;
  }

  const userData = loggedInUser();
  const step = getUserStep();

  const isAuthenticated =
    userData.exp && moment.unix(userData.exp).isAfter(moment());

  if (isAuthenticated) {
    if (!isPublic && step < 5) {
      return <Navigate to="/authentication/sign-up" />;
    }
    if (
      (isPublic && pathname !== "/authentication/sign-in") ||
      (isAdmin && userData.role !== "admin")
    ) {
      return <Navigate to="/" />;
    } else return <Box pt={{ xs: "56px", sm: "72px" }}>{element}</Box>;
  } else if (isPublic) {
    return <Box>{element}</Box>;
  } else {
    return <Navigate to="/authentication/sign-in" />;
  }
};

export default function App() {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    darkMode,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
  } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            // element={route.component}
            element={
              <PrivateRoute
                element={route.component}
                isPublic={!!route.is_public}
                isAdmin={!!route.is_admin}
              />
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brandName="AIC LINK"
              routes={routes}
              fontWeight="700"
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <NavBar />
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="AIC LINK"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}

      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
