import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, Backdrop, Fade } from "@mui/material";
import { useState } from "react";
import MDButton from "components/MDButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  p: 3,
  border: "none",
  borderRadius: "12px",
  outline: "none",
};

const CustomModal = ({ isOpen, onClose, onSubmit, loading }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Fade in={isOpen}>
          <MDBox sx={style}>
            <MDTypography
              id="transition-modal-description"
              sx={{
                mb: 4,
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                color: "#3C322D",
                textAlign: "center",
              }}
            >
              Are you sure you want to delete?
            </MDTypography>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={6}>
                <MDBox>
                  <MDButton
                    variant="gradient"
                    className="no-hover-color-change"
                    color="baseColor"
                    size="medium"
                    onClick={onSubmit}
                    sx={{
                      fontSize: "16px",
                      width: "100%",
                      height: "42px",
                      pr: 0,
                      pl: 0,
                      borderRadius: "4px",
                      textTransform: "none",
                    }}
                  >
                    Yes
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox>
                  <MDButton
                    variant="gradient"
                    sx={{
                      fontSize: "16px",
                      width: "100%",
                      height: "42px",
                      pr: 0,
                      pl: 0,
                      textTransform: "none",
                      borderRadius: "4px",
                    }}
                    color="baseColorSecondary"
                    onClick={onClose}
                  >
                    No
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Fade>
      </Modal>
    </div>
  );
};

function DeleteAction({ onSubmit, loading }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Grid xs={12} md={4}>
      <CustomModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      />
      <MDBox>
        {loading ? (
          <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
        ) : (
          <DeleteIcon
            onClick={openModal}
            color="white"
            sx={{ width: "25px", height: "25px", ml: 2, cursor: "pointer" }}
          />
        )}
      </MDBox>
    </Grid>
  );
}

export default DeleteAction;
