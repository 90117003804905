import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import { transactionState } from "store/states";
import { useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";

export default function TransitionsModal({ open, setOpen }) {
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");

  const { eventsLoading, eventAlerts } = useSelector(transactionState);

  const handleSortClick = (criteria) => {
    if (criteria === sortCriteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCriteria(criteria);
      setSortOrder("asc");
    }
  };

  const sortedEvents = useMemo(() => {
    const eventAlertsCopy = [...eventAlerts];
    return eventAlertsCopy.sort((a, b) => {
      if (sortCriteria === "date") {
        const dateA = new Date(a.event_date);
        const dateB = new Date(b.event_date);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        const descriptionA = a.event_description.toLowerCase();
        const descriptionB = b.event_description.toLowerCase();
        return sortOrder === "asc"
          ? descriptionA.localeCompare(descriptionB)
          : descriptionB.localeCompare(descriptionA);
      }
    });
  }, [eventAlerts, sortCriteria, sortOrder]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Fade in={open}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              py: 3,
              border: "none",
              borderRadius: "12px",
              width: { xs: "370px", md: "520px" },
              outline: "none",
            }}
          >
            {eventsLoading ? (
              <MDBox px={3}>
                <MDTypography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    color: "#2C241C",
                    fontSize: { xs: "21px", md: "24px" },
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Event Alert{" "}
                </MDTypography>
                <MDTypography
                  id="transition-modal-description"
                  sx={{
                    fontSize: { xs: "13px", sm: "16px" },
                    lineHeight: "20px",
                    fontWeight: 400,
                    fontFamily: "Poppins, sans-serif",
                    color: "#2C241C",
                  }}
                >
                  Please wait while we retrieve the historical event.
                </MDTypography>
                <MDBox
                  sx={{
                    mt: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={20} color="inherit" />
                </MDBox>
              </MDBox>
            ) : (
              <>
                <MDBox px={3}>
                  <MDTypography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      color: "#2C241C",
                      fontSize: { xs: "21px", md: "24px" },
                      fontWeight: 500,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Event Alert{" "}
                  </MDTypography>
                  <MDTypography
                    id="transition-modal-description"
                    sx={{
                      fontSize: { xs: "13px", sm: "16px" },
                      lineHeight: "20px",
                      fontWeight: 400,
                      fontFamily: "Poppins, sans-serif",
                      color: "#2C241C",
                    }}
                  >
                    Here is a historical event of an aircraft to gain insight
                    into its ownership and financial background.{" "}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <Grid container>
                    <Grid
                      item
                      container
                      sx={{
                        borderBottom: "1px solid #3C322D",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid item xs={3}>
                        <MDTypography
                          variant="h5"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "8px 0",
                            fontSize: "12px",
                            fontWeight: 400,
                            paddingLeft: 3,
                            color: "#3C322D",
                          }}
                        >
                          Date
                          <MDBox
                            pl={2}
                            display="flex"
                            flexDirection="column"
                            sx={{
                              margin: "0 5px",
                              fontSize: "14px",
                            }}
                            onClick={() => handleSortClick("date")}
                          >
                            <Icon
                              sx={{ marginBottom: "-8px", color: "#3C322D" }}
                            >
                              keyboard_arrow_up
                            </Icon>
                            <Icon sx={{ color: "#3C322D" }}>
                              keyboard_arrow_down
                            </Icon>
                          </MDBox>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={9}>
                        <MDTypography
                          variant="h5"
                          sx={{
                            display: "flex",
                            padding: "8px 0",
                            fontSize: "12px",
                            fontWeight: 200,
                            paddingLeft: 1,
                            color: "#3C322D",
                          }}
                        >
                          Event Description
                          <MDBox
                            pl={2}
                            display="flex"
                            flexDirection="column"
                            sx={{
                              margin: "0 5px",
                              fontSize: "14px",
                            }}
                            onClick={() => handleSortClick("description")}
                          >
                            <Icon
                              sx={{ marginBottom: "-8px", color: "#3C322D" }}
                            >
                              keyboard_arrow_up
                            </Icon>
                            <Icon sx={{ color: "#3C322D" }}>
                              keyboard_arrow_down
                            </Icon>
                          </MDBox>
                        </MDTypography>
                      </Grid>
                    </Grid>
                    {sortedEvents.map((event, index) => (
                      <Grid
                        item
                        container
                        spacing={0}
                        alignItems="center"
                        key={index}
                        mx={3}
                        sx={{
                          borderBottom:
                            index === sortedEvents.length - 1
                              ? "none"
                              : "1px solid #3C322D",

                          lineHeight: "25px",
                        }}
                      >
                        <Grid item xs={3}>
                          <MDTypography
                            variant="p"
                            sx={{
                              padding: "0 0",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#3C322D",
                            }}
                          >
                            {event.event_date}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={9}>
                          <MDTypography
                            variant="p"
                            sx={{
                              padding: "0px 0",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#3C322D",
                            }}
                          >
                            {event.event_description}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
                <MDBox mt={2} px={3}>
                  <MDButton
                    pt={1}
                    variant="gradient"
                    color="baseColor"
                    fullWidth
                    sx={{
                      fontSize: { xs: "13px", md: "16px" },
                      height: "42px",
                      borderRadius: "4px",
                      pr: 0,
                      pl: 0,
                      textTransform: "none",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </MDButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </Fade>
      </Modal>
    </div>
  );
}
