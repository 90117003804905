import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import bgImage from "assets/aic-bg-image.png";

import PageLayout from "components/LayoutContainers/PageLayout";
import { ToastContainer } from "react-toastify";

function SinglePageLayout({ children }) {
  return (
    <>
      <ToastContainer position="top-right" style={{ width: "fit-content" }} />
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <PageLayout>
            <MDBox px={{ xs: 2, sm: 1 }} width="100%" minHeight="100vh" overflowY="auto"
              sx={{
                backgroundPosition: { xs: "center", lg: "initial" },
                position: "relative",
                backgroundImage: `url(${bgImage})`,
                backgroundBlendMode: "multiply",
                backgroundSize: "cover",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#C8B19C",
                  opacity: "81%",
                  zIndex: 0,
                },
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                height="100%"
                zIndex={1}
                py={2}
              >
                {children}
              </Grid>
            </MDBox>
          </PageLayout>
        </Grid>
      </Grid>
    </>
  );
}

SinglePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SinglePageLayout;
