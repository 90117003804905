import { useEffect, useRef, useState } from "react";
import SinglePageLayout from "layouts/SinglePageLayout";
import { Grid } from "@mui/material";
import { ForgotForm, ResendForm } from "components/ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  resetFormsAndErrors,
  resendInvite,
  resetAuthErr,
} from "store/actions";
import { INVITE_TYPES } from "context/constants";

const authState = (state) => state.Authentication;

function Basic() {
  const dispatch = useDispatch();
  const { loading, error, resendForm } = useSelector(authState);
  const emailRef = useRef(null);

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: "",
  });

  const validateEmail = (e) => {
    if (!e) {
      return "Please enter your email.";
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(e)) {
      return "Please enter a valid email address.";
    }

    return "";
  };
  const handleOnChangeInput = (e, field) => {
    if (error) dispatch(resetAuthErr());

    const { value } = e;
    const newFormErrors = { ...errors };

    if (field === "email") {
      setEmail(value);
      newFormErrors.email = "";
    }
    setErrors(newFormErrors);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    const emailValidationResult = validateEmail(email);
    setErrors({ email: emailValidationResult });
    if (emailValidationResult) {
      if (!email) emailRef.current.focus();
      return null;
    }

    dispatch(forgotPassword({ email }));
  };

  useEffect(() => {
    dispatch(resetFormsAndErrors());
  }, []);

  return (
    <SinglePageLayout>
      <Grid
        mx={{ sm: "auto" }}
        maxWidth={{ xs: "100%", sm: "446px" }}
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        {resendForm ? (
          <ResendForm
            apiLoading={loading}
            onClick={() =>
              dispatch(resendInvite({ email, invite_type: INVITE_TYPES.reset }))
            }
          />
        ) : (
          <ForgotForm
            handleSignIn={handleSignIn}
            handleOnChangeInput={handleOnChangeInput}
            email={email}
            errors={errors}
            apiLoading={loading}
            emailRef={emailRef}
          />
        )}
      </Grid>
    </SinglePageLayout>
  );
}

export default Basic;
