import { useEffect, useState, useRef, createRef } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  SignUpForm,
  SignUpData,
  VerifyAccountOTP,
} from "components/SignUpForm";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  signUp,
  resetAuthErr,
  verifyInvite,
  registerUser,
  resendInvite,
} from "store/actions";
import Subscription from "pages/subscription";
import { isEmpty } from "radash";
import { INVITE_TYPES, MODAL_STYLE } from "context/constants";
import { resetUserData, fetchUserInfo } from "store/actions";
import { UnitedStates } from "context/constants";
import { useNavigate } from "react-router-dom";
import { showCustomToast, passwordValidate, loggedInUser } from "utils";
import { authState, planState } from "store/states";
import SinglePageLayout from "layouts/SinglePageLayout";

const initialState = {
  password: false,
  cpassword: false,
};

function getSteps() {
  return ["CREDENTIALS", "CONFIRMATION", "USER INFO", "SUBSCRIBE"];
}

const OTP_LENGTH = 6;

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokenData = loggedInUser("signUpToken");

  const {
    loading,
    error,
    verificationForm,
    registerForm,
    curUserInfo,
    verificationText,
  } = useSelector(authState);
  const { plans } = useSelector(planState);

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    cpassword: "",
  });
  const refInputFirst = useRef({
    email: null,
    password: null,
    cpassword: null,
  });

  const refInputThird = useRef({
    firstName: null,
    lastName: null,
    organizationName: null,
    phoneNumber: null,
    address: null,
    city: null,
    zipCode: null,
  });
  const [secondFormErrors, setSecondFormErrors] = useState({});
  const labels = getSteps();

  const [passwordVisible, setPasswordVisible] = useState(initialState);
  const [step, setStep] = useState(0);
  const [countryCodeState, setCountryCodeState] = useState(null);
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    organizationName: "",
    phoneNumber: "",
    address: "",
    suiteNumber: "",
    city: "",
    state: "",
    title: "",
    zipCode: "",
    country: UnitedStates,
  });

  const [inputs, setInputs] = useState(Array(OTP_LENGTH).fill(""));
  const inputRefs = useRef([...Array(OTP_LENGTH)].map(() => createRef()));
  const formRef = useRef(null);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");

  const togglePasswordVisibility = (passwordField) => {
    setPasswordVisible((oldState) => ({
      ...oldState,
      [passwordField]: !oldState[passwordField],
    }));
  };

  useEffect(() => {
    const userTokenObj = loggedInUser("signUpToken");
    if (userTokenObj?.exp) {
      dispatch(fetchUserInfo());
    }
  }, [step]);

  useEffect(() => {
    if (registerForm & (step !== 3)) {
      setStep(3);
      navigate("/authentication/sign-up", { replace: true });
    }
  }, [registerForm]);

  useEffect(() => {
    if (verificationForm & (step !== 1)) setStep(1);
  }, [verificationForm]);

  useEffect(() => {
    if (verificationText === "Token verified") {
      setTimeout(() => {
        setIsModalOpen(false);
        setStep(2);
      }, 500);
    }
  }, [verificationText]);

  const validateEmail = (e) => {
    if (!e) {
      return "Please enter your email.";
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(e)) {
      return "Please enter a valid email address.";
    }

    return "";
  };

  const validatePassword = (e) => {
    if (!e) return "Please enter your password.";

    if (!passwordValidate(e))
      return "Password must be 8 characters or more, including alphanumeric and special characters.";

    return "";
  };

  const validateConfirmPassword = (e) => {
    if (password !== e) {
      return "Password and Confirm Password do not match";
    }
    return "";
  };

  const handleOnChangeInput = (e, field) => {
    const { value } = e;
    const newFormErrors = { ...errors };
    if (error) dispatch(resetAuthErr());

    if (field === "email") {
      setEmail(value);
      newFormErrors.email = "";
    } else if (field === "password") {
      setPassword(value);
      newFormErrors.password = "";
    } else if (field === "cpassword") {
      setCPassword(value);
      newFormErrors.cpassword = validateConfirmPassword(value);
    }
    setErrors(newFormErrors);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      const cleanedInput = value.replace(/\D/g, "");
      let formattedPhoneNumber = "";

      for (let i = 0; i < cleanedInput.length && i < 10; i++) {
        if (i === 0) {
          formattedPhoneNumber += "(";
        } else if (i === 3) {
          formattedPhoneNumber += ") ";
        } else if (i === 6) {
          formattedPhoneNumber += "-";
        }
        formattedPhoneNumber += cleanedInput.charAt(i);
      }

      setFormState({ ...formState, [name]: formattedPhoneNumber });
    } else if (name === "zipCode" && value.length > 6) return;
    else {
      setFormState({ ...formState, [name]: value });
    }

    setSecondFormErrors({ ...secondFormErrors, [name]: "" });
  };

  const handleSelectChange = (newVal, field) => {
    setFormState({ ...formState, [field]: newVal });
    if (secondFormErrors[field]) {
      setSecondFormErrors({ ...secondFormErrors, [field]: "" });
    }
  };

  const handleSignIn = (e) => {
    const emailValidationResult = validateEmail(email);
    const passwordValidationResult = validatePassword(password);
    const confirmPasswordValidationResult = validateConfirmPassword(cpassword);

    setErrors({
      email: emailValidationResult,
      password: passwordValidationResult,
      cpassword: confirmPasswordValidationResult,
      rememberMe: !rememberMe ? "Kindly agree to terms and conditions" : "",
    });

    if (passwordValidationResult.startsWith("Password must be")) {
      showCustomToast(passwordValidationResult, { autoClose: 1200, isSuccess: false });
    }

    if (
      emailValidationResult ||
      passwordValidationResult ||
      confirmPasswordValidationResult ||
      !rememberMe
    ) {
      if (!email) refInputFirst.current.email.focus();
      else if (!password) refInputFirst.current.password.focus();
      else if (!cpassword) refInputFirst.current.cpassword.focus();
      return null;
    }

    dispatch(signUp({ email, password }));
  };

  const veriyToken = (e, val) => {
    let newInputs = inputRefs.current.map((val) => val.current.value);
    if (val) newInputs = val;
    e.preventDefault();
    const isAnyFieldEmpty = newInputs.some((input) => !input.trim());
    if (isAnyFieldEmpty) {
      setOtpErrorMessage(
        "All fields must be filled. Please check and try again."
      );
      return;
    }

    dispatch(
      verifyInvite({
        email: email || tokenData.email,
        invite_link: newInputs.join(""),
        invite_type: INVITE_TYPES.email,
      })
    );
  };

  const handleResend = (e) => {
    e.preventDefault();

    dispatch(
      resendInvite({
        email: email || tokenData.email,
        invite_type: INVITE_TYPES.email,
      })
    );
  };

  const validate = (values) => {
    const stateErrors = {};
    if (!values.firstName) {
      stateErrors.firstName = "First name is required";
    }
    if (!values.lastName) {
      stateErrors.lastName = "Last name is required";
    }
    if (!values.phoneNumber || values.phoneNumber.length < 14) {
      stateErrors.phoneNumber = "Phone number is required";
    }
    if (!values.address) {
      stateErrors.address = "Address is required";
    }
    if (!values.city) {
      stateErrors.city = "City is required";
    }
    if (!values.state) {
      stateErrors.state = "State is required";
    }
    if (!values.zipCode || values.zipCode.length < 5) {
      stateErrors.zipCode = "ZipCode is required";
    }
    if (!values.country) {
      stateErrors.country = "Country is required";
    }
    if (!values.title) {
      stateErrors.title = "Title is required";
    }

    return stateErrors;
  };

  const handleFormChange = () => {
    switch (step) {
      case 0:
        dispatch(signUp({ email, password }));
        break;
      case 2:
        const errorsObj = validate(formState);
        setSecondFormErrors(errorsObj);
        if (!isEmpty(errorsObj)) {
          if(!formState.firstName) refInputThird.current.firstName.focus();
          else if(!formState.lastName) refInputThird.current.lastName.focus();
          else if(!formState.organizationName) refInputThird.current.organizationName.focus();
          else if(!formState.address) refInputThird.current.address.focus();
          else if(!formState.city) refInputThird.current.city.focus();
          else if(!formState.zipCode) refInputThird.current.zipCode.focus();
          else if(!formState.phoneNumber) refInputThird.current.phoneNumber.focus();
          return null;
        }

        dispatch(
          registerUser(!!curUserInfo.job_title, {
            email: tokenData.email,
            first_name: formState.firstName,
            last_name: formState.lastName,
            role: "member",
            phone_no: formState.phoneNumber,
            address: formState.address,
            suite_no: formState.suiteNumber || undefined,
            city: formState.city,
            state: formState.state?.label || "",
            zipcode: formState.zipCode,
            country: formState.country?.label || "",
            job_title: formState.title,
            organization_name: formState.organizationName,
          })
        );
        break;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  const handleKeyPressSignUpData = (e) => {
    if (e.key === "Enter") {
      handleFormChange();
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newValue = value.replace(/\D/g, "");
    setInputs((inputs) =>
      inputs.map((input, i) => (i === index ? newValue : input))
    );

    if (index === OTP_LENGTH - 1 && !!newValue) return veriyToken(e);

    setOtpErrorMessage("");

    if (e.key === "Backspace" && !newValue) {
      if (index) inputRefs.current[index - 1].current.focus();
      return;
    }

    if (newValue && index < OTP_LENGTH - 1) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, OTP_LENGTH)
      .replace(/\D/g, "");

    const newInputs = pasteData
      .split("")
      .concat(Array(OTP_LENGTH - pasteData.length).fill(""));

    setInputs(newInputs);

    if (newInputs.length == 6) return veriyToken(e, newInputs);

    const nextFocusIndex =
      pasteData.length < OTP_LENGTH ? pasteData.length : OTP_LENGTH - 1;
    inputRefs.current[nextFocusIndex].current.focus();
  };

  return (
    <>
      <Modal open={isModalOpen}>
        <Box sx={MODAL_STYLE}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
          </Typography>
        </Box>
      </Modal>
      {step !== 3 ? (
        <SinglePageLayout>
          <Grid
            container
            display="flex"
            flexDirection="column"
            m="auto"
            justifyContent="center"
            maxWidth={{ xs: "100%", sm: "446px" }}
            width="100%"
            height="100vh"
          >
            {step === 0 && (
              <SignUpForm
                apiLoading={loading}
                error={error}
                handleOnChangeInput={handleOnChangeInput}
                handleSetRememberMe={handleSetRememberMe}
                email={email}
                password={password}
                cpassword={cpassword}
                errors={errors}
                passwordVisible={passwordVisible}
                rememberMe={rememberMe}
                handleSignIn={handleSignIn}
                activeStep={step}
                labels={labels}
                togglePasswordVisibility={togglePasswordVisibility}
                formRef={formRef}
                handleKeyPress={handleKeyPress}
                refInputFirst={refInputFirst}
              />
            )}
            {step === 1 && (
              <VerifyAccountOTP
                activeStep={step}
                labels={labels}
                handleResend={handleResend}
                veriyToken={veriyToken}
                setStep={(stepNo, isReset = true) => {
                  if (isReset) dispatch(resetUserData());
                  setStep(stepNo);
                }}
                inputRefs={inputRefs}
                inputs={inputs}
                handleChange={handleChange}
                handlePaste={handlePaste}
                otpErrorMessage={otpErrorMessage}
              />
            )}
            {step === 2 && (
              <SignUpData
                apiLoading={loading}
                apiError={error}
                handleFormChange={handleFormChange}
                handleInputChange={handleInputChange}
                setFormState={setFormState}
                handleSelectChange={handleSelectChange}
                formState={formState}
                secondFormErrors={secondFormErrors}
                activeStep={step}
                labels={labels}
                formRef={formRef}
                handleKeyPress={handleKeyPressSignUpData}
                refInputThird={refInputThird}
              />
            )}
          </Grid>
        </SinglePageLayout>
      ) : (
        <Subscription
          plans={plans}
          activeStep={3}
          setStep={setStep}
          labels={labels}
        />
      )}
    </>
  );
}

export default Basic;
