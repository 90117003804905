import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { West } from "@mui/icons-material";

function ForgotForm({
  handleOnChangeInput,
  handleSignIn,
  email,
  apiLoading,
  errors,
  emailRef
}) {
  return (
    <>
      <Card
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          top: "20px",
          left: "16px",
        }}
      >
        <MDBox>
          <Link to="/authentication/sign-in">
            <MDBox
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <West sx={{ width: "16px", height: "16px", color: "#3C322D" }} />
              <MDTypography
                sx={{
                  color: "#3C322D",
                  textTransform: "none",
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Back to Login
              </MDTypography>
            </MDBox>
          </Link>
        </MDBox>
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "24px", sm: "30px" },
          backgroundColor: "transparent",
          alignItems: "center",
          width: "100%",
        }}
      >
        <MDBox>
          <img
            src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
            style={{
              height: "120px",
              width: "245px",
            }}
          />
        </MDBox>
        <MDBox textAlign="center">
          <MDTypography
            fontSize="16px"
            variant="h4"
            color="#3B3229"
            sx={{ fontWeight: 400 }}
          >
            Enter your email address associated with
            <br />
            your account for a reset password link
          </MDTypography>
        </MDBox>
        <MDBox sx={{ width: "100%" }}>
          <MDBox component="form" role="form" onSubmit={handleSignIn}>
            <MDBox mb={{ xs: 3, sm: "30px" }} sx={{ maxWidth: "446px", width: "100%" }}>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                mb={1}
              >
                Email
              </MDTypography>
              <MDInput
                inputRef={emailRef}
                type="email"
                placeholder="email@mail.com"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => handleOnChangeInput(e.target, "email")}
                className={
                  errors.email !== "" ? "redBorder" : "transparentBorder"
                }
              />
            </MDBox>

            <MDBox mb={{ xs: 3, sm: "30px" }} textAlign="center">
              <MDButton
                variant="gradient"
                className="no-hover-color-change"
                color="baseColor"
                sx={{
                  textTransform: "none",
                  padding: "13px 20px",
                  borderRadius: "4px",
                  fontWeight: 500,
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={handleSignIn}
              >
                Reset Password
                {apiLoading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </MDButton>
            </MDBox>
            <MDBox mb={{ xs: 3, sm: "30px" }} textAlign="center" display={{ xs: "none", sm: "block" }}>
              <Link to="/authentication/sign-in">
                <MDBox
                  sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <West
                    sx={{ width: "16px", height: "16px", color: "#3C322D" }}
                  />
                  <MDTypography
                    sx={{
                      color: "#3C322D",
                      textTransform: "none",
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Back to Login
                  </MDTypography>
                </MDBox>
              </Link>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

function ResendForm({ apiLoading, onClick }) {
  return (
    <>
      <Card
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          top: "20px",
          left: "16px",
        }}
      >
        <MDBox>
          <Link to="/authentication/sign-in">
            <MDBox
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <West sx={{ width: "16px", height: "16px", color: "#3C322D" }} />
              <MDTypography
                sx={{
                  color: "#3C322D",
                  textTransform: "none",
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Back to Login
              </MDTypography>
            </MDBox>
          </Link>
        </MDBox>
      </Card>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "24px", sm: "30px" },
          backgroundColor: "transparent",
          alignItems: "center",
        }}
      >
        <MDBox>
          <img
            src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
            style={{
              height: "120px",
              width: "245px",
            }}
          />
        </MDBox>
        <MDBox textAlign="center">
          <MDTypography
            fontSize="16px"
            variant="h4"
            color="#3B3229"
            sx={{ fontWeight: 400 }}
          >
            We have sent an email to email@mail.com
            <br />
            with a link to reset your password
          </MDTypography>
        </MDBox>
        <MDBox component="form" role="form" textAlign="center">
          <MDBox mt={{ xs: "24px", sm: "30px" }}>
            <MDButton
              variant="gradient"
              className="no-hover-color-change"
              color="baseColor"
              sx={{
                textTransform: "none",
                padding: "13px 20px",
                borderRadius: "4px",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "Poppins, sans-serif",
              }}
              onClick={onClick}
            >
              Reset Password{" "}
              {apiLoading ? (
                <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
              ) : null}
            </MDButton>
          </MDBox>
          <MDBox mt={{ xs: "24px", sm: "30px" }} display={{ xs: "none", sm: "block" }}>
            <Link to="/authentication/sign-in">
              <MDBox
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <West sx={{ width: "16px", height: "16px", color: "#3C322D" }}/>
                <MDTypography
                  sx={{
                    color: "#3C322D",
                    textTransform: "none",
                    fontWeight: 400,
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Back to Login
                </MDTypography>
              </MDBox>
            </Link>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}
export { ForgotForm, ResendForm };
