import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function DefaultPricingCard({
  color,
  badge,
  price,
  action,
  className,
  variant,
  textColor,
  lable,
  margin,
}) {
  return (
    <Card>
      <MDBox
        bgColor={color}
        borderRadius="xl"
        variant={variant}
        // width={{ sx: "313px", md: "224px" }}
        height={{ sx: "127px", md: "167px" }}
        sx={{ padding: "0 8px", display: "flex", flexDirection: "column" }}
      >
        <MDBox
          bgColor={badge.color}
          width="104px"
          px={3}
          textAlign="center"
          pt={0}
          pb={0.5}
          mt={2}
          mx="auto"
          borderRadius="section"
          lineHeight={1}
          sx={{
            border: badge.border && badge.border,
            justtifyContent: "flex-start",
          }}
        >
          <MDTypography
            variant="caption"
            sx={{ fontSize: "12px", fontWeight: 500 }}
            color={badge.color === "light" ? "dark" : "white"}
          >
            {badge.label}
          </MDTypography>
        </MDBox>
        <MDBox textAlign="center">
          <MDBox>
            <MDTypography
              variant="h3"
              margin={margin}
              color={textColor}
              fontSize={{ xs: "18px", md: "22px" }}
              sx={{ fontWeight: 700 }}
            >
              {" "}
              {lable}
            </MDTypography>
            {badge.label === "Premium" && (
              <MDBox mt="16px" mb="16px">
                <MDTypography
                  display="inline"
                  component="small"
                  variant="h5"
                  color="#F2F7F2"
                  fontSize={{ xs: "18px", md: "24px" }}
                  sx={{ fontWeight: 700 }}
                >
                  {price.currency}
                  {price.value}
                </MDTypography>
                <MDTypography
                  display="inline"
                  component="small"
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                  variant="h5"
                  color="#F2F7F2"
                >
                  /{price.type}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <MDBox pb={{ xs: 2, md: 3 }} px={2}>
          {action.type === "internal" ? (
            <MDBox>
              <MDButton
                mt="8px"
                onClick={action.onClick}
                variant="gradient"
                color="baseColor"
                className={className || ""}
                fullWidth
                fontSize={{ xs: "13px", md: "14px" }}
                sx={{
                  fontWeight: 500,
                  size: { xs: "small", md: "large" },
                  backgroundColor: "#C8B19C",
                  color: "#3C322D",
                  borderRadius: "4px",
                }}
              >
                {action.loading ? (
                  <CircularProgress size={20} color="info" />
                ) : (
                  <>{action.label}</>
                )}
              </MDButton>
            </MDBox>
          ) : (
            <MDBox>
              <MDButton
                onClick={action.onClick}
                className={action.disabled ? "cursor-not-allowed" : ""}
                variant="gradient"
                color="baseColor"
                fullWidth
                fontSize={{ xs: "13px", md: "14px" }}
                sx={{
                  fontWeight: 500,
                  padding: "0",
                  size: { xs: "small", md: "large" },
                  borderRadius: "4px",
                }}
              >
                {action.loading ? (
                  <CircularProgress size={20} color="info" />
                ) : (
                  <>{action.label}</>
                )}
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
DefaultPricingCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.instanceOf(Array).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default DefaultPricingCard;
