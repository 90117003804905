import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import UserPrompt from "components/UserPrompt";

function DeleteAction({ onSubmit, loading }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Grid xs={12} md={4}>
      <UserPrompt
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      />
      <MDBox>
        {loading ? (
          <CircularProgress size={20} sx={{ ml: 2 }} color="white" />
        ) : (
          <DeleteIcon
            onClick={openModal}
            sx={{ width: "25px", height: "25px", ml: 2, cursor: "pointer", color: "white !important" }}
          />
        )}
      </MDBox>
    </Grid>
  );
}

export default DeleteAction;
