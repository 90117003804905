import SignInBasic from "pages/sign-in";
import SignUpBasic from "pages/sign-up";
import ForgetBasic from "pages/forget-password";
import Subscription from "pages/subscription";
import ResetPassword from "pages/reset-password";
import TokenVerification from "pages/token-verification";
import Icon from "@mui/material/Icon";
import Admin from "pages/admin";
import Dashboard from "./pages/dashboard";
import saveSearch from "assets/save_searche.svg";
import settings from "assets/settings.svg";
import dashboard from "assets/dashboard.svg";
import account from "assets/account.svg";
import TermsConditionPage from "pages/terms-page";
import PrivacyPolicyPage from "pages/privacy-policy";

const routes = [
  // { type: "divider", key: "divider-0" },

  // {
  //   type: "collapse",
  //   name: "User",
  //   key: "title",
  //   identifier: "logged_in_user",
  //   collapse: [
  //     {
  //       name: "Logout",
  //       href: "/authentication/sign-in",
  //       icon: <img src={dashboard} />,
  //     },
  //   ],
  // },
  // { type: "dividerleft", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    component: <Dashboard />,
    key: "",
    route: "/",
    href: "/",
    icon: <img src={dashboard} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Saved Searches",
    key: "saved_searches",
    icon: <img src={saveSearch} />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <img src={settings} />,
    noCollapse: true,
  },
  {
    name: "T&Cs",
    key: "t&cs",
    route: "/T&Cs",
    hideInMenu: true,
    component: <TermsConditionPage />,
    is_public: true
  },
  {
    name: "PP",
    key: "pp",
    route: "/PP",
    hideInMenu: true,
    component: <PrivacyPolicyPage />,
    is_public: true
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    hideInMenu: true,
    icon: <Icon fontSize="medium">security</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "signin",
        route: "/authentication/sign-in",
        component: <SignInBasic />,
      },
      {
        name: "Token Verification",
        key: "token_verification",
        route: "/token-verification",
        component: <TokenVerification />,
      },
      {
        name: "ForgotPassword",
        key: "forgotpassword",
        route: "/authentication/forgot-password",
        component: <ForgetBasic />,
      },
      {
        name: "SignUp",
        key: "signup",
        route: "/authentication/sign-up",
        component: <SignUpBasic />,
      },

      {
        name: "Subscriptions",
        key: "subscriptions",
        route: "/authentication/subscriptions",
        component: <Subscription />,
      },
      {
        name: "ResetPassword",
        key: "resetpassword",
        route: "/authentication/reset-password",
        component: <ResetPassword />,
      },
    ].map((row) => ({ ...row, is_public: true })),
  },
  {
    type: "collapse",
    name: "User Accounts",
    icon: <img src={account} />,
    key: "admin",
    route: "/admin",
    href: "/admin",
    roles: ["admin"],
    is_admin: true,
    component: <Admin />,
    noCollapse: true,
  },
];

export default routes;
