import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  ALL_COUNTRIES,
  STATES_BY_COUNTRY,
  TITLE,
  UnitedStates,
} from "context/constants";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "components/FormField";
import emailicon from "assets/Email_Confirmation.svg";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import eyeImg from "assets/Eye.svg";

const defaulStates = [{ label: "Select One", value: "" }];
import { useDispatch, useSelector } from "react-redux";
import { resetFormsAndErrors, resetResendSecs } from "store/actions";
import { authState } from "store/states";
import CustomStepper from "components/CustomStepper";
import { East, ExpandMore, West } from "@mui/icons-material";

function SignUpForm({
  handleOnChangeInput,
  email,
  apiLoading,
  password,
  cpassword,
  errors,
  passwordVisible,
  rememberMe,
  handleSetRememberMe,
  handleSignIn,
  activeStep,
  labels,
  togglePasswordVisibility,
  formRef,
  handleKeyPress,
  refInputFirst,
}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "24px", md: "30px" },
        backgroundColor: "transparent",
        alignItems: "center",
      }}
    >
      <MDBox>
        <img
          src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
          style={{
            height: "120px",
            width: "245px",
          }}
        />
      </MDBox>
      <MDBox textAlign="center">
        <MDTypography
          variant="h4"
          color="#3B3229"
          sx={{ fontWeight: 400 }}
          fontSize="16px"
        >
          Create an account with us today <br />
          by entering your following details below.
        </MDTypography>
      </MDBox>
      <MDBox sx={{ maxWidth: "446px", width: "100%" }}>
        <CustomStepper activeStep={activeStep} labels={labels} />
        <MDBox
          mt="10px"
          component="form"
          role="form"
          onSubmit={handleSignIn}
          ref={formRef}
        >
          <MDBox mb="10px">
            <MDTypography
              variant="h2"
              sx={{ fontWeight: 700 }}
              color="#3B3229"
              fontSize="14px"
              mb="10px"
            >
              Email
            </MDTypography>
            <MDInput
              inputRef={(ref) =>
                (refInputFirst.current = {
                  ...refInputFirst.current,
                  email: ref,
                })
              }
              type="email"
              variant="standard"
              placeholder="email@mail.com"
              fullWidth
              value={email}
              onKeyPress={handleKeyPress}
              onChange={(e) => handleOnChangeInput(e.target, "email")}
              className={
                errors.email !== "" ? "redBorder" : "transparentBorder"
              }
            />
          </MDBox>
          <MDBox mb="10px">
            <MDTypography
              variant="h2"
              sx={{ fontWeight: 700 }}
              color="#3B3229"
              fontSize="14px"
              mb="10px"
            >
              Password
            </MDTypography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MDInput
                inputRef={(ref) =>
                  (refInputFirst.current = {
                    ...refInputFirst.current,
                    password: ref,
                  })
                }
                variant="standard"
                type={passwordVisible.password ? "text" : "password"}
                placeholder="Type Here"
                fullWidth
                value={password}
                onChange={(e) => handleOnChangeInput(e.target, "password")}
                className={
                  errors.password !== "" ? "redBorder" : "transparentBorder"
                }
                onKeyPress={handleKeyPress}
              />
              <img
                src={eyeImg}
                onClick={() => togglePasswordVisibility("password")}
                style={{
                  cursor: "pointer",
                  zIndex: 999,
                  position: "absolute",
                  right: "5%",
                }}
              />
            </div>
            <MDTypography
              variant="h2"
              color="#3B3229"
              fontSize="10px"
              onClick={handleSetRememberMe}
              sx={{
                mt: "10px",
                fontWeight: 400,
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              Must 12 character long minimum
            </MDTypography>
          </MDBox>
          <MDBox mb={{ xs: "24px", sm: "10px" }}>
            <MDTypography
              variant="h2"
              sx={{ fontWeight: 700 }}
              color="#3B3229"
              fontSize="14px"
              mb="10px"
            >
              Confirm Password
            </MDTypography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MDInput
                inputRef={(ref) =>
                  (refInputFirst.current = {
                    ...refInputFirst.current,
                    cpassword: ref,
                  })
                }
                variant="standard"
                type={passwordVisible.cpassword ? "text" : "password"}
                placeholder="Type Here"
                fullWidth
                value={cpassword}
                onChange={(e) => handleOnChangeInput(e.target, "cpassword")}
                className={
                  errors.cpassword !== "" ? "redBorder" : "transparentBorder"
                }
                onKeyPress={handleKeyPress}
              />
              <img
                src={eyeImg}
                onClick={() => togglePasswordVisibility("cpassword")}
                style={{
                  cursor: "pointer",
                  zIndex: 999,
                  position: "absolute",
                  right: "5%",
                }}
              />
            </div>
            <MDTypography
              variant="h2"
              color="#3B3229"
              fontSize="10px"
              onClick={handleSetRememberMe}
              sx={{
                mt: 1,
                fontWeight: 400,
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              Must 12 character long minimum
            </MDTypography>
          </MDBox>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={9} md={9} lg={9} xl={9} textAlign="center">
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <Checkbox
                  sx={{
                    cursor: "pointer",
                    "& .MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                      border: errors.rememberMe ? "1px solid red" : "1px solid",
                      backgroundColor: "#3C322D3D",
                      borderRadius: "4px",
                    },
                  }}
                  checked={rememberMe}
                  onChange={handleSetRememberMe}
                />
                <MDTypography
                  variant="button"
                  color="#3C322D"
                  fontSize="14px"
                  onClick={handleSetRememberMe}
                  sx={{
                    fontWeight: 400,
                    cursor: "pointer",
                    userSelect: "none",
                    marginLeft: "-10px",
                  }}
                >
                  &nbsp;&nbsp;I agree with AIC&rsquo;s{" "}
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="#3C322D"
                  fontSize="14px"
                  sx={{
                    fontWeight: 400,
                    cursor: "pointer",
                    userSelect: "none",
                    marginLeft: "5px",
                  }}
                >
                  <Link to="/T&Cs" target="_blank">
                    <span
                      style={{
                        fontWeight: 700,
                        textDecoration: "none",
                        color: "#3C322D",
                      }}
                    >
                      terms and conditions
                    </span>
                  </Link>
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>

          <MDBox textAlign="center" mt={{ xs: "24px", sm: "30px" }}>
            <MDButton
              variant="gradient"
              className="no-hover-color-change"
              color="baseColor"
              sx={{
                textTransform: "none",
                padding: "13px 20px",
                borderRadius: "4px",
                fontWeight: 500,
                fontSize: "14px",
              }}
              onClick={handleSignIn}
            >
              <span
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                Next{" "}
                <span
                  style={{ width: "16px", height: "16px", textAlign: "center" }}
                >
                  <East />{" "}
                </span>
                {apiLoading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </span>
            </MDButton>
          </MDBox>
          <MDBox textAlign="center" mt={{ xs: "24px", sm: "30px" }} padding="13px 20px">
            <MDTypography
              variant="h4"
              color="#3B3229"
              sx={{ fontWeight: 400 }}
              fontSize="14px"
            >
              Already have an account?{" "}
              <Link to="/authentication/sign-in">
                <span
                  style={{
                    color: "#3B3229",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  Log In
                </span>
              </Link>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function SignUpData({
  handleFormChange,
  handleInputChange,
  setFormState,
  handleSelectChange,
  apiLoading,
  formState,
  secondFormErrors,
  activeStep,
  labels,
  formRef,
  handleKeyPress,
  refInputThird,
}) {
  const dispatch = useDispatch();
  const [searchInputs, setSearchInputs] = useState({
    country: "",
    state: "",
    title: "",
  });
  const [open, setOpen] = useState({
    role: false,
    state: false,
  });
  const { curUserInfo } = useSelector(authState);

  useEffect(() => {
    if (curUserInfo.job_title && !formState.title) {
      setFormState((prev) => ({
        ...prev,
        phoneNumber: curUserInfo.phone_no,
        firstName: curUserInfo.first_name,
        lastName: curUserInfo.last_name,
        organizationName: curUserInfo.organization_name,
        address: curUserInfo.address,
        suiteNumber: curUserInfo.suite_no,
        city: curUserInfo.city,
        state: { label: curUserInfo.state },
        title: curUserInfo.job_title,
        zipCode: curUserInfo.zipcode,
        country: ALL_COUNTRIES.find(
          (country) => country.label === curUserInfo.country || UnitedStates
        ),
      }));
    }
  }, []);
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "24px", md: "30px" },
        backgroundColor: "transparent",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        justifyContent: { sm: "center" },
      }}
    >
      <MDBox sx={{ textAlign: "center" }}>
        <img
          src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
          style={{
            height: "120px",
            width: "245px",
          }}
        />
      </MDBox>
      <MDBox textAlign="center">
        <MDTypography
          variant="h4"
          color="#3B3229"
          sx={{ fontWeight: 400 }}
          fontSize="16px"
        >
          Create an account with us today <br />
          by entering your following details below.
        </MDTypography>
      </MDBox>
      <MDBox sx={{ width: "446px" }}>
        <CustomStepper activeStep={activeStep} labels={labels} />
      </MDBox>
      <MDBox sx={{ maxWidth: "523px", width: "100%" }}>
        <MDBox
          component="form"
          role="form"
          onSubmit={handleFormChange}
          ref={formRef}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                mb={1.5}
              >
                First Name
              </MDTypography>
              <MDInput
                inputRef={(ref) =>
                  (refInputThird.current = {
                    ...refInputThird.current,
                    firstName: ref,
                  })
                }
                variant="standard"
                name="firstName"
                fullWidth
                onKeyPress={handleKeyPress}
                type="text"
                placeholder="Enter Name"
                value={formState.firstName}
                onChange={handleInputChange}
                className={
                  secondFormErrors.firstName &&
                  secondFormErrors.firstName !== ""
                    ? "redBorder"
                    : "transparentBorder"
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                mt={{ xs: 1.5, sm: 0 }}
                mb={1.5}
              >
                Last Name
              </MDTypography>
              <MDInput
                inputRef={(ref) =>
                  (refInputThird.current = {
                    ...refInputThird.current,
                    lastName: ref,
                  })
                }
                name="lastName"
                variant="standard"
                fullWidth
                type="text"
                placeholder="Enter Name"
                value={formState.lastName}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={
                  secondFormErrors.lastName && secondFormErrors.lastName !== ""
                    ? "redBorder"
                    : "transparentBorder"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} mt={1.5}>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                my={1.5}
              >
                Subscription Role
              </MDTypography>
              <Autocomplete
                className={
                  secondFormErrors.title && secondFormErrors.title !== ""
                    ? "redDropdpownBorder"
                    : "transparentBorder"
                }
                open={open.role}
                onOpen={() => setOpen({ ...open, role: true })}
                onClose={() => setOpen({ ...open, role: false })}
                inputValue={searchInputs.title}
                onInputChange={(_, newValue) => {
                  setSearchInputs({ ...searchInputs, title: newValue });
                }}
                onChange={(_, newVal) => {
                  if (newVal) {
                    handleSelectChange(newVal, "title");
                  }
                }}
                popupIcon={
                  <ExpandMore
                    color="white"
                    sx={{ fontWeight: 400, paddingLeft: "8px" }}
                  />
                }
                value={formState.title || TITLE[0]}
                defaultValue={TITLE[0]}
                style={{}}
                options={TITLE}
                renderInput={(params) => (
                  <FormField {...params} InputLabelProps={{ shrink: true }} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} mt={{ xs: 0, sm: 1.5 }}>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                my={1.5}
              >
                Organization Name (Optional)
              </MDTypography>
              <MDInput
                inputRef={(ref) =>
                  (refInputThird.current = {
                    ...refInputThird.current,
                    organizationName: ref,
                  })
                }
                onKeyPress={handleKeyPress}
                name="organizationName"
                variant="standard"
                fullWidth
                type="text"
                placeholder="Enter Name"
                value={formState.organizationName}
                onChange={handleInputChange}
                className={
                  secondFormErrors.organizationName &&
                  secondFormErrors.organizationName !== ""
                    ? "redBorder"
                    : "transparentBorder"
                }
              />
            </Grid>
          </Grid>
          <MDBox mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} mt={1.5}>
                <MDTypography
                  variant="h2"
                  sx={{ fontWeight: 700 }}
                  color="#3B3229"
                  fontSize="14px"
                  my={1.5}
                >
                  Address
                </MDTypography>
                <MDInput
                  inputRef={(ref) =>
                    (refInputThird.current = {
                      ...refInputThird.current,
                      address: ref,
                    })
                  }
                  onKeyPress={handleKeyPress}
                  name="address"
                  variant="standard"
                  fullWidth
                  type="text"
                  value={formState.address}
                  placeholder="e.g. 123 Business Street"
                  onChange={handleInputChange}
                  className={
                    secondFormErrors.address && secondFormErrors.address !== ""
                      ? "redBorder"
                      : "transparentBorder"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} mt={{ xs: 0, sm: 1.5 }}>
                <MDTypography
                  variant="h2"
                  sx={{ fontWeight: 700 }}
                  color="#3B3229"
                  fontSize="14px"
                  my={1.5}
                >
                  Suite/Unit Number(Optional)
                </MDTypography>
                <MDInput
                  onKeyPress={handleKeyPress}
                  name="suiteNumber"
                  fullWidth
                  variant="standard"
                  type="number"
                  placeholder="Enter Number"
                  value={formState.suiteNumber}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ mt: "-16px" }} pt={1.5}>
                <MDTypography
                  variant="h2"
                  sx={{ fontWeight: 700 }}
                  color="#3B3229"
                  fontSize="14px"
                  my={1.5}
                >
                  City
                </MDTypography>
                <MDInput
                  inputRef={(ref) =>
                    (refInputThird.current = {
                      ...refInputThird.current,
                      city: ref,
                    })
                  }
                  onKeyPress={handleKeyPress}
                  name="city"
                  fullWidth
                  placeholder="Enter City"
                  variant="standard"
                  type="text"
                  value={formState.city}
                  onChange={handleInputChange}
                  className={
                    secondFormErrors.city && secondFormErrors.city !== ""
                      ? "redBorder"
                      : "transparentBorder"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: "-16px" }} pt={1.5}>
                <MDTypography
                  variant="h2"
                  sx={{ fontWeight: 700 }}
                  color="#3B3229"
                  fontSize="14px"
                  my={1.5}
                >
                  State
                </MDTypography>
                <Autocomplete
                  defaultValue={defaulStates[0]}
                  inputValue={searchInputs.state}
                  onInputChange={(_, newValue) => {
                    setSearchInputs({ ...searchInputs, state: newValue });
                  }}
                  open={open.state}
                  onOpen={() => setOpen({ ...open, state: true })}
                  onClose={() => setOpen({ ...open, state: false })}
                  value={formState.state || defaulStates[0]}
                  name="state"
                  popupIcon={
                    <ExpandMore
                      color="white"
                      sx={{ fontWeight: 400, paddingLeft: "8px" }}
                    />
                  }
                  onChange={(_, newVal) => {
                    if (newVal) {
                      handleSelectChange(newVal, "state");
                    }
                  }}
                  options={
                    STATES_BY_COUNTRY[formState.country?.value] || defaulStates
                  }
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      className={
                        secondFormErrors.state && secondFormErrors.state !== ""
                          ? "redDropdpownBorder"
                          : "transparentBorder"
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ mt: "-16px" }} pt={1.5}>
                <MDTypography
                  variant="h2"
                  sx={{ fontWeight: 700 }}
                  color="#3B3229"
                  fontSize="14px"
                  my={1.5}
                >
                  Zip Code
                </MDTypography>
                <MDInput
                  inputRef={(ref) =>
                    (refInputThird.current = {
                      ...refInputThird.current,
                      zipCode: ref,
                    })
                  }
                  onKeyPress={handleKeyPress}
                  name="zipCode"
                  fullWidth
                  placeholder="Enter Code"
                  variant="standard"
                  type="text"
                  value={formState.zipCode}
                  onChange={handleInputChange}
                  className={
                    secondFormErrors.zipCode && secondFormErrors.zipCode !== ""
                      ? "redBorder"
                      : "transparentBorder"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: "-16px" }} pt={1.5}>
                <MDTypography
                  variant="h2"
                  sx={{ fontWeight: 700 }}
                  color="#3B3229"
                  fontSize="14px"
                  my={1.5}
                >
                  Country
                </MDTypography>
                <Autocomplete
                  defaultValue={UnitedStates}
                  inputValue={searchInputs.country}
                  onInputChange={(_, newValue) => {
                    setSearchInputs({ ...searchInputs, country: newValue });
                  }}
                  value={formState.country || UnitedStates}
                  name="country"
                  popupIcon={
                    <ExpandMore
                      color="white"
                      sx={{ fontWeight: 400, paddingLeft: "8px" }}
                    />
                  }
                  onChange={(_, newVal) => {
                    if (newVal) {
                      handleSelectChange(newVal, "country");
                    }
                  }}
                  options={ALL_COUNTRIES}
                  renderInput={(params) => {
                    return (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        className={
                          secondFormErrors.country &&
                          secondFormErrors.country !== ""
                            ? "redDropdpownBorder"
                            : "transparentBorder"
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <MDBox mb={2}>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                my={1.5}
              >
                Phone Number
              </MDTypography>
              <MDBox sx={{ display: "flex", gap: "4px" }}>
                <MDInput
                  inputRef={(ref) =>
                    (refInputThird.current = {
                      ...refInputThird.current,
                      phoneNumber: ref,
                    })
                  }
                  onKeyPress={handleKeyPress}
                  name="phoneNumber"
                  type="text"
                  variant="standard"
                  placeholder="(__) __-___"
                  fullWidth
                  value={formState.phoneNumber}
                  onChange={handleInputChange}
                  className={
                    secondFormErrors.phoneNumber &&
                    secondFormErrors.phoneNumber !== ""
                      ? "redBorder"
                      : "transparentBorder"
                  }
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox textAlign="center" mt="20px" mb="30px">
            <MDButton
              variant="gradient"
              className="no-hover-color-change"
              color="baseColor"
              sx={{
                textTransform: "none",
                padding: "13px 20px",
                borderRadius: "4px",
                fontWeight: 500,
                fontSize: { xs: "13px", md: "14px" },
                size: { xs: "small", md: "large" },
              }}
              onClick={handleFormChange}
            >
              <span
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                Next{" "}
                <span
                  style={{
                    width: "16px",
                    height: "16px",
                    textAlign: "center",
                  }}
                >
                  <East />{" "}
                </span>
                {apiLoading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </span>
            </MDButton>
          </MDBox>
          <MDBox>
            <Link to="/authentication/sign-in">
              <MDTypography
                variant="h2"
                sx={{
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent: "center",
                }}
                color="#3B3229"
                fontSize="14px"
                onClick={() => dispatch(resetFormsAndErrors())}
              >
                <West /> Back to Previous Page
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function VerifyAccount({
  setStep,
  apiLoading,
  activeStep,
  labels,
  error,
  handleResend,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gotoLogin = () => {
    setTimeout(() => {
      navigate("/authentication/sign-in", { replace: true });
      dispatch(resetFormsAndErrors());
    }, 1000);
  };
  useEffect(() => {
    if (error) gotoLogin();
  }, [error]);

  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="#202C39"
        borderRadius="lg"
        mx={2}
        mt={-10}
        py={{ xs: 1, md: 3 }}
        mb={1}
        textAlign="center"
      >
        {" "}
        <MDTypography
          variant="h4"
          fontWeight="700"
          fontSize={{ xs: "18px", md: "21px" }}
          color="white"
          mt={1}
        >
          Verify Your Account
        </MDTypography>
        <MDTypography
          display={{ xs: "none", md: "block" }}
          variant="button"
          fontWeight="regular"
          px={3}
          fontSize="14px"
          sx={{
            lineHeight: "17px",
          }}
          color="#F2F7F2"
          my={1}
        >
          {" "}
          To complete your registration, activate your account <br /> by
          checking your email for a confirmation link.
        </MDTypography>
        <MDTypography
          display={{ xs: "block", md: "none" }}
          variant="button"
          fontWeight="regular"
          px={5}
          fontSize="13px"
          sx={{
            lineHeight: "16px",
          }}
          color="#F2F7F2"
          mt={0.5}
        >
          {" "}
          To complete your registration, activate your account by checking your
          email for <br /> a confirmation link.
        </MDTypography>
        <CustomStepper activeStep={activeStep} labels={labels} />
      </MDBox>
      <MDBox pt={1} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox my={2} textAlign="center">
            <img
              src={emailicon}
              className="responsive-image"
              alt="Email Confirmation"
            />
          </MDBox>
          <MDBox my={2}>
            <MDButton
              variant="gradient"
              className="no-hover-color-change"
              color="baseColor"
              fullWidth
              sx={{
                fontSize: { xs: "13px", md: "16px" },
                size: { xs: "small", md: "large" },
              }}
              onClick={handleResend}
            >
              RESEND CONFIRMATION EMAIL{" "}
              {apiLoading ? (
                <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
              ) : null}
            </MDButton>
          </MDBox>
          <MDBox
            textAlign="left"
            sx={{
              fontSize: "16px",
              lineHeight: "20px !important",
              marginTop: { md: "35px" },
            }}
          >
            <MDTypography
              variant="p"
              fontWeight="regular"
              color="#202C39"
              sx={{
                lineHeight: { xs: "16px", md: "17px" },
                fontSize: { xs: "13px", md: "16px" },
              }}
              mt={1}
            >
              If you don't see our account verification email in your inbox,
              please check your spam or junk folder.
            </MDTypography>
            <br />
            <br />
            <MDTypography />
            <MDTypography
              variant="p"
              sx={{
                lineHeight: { xs: "16px", md: "17px" },
              }}
              fontSize={{ xs: "13px", md: "16px" }}
              fontWeight="regular"
              color="#202C39"
            >
              If you're still having trouble, please contact us at <br />
              <a href="mailto:support@aic.com">
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    color: "#3581B8",
                  }}
                >
                  support@aic.com
                </span>
              </a>
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            padding="16px 0 0 0"
          >
            <div
              style={{
                width: "5px",
                height: "5px",
                backgroundColor: "#3498db",
                borderRadius: "50%",
              }}
            />
            <Divider sx={{ flex: 1, border: "1px solid #3581B8" }} />
            <MDTypography
              px={1}
              variant="p"
              fontWeight="bold"
              color="text.secondary"
              fontSize={{ xs: "16px", md: "18px" }}
            >
              OR
            </MDTypography>
            <Divider sx={{ flex: 1, border: "1px solid #3581B8" }} />
            <div
              style={{
                width: "5px",
                height: "5px",
                backgroundColor: "#3498db",
                borderRadius: "50%",
              }}
            />
          </MDBox>
          <MDBox pb={1} textAlign="center">
            <MDTypography
              variant="p"
              fontWeight="regular"
              color="#A4A4A4"
              fontSize={{ xs: "13px", md: "14px" }}
            >
              Already have an account?
            </MDTypography>
          </MDBox>
          <MDBox pb={2}>
            <Link
              to="/authentication/sign-in"
              onClick={() => {
                setStep(0);
                dispatch(resetFormsAndErrors());
              }}
            >
              <MDButton
                variant="outlined"
                color="#3581B8"
                fullWidth
                sx={{
                  color: "#3581B8",
                  size: { xs: "small", md: "large" },
                  fontSize: { xs: "13px", md: "16px" },
                }}
              >
                Login
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function VerifyAccountOTP({
  setStep,
  activeStep,
  labels,
  handleResend,
  veriyToken,
  inputRefs,
  inputs,
  handleChange,
  handlePaste,
  otpErrorMessage,
}) {
  const dispatch = useDispatch();
  const [resendTimer, setResendTimer] = useState(0);
  const { resendSecs, loading, resendInviteLoading } = useSelector(authState);

  useEffect(() => {
    inputRefs.current[0].current.focus();
  }, []);

  useEffect(() => {
    if (resendSecs) {
      setResendTimer(resendSecs);
      dispatch(resetResendSecs());
    }
  }, [resendSecs]);

  useEffect(() => {
    let interval;

    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => interval && clearInterval(interval);
  }, [resendTimer]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "24px", md: "30px" },
        backgroundColor: "transparent",
        alignItems: "center",
        width: "100%",
      }}
    >
      <MDBox textAlign="center">
        <img
          src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
          style={{
            height: "120px",
            width: "245px",
          }}
        />
      </MDBox>
      <MDBox textAlign="center">
        <MDBox sx={{ width: "446px" }}>
          <CustomStepper activeStep={activeStep} labels={labels} />
        </MDBox>
        <MDTypography
          variant="button"
          fontSize="16px"
          sx={{
            fontWeight: 700,
            lineHeight: "16px",
            color: "#3B3229",
          }}
        >
          Enter the 6-digit Confirmation Code
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDBox component="form" role="form">
          <Grid
            container
            display="flex"
            justifyContent="center"
            spacing={2}
            pl={1}
            pt={3}
            pb={6}
          >
            {inputs.map((input, index) => (
              <Grid
                item
                xs={2}
                key={index}
                sx={{
                  width: "100%",
                  px: { md: 2 },
                }}
              >
                <MDInput
                  variant="standard"
                  fullWidth
                  value={input}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleChange(e, index)}
                  onPaste={handlePaste}
                  inputProps={{
                    style: { fontSize: 28, textAlign: "center" },
                    maxLength: 1,
                    ref: inputRefs.current[index],
                  }}
                  sx={{
                    fontWeight: 700,
                    backgroundColor: "#3C322D3D !important",
                    borderBottom: "1px solid black",
                    textAlign: "center",
                  }}
                  className={
                    otpErrorMessage && !input
                      ? "redBorder"
                      : "transparentBorder"
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid container display="flex" justifyContent="center" spacing={2}>
            <Grid
              xs={6}
              item
              sx={{
                width: "100%",
              }}
            >
              <MDButton
                variant="gradient"
                color="baseColor"
                fullWidth
                sx={{
                  textTransform: "none",
                  padding: "13px 20px",
                  borderRadius: "4px",
                  fontWeight: 500,
                  fontSize: "14px",
                  cursor: resendTimer ? "not-allowed" : "pointer",
                }}
                onClick={
                  resendTimer
                    ? () => null
                    : (e) => handleResend(e, setResendTimer)
                }
              >
                {` ${
                  resendTimer ? `RETRY IN ${resendTimer} SECS` : "RESEND CODE"
                }`}
                {resendInviteLoading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </MDButton>
            </Grid>
            <Grid
              xs={6}
              item
              sx={{
                width: "100%",
              }}
            >
              <MDButton
                variant="gradient"
                color="baseColor"
                fullWidth
                className="no-hover-color-change"
                sx={{
                  textTransform: "none",
                  padding: "13px 20px",
                  borderRadius: "4px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={veriyToken}
              >
                CONTINUE{" "}
                {!resendInviteLoading && loading ? (
                  <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                ) : null}
              </MDButton>
            </Grid>
          </Grid>
          <MDBox
            textAlign="center"
            mt={{ xs: 2, md: 0 }}
            sx={{
              color: "#3C322D",
              fontSize: "16px",
              lineHeight: "20px !important",
              marginTop: { md: "35px" },
            }}
          >
            <MDTypography
              variant="p"
              fontWeight="regular"
              color="#3C322D"
              sx={{
                lineHeight: "17px",
              }}
              fontSize="14px"
              mt={1}
            >
              If you don't see our account verification email in your inbox,
              <br />
              please check your spam or junk folder.
            </MDTypography>
            <br />
            <br />
            <MDTypography />
            <MDTypography
              variant="p"
              sx={{
                lineHeight: "17px",
              }}
              fontSize="14px"
              fontWeight="regular"
              color="#3C322D"
            >
              If you're still having trouble, <br /> please contact us at
              <a href="mailto:support@aic.com">
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: "#3C322D",
                  }}
                >
                  &nbsp;support@aic.com
                </span>
              </a>
            </MDTypography>
          </MDBox>
          <MDBox textAlign="center" mt="30px">
            <MDTypography
              variant="h4"
              color="#3B3229"
              sx={{ fontWeight: 400 }}
              fontSize="14px"
            >
              Already have an account?{" "}
              <Link
                to="/authentication/sign-in"
                onClick={() => {
                  setStep(0);
                  dispatch(resetFormsAndErrors());
                }}
              >
                <span
                  style={{
                    color: "#3B3229",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  Log In
                </span>
              </Link>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export { SignUpForm, SignUpData, VerifyAccount, VerifyAccountOTP };
