import { createSlice } from "@reduxjs/toolkit";
import { toggleBoolean } from "utils";

const initialState = {
  loading: false,
  error: null,
  lenderDropZone: false,
};

const lenderSlice = createSlice({
  name: "lender",
  initialState,
  reducers: {
    toggleDropZone: toggleBoolean("lenderDropZone"),
    error: (state, action) => {
      state.loading = false;
      state.error = action.payload.message || "Internal Server Error";
    },
  },
});

export const actions = lenderSlice.actions;

export default lenderSlice.reducer;
