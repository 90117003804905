// axiosInstance.js
import axios from "axios";
import { API_BASE_URL } from "context/constants";
import { getToken } from "utils";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use((config) => {
  console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL)
  const authToken = getToken() || getToken("signUpToken");
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;

  return config;
});

export default axiosInstance;
