import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { getToken, decodeJwt } from "utils";

export default styled(Drawer)(({ theme, ownerState }) => {
  const userRole = decodeJwt(getToken()).role;
  const role_baseColor = "#E2CDBB";
  // const role_baseColor =
  //   userRole === "member"
  //     ? "linear-gradient(180deg, #202C39 0%, #3581B8 183.02%)"
  //     : "linear-gradient(180deg, #3581B8 0%, #202C39 100%)";

  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } =
    ownerState;

  const sidebarWidth = 250;
  const { transparent, white } = palette;
  const { lg, xxl } = boxShadows;
  const { pxToRem } = functions;

  let backgroundValue = role_baseColor;

  if (transparentSidenav) {
    backgroundValue = transparent.main;
  } else if (whiteSidenav) {
    backgroundValue = white.main;
  }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("lg")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    [breakpoints.between("md", "lg")]: {
      boxShadow: transparentSidenav ? "none" : lg,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      maxWidth: 200,
      minWidth: 180,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    [breakpoints.between("xs", "md")]: {
      boxShadow: transparentSidenav ? "none" : lg,
      marginBottom: transparentSidenav ? 0 : "inherit",
      width: "95%",
      minWidth: 180,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("sm")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      marginTop: 25,
      left: "0",
      maxWidth: 200,
      minWidth: 180,
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
