import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import "react-datepicker/dist/react-datepicker.css";
import ReportsLineChart from "components/ReportsLineChart";
import React, { useMemo } from "react";
import moment from "moment";
import { humanize } from "utils";
import planeIcon from "assets/planeIcon.svg";
import planeBackgrounIcon from "assets/planeBackgrounIcon.svg";
import { humanizeKM } from "utils";
import { CircularProgress, Tooltip } from "@mui/material";
import { isEmpty } from "radash";

const FILTER_KEYS = ["start_date", "end_date", "model", "make", "mfr_years"];

function CardGraphContainer({ stats, filters, search, statsLoading }) {

  const isFilterPresent = FILTER_KEYS.some((key) => !isEmpty(filters[key]));

  const { labels, data } = useMemo(() => {
    return (stats?.data || []).reduce(
      (obj, sale) => {
        const { date, price } = sale;
        obj.labels.push(date.includes("-") ? moment(date).format("DD") : date);
        obj.data.push(price);
        return obj;
      },
      { labels: [], data: [] }
    );
  }, [stats?.data]);

  return (
    <Grid container spacing="2px">
      <Grid item xs={12} lg={12} xl={8} sx={{ zIndex: 2 }}>
        <ReportsLineChart
          title="Aircraft Price Trend"
          description={`$${humanize(stats?.avg_price || 0)}`}
          isStatsExist={!isEmpty(stats)}
          date="updated 4 min ago"
          chart={{ labels, datasets: { label: "Price", data } }}
          avgPrice={`Total price ${isFilterPresent ? `$${humanize(stats?.total_price || 0)}` : "" }`}
          statsLoading={statsLoading}
        />
      </Grid>
      <Grid item xs={12} lg={12} xl={4}>
        <Card
          sx={{
            width: "100%",
            borderRadius: "4px",
            padding: "24px",
            gap: "24px",
            backgroundColor: "#C8B19C",
            position: "relative",
            height: "100%",
          }}
        >
          <img
            src={planeBackgrounIcon}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 1,
            }}
            alt="Plane Icon"
          />
          <MDBox display="flex" gap="5px" alignItems="center">
            <MDBox>
              <img
                src={planeIcon}
                style={{ display: "block", margin: "auto" }}
              />
            </MDBox>
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: { xs: "18px", lg: "20px" },
                color: "#FFFFFF",
              }}
            >
              Aircraft Closed
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: { xs: "14px", lg: "16px" },
                color: "#3C322D",
              }}
            >
              Avg Price
            </MDTypography>
            <MDTypography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: { xs: "20px", sm: "24px" },
                color: "#FFFFFF",
              }}
            >
              {!statsLoading && (isFilterPresent || search) && stats?.total
                ? `$${humanize(stats?.avg_price || 0)}`
                : ""}
            </MDTypography>
          </MDBox>
          {statsLoading ? (
            <CircularProgress
              color="inherit"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <MDBox
              display="flex"
              flexDirection="column"
              sx={{
                textAlign: "center",
                padding: "24px 0",
                padding: { xs: "0", sm: "24px 0" },
                gap: { xs: "0", sm: "35px" },
              }}
            >
              <Tooltip
                title={
                  !statsLoading && (isFilterPresent || search) && stats?.total
                }
                placement="top"
              >
                <MDTypography
                  variant="h6"
                  sx={{
                    height: "196px",
                    fontWeight: 600,
                    fontSize: "95px",
                    color: "#3C322D",
                  }}
                  className="responsiveACText"
                >
                  {!statsLoading && stats?.total && (isFilterPresent || search)
                    ? humanizeKM(stats.total || 0)
                    : ""}
                </MDTypography>
              </Tooltip>
              <MDBox sx={{ marginTop: { xs: 0, sm: 0, xl: 0 } }}>
                <MDTypography
                  variant="p"
                  sx={{
                    fontWeight: 300,
                    fontSize: "16px",
                    color: "#FFFFFF",
                  }}
                >
                  Aircraft{isFilterPresent && stats?.total > 1 ? "(s)" : ""}{" "}
                  Closed
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </Card>
      </Grid>
    </Grid >
  );
}

export default CardGraphContainer;
