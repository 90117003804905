import axios from "utils/axios";
import { actions } from "./reducer";
import { tryit } from "radash";
import { showCustomToast, handleSessionExpired, toRaw } from "utils";

export const fetchSavedSearches = (params) => async (dispatch) => {
  dispatch(actions.getSaveSearches());
  const [error, resp] = await tryit(axios.get)("/saved_searches", { params });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getSaveSearchesSuccess(resp.data.data));
};

export const saveSearch = (payload) => async (dispatch) => {
  dispatch(actions.createSaveSearch());
  const [error, resp] = await tryit(axios.post)("/saved_searches", payload);

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    } else if (errResp.status === 400) {
      showCustomToast("Search name should be unique", { autoClose: 1200, isSuccess: false });
    } else showCustomToast("Internal Server Error", { isSuccess: false });

    dispatch(actions.error(errResp));
  } else {
    showCustomToast(
      `You can view ${payload.name} under Saved Searches.`,
      { autoClose: 1200 }
    );
    dispatch(actions.createSaveSearchSuccess(resp.data));
  }
};

export const deleteSearch = (searchId) => async (dispatch) => {
  dispatch(actions.deleteSaveSearch(searchId));
  const [error] = await tryit(axios.delete)(`/saved_searches/${searchId}`);

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    } else if (errResp.status === 404) {
      showCustomToast("Search not found", { isSuccess: false });
    } else showCustomToast("Internal Server Error", { isSuccess: false });
    dispatch(actions.error(errResp));
  } else dispatch(actions.deleteSaveSearchSuccess(searchId));
};

export const setSaveSearch = (data) => (dispatch) =>
  dispatch(actions.setSavedSearch(data));
