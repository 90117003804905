import { isEmpty, tryit } from "radash";
import { toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import successIcon from "assets/successIcon.svg";
import errorIcon from "assets/errorIconToast.svg";

export const env = process.env;

export const toRaw = (obj) => JSON.parse(JSON.stringify(obj));

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const decodeJwt = (token) => {
  const [, data] = tryit(parseJwt)(token);
  return data || {};
};

export const isFalse = (val) => val === false;

export const saveToken = (token, tokenKey = "authKey") =>
  localStorage.setItem(tokenKey, token);

export const delToken = (tokenKey = "authKey") =>
  localStorage.removeItem(tokenKey);

export const getToken = (tokenKey = "authKey") =>
  localStorage.getItem(tokenKey);

export const passwordValidate = (password) => {
  const specialCharacterRegex = /[!@#$%^&*()\-_=+[{\]}|;:'"<>,.?/`~]/;

  return (
    password.length >= 8 &&
    (/[a-z]/.test(password) || /[A-Z]/.test(password)) &&
    /[0-9]/.test(password) &&
    specialCharacterRegex.test(password)
  );
};

const CustomToastDesign = ({ message, isSuccess }) => (
  <div style={{ display: "flex" }}>
    <div
      style={{
        width: "5px",
        backgroundColor: isSuccess ? "#00DF80" : "#FF3B30",
      }}
    ></div>
    <div style={{ width: "359px", display: "flex", alignItems: "center" }}>
      <div style={{ padding: "0 8px 0 16px" }}>
        <img
          src={isSuccess ? successIcon : errorIcon}
          style={{ display: "block" }}
        />
      </div>
      <div
        style={{
          padding: "12px 4px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            color: "#3C322D",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            padding: "12px 0px",
          }}
        >
          {isSuccess ? "Success! " : "Error! "} {message}
        </p>
      </div>
    </div>
  </div>
);

export const showCustomToast = (
  message,
  options = { isSuccess: true, toastId: "Unique" }
) => {

  console.trace("showCustomToast", message, options);
  if (options.isSuccess == undefined) options.isSuccess = true;
  if (options.toastId == undefined) options.toastId = "Unique";
  if (message === "Internal Server Error") options.isSuccess = false;
  const customOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    padding: "0",
    theme: "colored",
    style: {
      marginLeft: "15px",
      marginTop: "10px",
      borderRadius: "4px",
      width: "fit-content",
      padding: "0",
      fontFamily: "Poppins, sans-serif",
    },
    ...options,
    className: "custom-toast",
  };

  toast(<CustomToastDesign message={message} isSuccess={options.isSuccess} />, customOptions);
};

export const handleSessionExpired = () => {
  delToken();
  showCustomToast("Session expired, redirecting...", { isSuccess: false });
  setTimeout(() => {
    window.location.href = "/authentication/sign-in";
  }, 1200);
};

export const sanitizeObj = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, val]) => !isEmpty(val)));

export const humanizeKM = (value) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  } else {
    return value.toString();
  }
};

export const humanize = (num) => Math.round(num).toLocaleString();

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 500);
};

export const loggedInUser = (keyName) => decodeJwt(getToken(keyName));

export const getUserStep = () => {
  const userData = loggedInUser();
  const isAdmin = userData.role === "admin";

  let step = 0;
  if (isFalse(userData.is_user_verified)) {
    step = 1;
  } else if (
    userData.is_user_verified &&
    isFalse(userData.is_user_registered)
  ) {
    step = 2;
  } else if (userData.is_user_verified && userData.is_user_registered) {
    if (
      !isAdmin &&
      isFalse(userData.is_subscribed) &&
      (!userData.trial_end ||
        moment(userData.trial_end).isSameOrBefore(moment()))
    ) {
      step = 3;
    } else if (
      isAdmin ||
      userData.is_subscribed ||
      (userData.trial_end && moment(userData.trial_end).isAfter(moment()))
    ) {
      step = userData.status === "ACTIVE" ? 5 : 4;
    }
  }
  return step;
};

export const getAllYears = (startYear = 1900) => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= startYear; year--) {
    years.push({ value: year, label: year.toString() });
  }
  return years;
};

export function genPaginationList(totalPages, currentPage) {
  const pageList = [];

  if (currentPage > 2) {
    pageList.push(1);
  }

  if (currentPage > 3) {
    pageList.push("...");
  }

  for (
    let i = Math.max(1, currentPage - 1);
    i <= Math.min(totalPages, currentPage + 1);
    i++
  ) {
    pageList.push(i);
  }

  if (currentPage < totalPages - 2) {
    pageList.push("...");
  }

  if (currentPage < totalPages - 1) {
    pageList.push(totalPages);
  }

  return pageList;
}

// TEMPORARY WORKAROUND: Hardcoded API Urls because of env isn't being loaded while building docker
export const tempApiUrl = () => {
  const { host } = window.location;
  if (!host || host.includes("localhost")) return "http://localhost:8080";

  const apiUrl = (env) => `https://aic-backend-${env}-a2j42w6fva-uc.a.run.app`;

  if (host.includes("aic-frontend-uat")) {
    return apiUrl("uat");
  } else if (host.includes("aic-frontend-prod")) {
    return apiUrl("prod");
  } else if (host.includes("192.168.2.222")) {
    return "http://192.168.2.222:8080";
  } else if (host.includes("aic-frontend-p5mv25fvqq-ue.a.run.app") || host.includes("aiclynx.com")) {
    return "https://aic-backend-p5mv25fvqq-ue.a.run.app"
  }
};

export const basicStarter = (state) => {
  state.loading = true;
  state.error = null;
};

export const apiAction = async (promise, onSuccess, onError) => {
  const [error, resp] = await promise;
  if (!error) return onSuccess(resp);

  const errResp = toRaw(error);
  if (errResp.status === 401) return handleSessionExpired();
  onError(errResp);
};

export const toggleBoolean = (field) => (state, action) => {
  const val = action.payload;
  state[field] = typeof val === "boolean" ? val : !state[field];
};

export const isTrialOrSubscribed = (userInfo) => {
  if (!userInfo) userInfo = loggedInUser();
  const isTrialExpired =
    !userData.trial_end || moment(userData.trial_end).isSameOrBefore(moment());
  const isSubscribed = !!(userData.is_subscribed && userData.subscription_type);

  return isTrialExpired || isSubscribed;
};

export const validateSubType = (type) => loggedInUser()?.subscription_type?.includes(type);

export const isLenderPlus = () => validateSubType("Lender Plus");

export const getInitials = (name) => {
  if (name) {
    const names = name?.split(" ");
    return names[0][0] + (names.length > 1 ? names[1][0] : "");
  } else {
    return ""
  }
};
