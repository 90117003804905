import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  delAccId: -1,
  updateAccId: -1,
  error: null,
  usersData: [],
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getUsers: (state) => {
      state.loading = true;
      state.error = null;
    },
    getUsersSuccess: (state, action) => {
      state.loading = false;
      state.usersData = action.payload;
    },
    updateUser: (state, action) => {
      state.updateAccId = action.payload;
      state.loading = true;
      state.error = null;
    },
    updateUserSuccess: (state, action) => {
      const _index = state.usersData.data?.findIndex(
        (row) => row.id === action.payload.id
      );
      if (_index !== -1) {
        state.usersData.data[_index] = action.payload;
      }
      state.updateAccId = -1;
      state.loading = false;
    },
    deleteUser: (state, action) => {
      state.delAccId = action.payload;
      state.error = null;
    },
    deleteUserSuccess: (state, action) => {
      const _index = state.usersData.data?.findIndex(
        (row) => row.id === action.payload
      );
      if (_index !== -1) state.usersData.data.splice(_index, 1);

      state.delAccId = -1;
    },
    error: (state, action) => {
      state.loading = false;
      state.delAccId = -1;
      state.updateAccId = -1;
      state.error = action.payload.message || "Internal Server Error";
    },
  },
});

export const actions = adminSlice.actions;

export default adminSlice.reducer;
