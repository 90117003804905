import axios from "utils/axios";
import { actions } from "./reducer";
import { tryit, last, title } from "radash";
import { API_BASE_URL, INVITE_TYPES } from "context/constants";
import { getToken, toRaw, showCustomToast, handleSessionExpired } from "utils";
import { saveToken } from "utils";

export const login = (payload) => async (dispatch) => {
  dispatch(actions.login());
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_BASE_URL}/auth/`,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {
      username: payload.email,
      password: payload.password,
    },
  };
  const [error, resp] = await tryit(axios.request)(config);

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 404) {
      errResp.message = "Incorrect email or password, please try again";
    } else if (errResp.status === 403) {
      errResp.message = "Account is inactive";
    }
    showCustomToast(errResp.message, { isSuccess: false });
    dispatch(actions.error(errResp));
  } else dispatch(actions.loginSuccess(resp.data));
};

export const signUp = (payload) => async (dispatch) => {
  dispatch(actions.signUp());
  const [error, resp] = await tryit(axios.post)("/auth/sign_up", payload);

  if (error) {
    const errResp = toRaw(error);
    const errors = error.response?.data?.detail;
    if (errResp.status === 422) {
      errResp.message = "Email already exists";
    } else if (Array.isArray(errors) && errors.length) {
      errResp.message = errors
        .map(
          (error) =>
            `${title(last(error.loc))} ${error.msg
              .replace(/String should|Password should/, "should")
              .replace("Value error, ", "")}`
        )
        .join("\n");
    }
    showCustomToast(errResp.message, { isSuccess: false });
    dispatch(actions.error(errResp));
  } else {
    dispatch(actions.signUpSuccess(resp.data));
    showCustomToast("Verification code sent in email successfully.");
  }
};

export const registerUser = (isExisting, payload) => async (dispatch) => {
  dispatch(actions.registerUser());
  const authKey = getToken();
  const [error, resp] = await tryit(axios.put)(
    `/users${isExisting ? "" : "/register_user"}`,
    payload,
    { headers: authKey ? { Authorization: `Bearer ${authKey}` } : {} }
  );

  if (error) {
    const errResp = toRaw(error);
    const errors = error.response?.data?.detail;
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    if (Array.isArray(errors) && errors.length) {
      errResp.message = errors
        .map(
          (error) =>
            `${title(last(error.loc))} ${error.msg
              .replace(/String should|Password should/, "should")
              .replace("Value error, ", "")}`
        )
        .join("\n");
    }
    showCustomToast(errResp.message, { isSuccess: false });
    dispatch(actions.error(errors));
  } else {
    dispatch(actions.registerUserSuccess(resp.data));
    showCustomToast("User data saved successfully.");
  }
};

export const forgotPassword = (payload) => async (dispatch) => {
  dispatch(actions.forgotPassword());
  const [error, resp] = await tryit(axios.post)(
    "/auth/forgot_password",
    payload
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status >= 500) {
      dispatch(actions.error(errResp));
      showCustomToast("An internal server error occurred.", { isSuccess: false });
    } else {
      dispatch(actions.forgotPasswordSuccess({}));
      showCustomToast("Forgot password request failed.", { isSuccess: false });
    }
  } else {
    dispatch(actions.forgotPasswordSuccess(resp.data));
    showCustomToast("Password reset email sent successfully.");
  }
};

export const resendInvite = (payload) => async (dispatch) => {
  dispatch(actions.resendInvite());
  const [error, resp] = await tryit(axios.get)("/auth/request_invite", {
    params: payload,
  });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 404) errResp.message = "Email not found";
    dispatch(actions.error(errResp));
    showCustomToast(errResp.message, { isSuccess: false });
  } else {
    dispatch(actions.resendInviteSuccess(resp.data));
    if (resp.data.message.includes("Please wait for")) return;

    showCustomToast(
      `${
        payload.invite_type === INVITE_TYPES.reset
          ? "Password reset email"
          : "Confirmation code"
      } resent successfully.`
    );
  }
};

export const verifyInvite = (payload) => async (dispatch) => {
  dispatch(actions.verifyInvite());
  const [error, resp] = await tryit(axios.post)("/auth/verify_invite", payload);

  if (error) {
    const errResp = toRaw(error);
    if ([404, 400].includes(errResp.status))
      errResp.message = `Invalid ${
        payload.invite_type === INVITE_TYPES.email ? "code" : "token"
      }`;
    showCustomToast(errResp.message, { isSuccess: false });
    dispatch(actions.error(errResp));
  } else {
    dispatch(actions.verifyInviteSuccess(resp.data));
    if (window.location.pathname.includes("sign-up")) {
      showCustomToast("Your account has been confirmed!");
    }
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  dispatch(actions.resetPassword());
  const [error, resp] = await tryit(axios.post)(
    "/auth/reset_password",
    payload
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 404) errResp.message = "Email not found";
    showCustomToast(errResp.message, { isSuccess: false });
    dispatch(actions.error(errResp));
  } else {
    dispatch(actions.resetPasswordSuccess(resp.data));
    showCustomToast("Password updated successfully");
  }
};

export const startTrial = () => async (dispatch) => {
  dispatch(actions.startTrial());
  const authKey = getToken();

  const [error, resp] = await tryit(axios.put)(
    "/payments/start_trial",
    {},
    { headers: authKey ? { Authorization: `Bearer ${authKey}` } : {} }
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 403) errResp.message = "User already subscribed";
    showCustomToast(errResp.message, { isSuccess: false });
    dispatch(actions.error(errResp));
  } else {
    dispatch(actions.startTrialSuccess(resp.data));
    saveToken(resp.data.token);
    showCustomToast("Trial started");
  }
};

export const resetAuthErr = () => (dispatch) => dispatch(actions.resetErr());

export const setAuthErr = (err) => (dispatch) => dispatch(actions.error(err));

export const resetUserData = (err) => (dispatch) =>
  dispatch(actions.removeUserData(err));

export const resetFormsAndErrors = () => (dispatch) => {
  dispatch(actions.resetForms());
  dispatch(actions.resetErr());
};

export const createCheckoutSession = (priceId) => async (dispatch) => {
  dispatch(actions.checkoutSession());
  const [error, resp] = await tryit(axios.post)(
    "/payments/create_checkout_session",
    { price_id: priceId }
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.checkoutSessionSuccess(resp.data));
};

export const createPortalSession = () => async (dispatch) => {
  dispatch(actions.portalSession());
  const [error, resp] = await tryit(axios.post)("/payments/portal_session");

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.portalSessionSuccess(resp.data));
};

export const refreshToken = (token) => async (dispatch) => {
  dispatch(actions.refreshToken());
  const [error, resp] = await tryit(axios.get)(
    `/users/refresh_token?token=${token}`
  );

  if (error) {
    const errResp = toRaw(error);
    dispatch(actions.error(errResp));

    showCustomToast("Invalid token", { isSuccess: false });
    window.location.href = "/authentication/sign-in";
  } else dispatch(actions.refreshTokenSuccess(resp.data));
};

export const resetResendSecs = () => (dispatch) =>
  dispatch(actions.resetResendSecs());

export const fetchUserInfo = () => async (dispatch) => {
  dispatch(actions.fetchUserInfo());
  const [error, resp] = await tryit(axios.get)("/users");

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.fetchUserInfoSuccess(resp.data));
};
