import axios from "utils/axios";
import { actions } from "./reducer";
import { tryit } from "radash";
import { showCustomToast, toRaw, handleSessionExpired } from "utils";

export const fetchUsers = (params) => async (dispatch) => {
  dispatch(actions.getUsers());
  const [error, resp] = await tryit(axios.get)("/admin/get_users", { params });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getUsersSuccess(resp.data));
};

export const updateUser = (userId, body) => async (dispatch) => {
  dispatch(actions.updateUser(userId));
  const [error, resp] = await tryit(axios.put)(
    `/admin/update_user/${userId}`,
    body
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.updateUserSuccess(resp.data));
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch(actions.deleteUser(userId));
  const [error] = await tryit(axios.delete)(`/admin/delete_user/${userId}`);

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    } else if (errResp.status === 403) {
      errResp.message = "Deletion is not allowed";
      showCustomToast(errResp.message, { autoClose: 1200 });
    } else showCustomToast("Internal Server Error", { isSuccess: false });

    dispatch(actions.error(errResp));
  } else dispatch(actions.deleteUserSuccess(userId));
};
