import axios from "utils/axios";
import axiosLib from "axios";
import { actions } from "./reducer";
import { actions as lenderActions } from "../lender/reducer";
import { tryit } from "radash";
import moment from "moment";
import {
  showCustomToast,
  downloadFile,
  handleSessionExpired,
  toRaw,
  apiAction,
} from "utils";

const CancelToken = axiosLib.CancelToken;
let cancelFetchTransactions;

export const fetchTransactions = (params) => async (dispatch) => {
  dispatch(actions.getTransactions());
  if (cancelFetchTransactions) {
    cancelFetchTransactions();
  }
  const source = CancelToken.source();
  cancelFetchTransactions = source.cancel;
  const [error, resp] = await tryit(axios.get)("/transactions", {
    params,
    cancelToken: source.token,
  });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    if (!axiosLib.isCancel(error))
      showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getTransactionsSuccess(resp.data));
};

export const deleteTransaction = (transactionId) => (dispatch) => {
  dispatch(actions.deleteTransaction());
  return apiAction(
    tryit(axios.delete)(`/transactions/${transactionId}`),
    () => dispatch(actions.deleteTransactionSuccess(transactionId)),
    (errResp) => {
      dispatch(actions.error(errResp));
      showCustomToast("Internal Server Error", { isSuccess: false });
    }
  );
};

export const fetchMakers = () => async (dispatch) => {
  dispatch(actions.getMakers());
  const [error, resp] = await tryit(axios.get)("/transactions/makers");

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getMakersSuccess(resp.data));
};

export const fetchModels = (params) => async (dispatch) => {
  const newParams = { ...params }
  if (newParams.make) newParams.per_page = 500

  dispatch(actions.getModels());
  Object.entries(newParams).forEach(
    ([key, value]) =>
      newParams.hasOwnProperty(key) &&
      !value &&
      value !== false &&
      delete newParams[key]
  );

  const [error, resp] = await tryit(axios.get)("/transactions/models", {
    params: newParams,
  });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getModelsSuccess(resp.data.data));
};

export const fetchManufacturerYears = (params) => async (dispatch) => {
  const newParams = { ...params }
  if (newParams.make) newParams.per_page = 500

  dispatch(actions.getManufacturerYears());
  const [error, resp] = await tryit(axios.get)(
    "/transactions/manufacture_years",
    { params: newParams }
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getManufacturerYearsSuccess(resp.data.data));
};

let cancelFetchTransactionsStats;

export const fetchTransactionsStats = (params) => async (dispatch) => {
  dispatch(actions.getTransactionStats());
  if (cancelFetchTransactionsStats) {
    cancelFetchTransactionsStats();
  }
  const sourceStats = CancelToken.source();
  cancelFetchTransactionsStats = sourceStats.cancel;
  const [error, resp] = await tryit(axios.get)("/transactions/stats", {
    params,
    cancelToken: sourceStats.token,
  });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    if (!axiosLib.isCancel(error))
      showCustomToast("Internal Server Error", { isSuccess: false });
  } else dispatch(actions.getTransactionStatsSuccess(resp.data));
};

export const requestTransactionsReport = (params) => async (dispatch) => {
  dispatch(actions.requestTransactionsReport());
  const [error, resp] = await tryit(axios.get)("/transactions/request_report", {
    params,
  });

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    showCustomToast("Internal Server Error", { isSuccess: false });
  } else {
    dispatch(actions.requestTransactionsReportSuccess(resp.data));
  }
};

export const uploadTransactionsReport = (payload) => (dispatch) => {
  dispatch(actions.uploadTransactionsReport());
  const formData = new FormData();
  formData.append("uploaded_file", payload);
  return apiAction(
    tryit(axios.post)("/transactions/upload_file", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
    (resp) => {
      dispatch(actions.uploadTransactionsReportSuccess(resp.data));
    },
    (errResp) => {
      dispatch(actions.error(errResp));
      const msgMapping = {
        422: "Invalid file format",
        409: "File have duplicate records",
        503: "We're unable to process your request at the moment. Please try again shortly",
      };
      showCustomToast(msgMapping[errResp.status] || "Internal Server Error", { isSuccess: false });
    }
  );
};

export const downloadTransactionsReport = (reportId) => async (dispatch) => {
  const [error, resp] = await tryit(axios.get)(
    `/transactions/download_report/${reportId}`,
    { responseType: "blob" }
  );

  if (error) {
    const errResp = toRaw(error);
    if (errResp.status === 401) {
      handleSessionExpired();
      return;
    }
    dispatch(actions.error(errResp));
    dispatch(actions.resetReportId());
    showCustomToast("Report export failed", { isSuccess: false });
  } else if (resp.data.type === "text/csv") {
    downloadFile(resp.data, `report_${moment().unix()}.csv`);
    dispatch(actions.resetReportId());
  }
};

export const fetchTaskStatus = (taskId) => (dispatch) => {
  dispatch(actions.resetError());

  return apiAction(
    tryit(axios.get)(`/transactions/task_status/${taskId}`),
    (resp) => {
      const { data } = resp;
      if (data.error) {
        showCustomToast(data.error.replace(/database/g, "system", { isSuccess: false }));
        dispatch(actions.resetReportId());
        dispatch(actions.error({ message: data.error }));
      }
      if (data.message === "File Processed Successfully") {
        dispatch(actions.resetReportId());
        showCustomToast(data.message);
        dispatch(lenderActions.toggleDropZone());
      }
    },
    (errResp) => {
      dispatch(actions.error(errResp));
      dispatch(actions.resetReportId());
      showCustomToast("Report upload failed", { isSuccess: false });
    }
  );
};

export const fetchEventAlerts = (transactionId) => async (dispatch) => {
  dispatch(actions.getEventAlerts());
  const [error, resp] = await tryit(axios.get)(`/transactions/events/${transactionId}`);
  if (error){
    dispatch(actions.error(error));
    dispatch(actions.setShowEventModal(false));
    showCustomToast("Issue while fetching event alerts");
  } else {
    dispatch(actions.getEventAlertsSuccess(resp.data));
  }
};

export const resetTransactionsData = () => (dispatch) =>
  dispatch(actions.resetData());

export const setFirstLoad = () => (dispatch) =>
  dispatch(actions.setFirstLoad());

export const getTransactionStatsSuccess = (data) => (dispatch) =>
  dispatch(actions.getTransactionStatsSuccess(data));

export const setStatsLoading = (data) => (dispatch) => dispatch(actions.setStatsLoading(data));
