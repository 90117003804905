import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import queryString from "query-string";
import { MODAL_STYLE } from "context/constants";
import { refreshToken } from "store/actions";

export default function TokenVerification() {
  const dispatch = useDispatch();
  const query = queryString.parse(window.location.search);

  useEffect(() => {
    if (query.success === "true" && query.token) {
      dispatch(refreshToken(query.token));
    }
  }, []);

  return (
    <Modal open={true}>
      <Box sx={MODAL_STYLE}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
        </Typography>
      </Box>
    </Modal>
  );
}
