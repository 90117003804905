export const transactionState = (state) => state.Transaction;

export const authState = (state) => state.Authentication;

export const adminState = (state) => state.Admin;

export const savedSearchState = (state) => state.SavedSearch;

export const planState = (state) => state.Plan;

export const lenderState = (state) => state.Lender;

export const globalState = (state) => state.Global;
