import { createSlice } from "@reduxjs/toolkit";
import { basicStarter, toggleBoolean } from "utils";

const initialState = {
  loading: false,
  error: null,
  searchStr: "",
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setSearchStr: (state, action) => {
      state.searchStr = action.payload;
    },
    error: (state, action) => {
      state.loading = false;
      state.error = action.payload.message || "Internal Server Error";
    },
  },
});

export const actions = globalSlice.actions;

export default globalSlice.reducer;
