import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import MDTypography from "components/MDTypography";
function TableCheckBox({ value, onClick }) {
  const isActive = value === "ACTIVE";

  return (
    <Grid xs={12} md={4}>
      <MDBox>
        {value && (
          <MDBox sx={{ ml: -1 }}>
            {" "}
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <Checkbox
                disabled={isActive}
                sx={{
                  cursor: "pointer",
                  "& .MuiSvgIcon-root": {
                    border: "1px solid #3C322D",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#C8B19C",
                  },
                }}
                checked={isActive}
                onClick={isActive ? null : onClick}
              />
              <MDTypography
                fontSize="14px"
                sx={{ color: "#FFFFFF", fontWeight: 600 }}
              >
                Active
              </MDTypography>
              <Checkbox
                sx={{
                  cursor: "pointer",
                  "& .MuiSvgIcon-root": {
                    border: "1px solid #3C322D",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#C8B19C",
                  },
                }}
                checked={!isActive}
                onClick={isActive ? onClick : null}
              />
              <MDTypography
                fontSize="14px"
                sx={{ color: "#FFFFFF", fontWeight: 600 }}
              >
                Deactive
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </Grid>
  );
}

// Typechecking props for the DefaultCell
TableCheckBox.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TableCheckBox;
