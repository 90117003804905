import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  showCustomToast,
  basicStarter,
  isFalse,
  saveToken,
  decodeJwt,
  delToken,
  getUserStep,
} from "utils";

const initialState = {
  userData: {},
  curUserInfo: {},
  loading: false,
  error: null,
  resendSecs: 0,
  verificationText: "",
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login: (state) => {
      basicStarter(state);
      state.loginForm = false;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      const authKey = action.payload.access_token;
      const userData = (state.userData = decodeJwt(authKey));

      if (isFalse(userData.is_user_verified)) {
        saveToken(authKey, "signUpToken");
        state.loginForm = true;
        state.verificationForm = true;
      } else if (
        userData.is_user_verified &&
        isFalse(userData.is_user_registered)
      ) {
        saveToken(authKey, "signUpToken");
        state.loginForm = true;
        state.verificationText = "Token verified";
      } else if (userData.is_user_verified && userData.is_user_registered) {
        const isAdmin = userData.role === "admin";
        if (
          !isAdmin &&
          isFalse(userData.is_subscribed) &&
          (!userData.trial_end ||
            moment(userData.trial_end).isSameOrBefore(moment()))
        ) {
          saveToken(authKey, "signUpToken");
          state.loginForm = true;
          state.registerForm = true;
        } else if (
          isAdmin ||
          userData.is_subscribed ||
          (userData.trial_end && moment(userData.trial_end).isAfter(moment()))
        ) {
          state.loginForm = false;

          if (userData.status !== "ACTIVE") {
            showCustomToast(
              "Your account has been deactivated. Please contact support@aic.com", { isSuccess: false }
            );
            return;
          }
          saveToken(authKey);
          delToken("signUpToken");

          state.userTrialText = isAdmin
            ? "Admin authorized"
            : "User authorized";
        }
      }
    },
    signUp: (state) => {
      basicStarter(state);
      state.verificationForm = false;
    },
    signUpSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.verificationForm = true;
    },
    registerUser: (state) => {
      basicStarter(state);
      state.registerForm = false;
    },
    registerUserSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.registerForm = true;
    },
    forgotPassword: (state) => {
      basicStarter(state);
      state.resendForm = false;
    },
    forgotPasswordSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.resendForm = true;
    },
    resendInvite: (state) => {
      basicStarter(state);
      state.resendInviteLoading = true;
    },
    resendInviteSuccess: (state, action) => {
      state.loading = false;
      state.resendInviteLoading = false;
      if (action.payload.message.includes("Please wait for")) {
        const remSecs = parseInt(
          action.payload.message.match(/wait for (\d+) seconds/)?.[1]
        );
        state.resendSecs = remSecs || 60;
      } else state.resendSecs = 60;

      state.userData = action.payload;
    },
    verifyInvite: (state) => {
      basicStarter(state);
      state.verificationText = "Checking verification token";
    },
    verifyInviteSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      saveToken(action.payload.token, "signUpToken");
      state.verificationText = "Token verified";
    },
    resetPassword: basicStarter,
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.verificationText = "Password updated";
    },
    startTrial: (state) => {
      state.error = null;
      state.trialLoading = true;
      state.subscriptionText = "Requesting trial";
    },
    startTrialSuccess: (state, action) => {
      state.trialLoading = false;
      state.userData = action.payload;
      state.subscriptionText = "Trial started";
    },
    checkoutSession: basicStarter,
    checkoutSessionSuccess: (state, action) => {
      state.loading = false;
      const url = action.payload.data?.checkout_url;
      if (url) window.location.href = url;
    },
    fetchUserInfo: basicStarter,
    fetchUserInfoSuccess: (state, action) => {
      state.loading = false;
      state.curUserInfo = action.payload.data;
    },
    portalSession: basicStarter,
    portalSessionSuccess: (state, action) => {
      state.loading = false;
      const url = action.payload.data?.portal_url;
      if (url) window.location.href = url;
    },
    refreshToken: basicStarter,
    resetResendSecs: (state) => {
      state.resendSecs = 0;
    },
    refreshTokenSuccess: (state, action) => {
      state.loading = false;
      saveToken(action.payload.token);
      const userStep = getUserStep();
      if (userStep < 5) {
        window.location.href = "/authentication/sign-in";
        delToken();
      } else {
        window.location.href = "/";
        delToken("signUpToken");
      }
    },
    error: (state, action) => {
      state.loading = false;
      state.resendInviteLoading = false;
      state.trialLoading = false;
      state.verificationText = "";
      state.subscriptionText = "";
      state.error = action.payload.message || "Internal Server Error";
    },
    resetErr: (state) => {
      state.error = null;
    },
    removeUserData: (state) => {
      state.userData = {};
    },
    resetForms: (state) => {
      state.loginForm = false;
      state.verificationForm = false;
      state.registerForm = false;
      state.verificationText = "";
      state.subscriptionText = "";
      state.userTrialText = "";
      state.curUserInfo = {};
      state.resendForm = false;
    },
  },
});

export const actions = authenticationSlice.actions;

export default authenticationSlice.reducer;
