import { Country, State } from "country-state-city";
import { env, tempApiUrl } from "utils";

export const API_BASE_URL = env.REACT_APP_API_BASE_URL || tempApiUrl();

const COUNTRIES_NAMES = Country.getAllCountries().reduce((obj, country) => {
  obj[country.isoCode] = country.name;
  return obj;
}, {});

export const COUNTRY_CODES = Country.getAllCountries().map(country => {
  const phoneCode = country.phonecode;
  return phoneCode.startsWith('+') ? phoneCode : `+${phoneCode}`;
});

export const STATES_BY_COUNTRY = State.getAllStates().reduce((obj, state) => {
  if (!obj[state.countryCode]) obj[state.countryCode] = [];

  obj[state.countryCode].push({ label: state.name, value: state.name });
  return obj;
}, {});

export const ALL_COUNTRIES = Object.keys(STATES_BY_COUNTRY).map((isoCode) => ({
  label: COUNTRIES_NAMES[isoCode],
  value: isoCode,
}));
export const UnitedStates = ALL_COUNTRIES.find(
  (country) => country.value === "US"
);

export const TITLE = [
  "Select One",
  "Lender",
  "Broker",
  "Buyer",
  "Seller",
  "Investor",
  "Analyst",
  "Insurer",
];

export const SUB_TYPES_FOR_ADMIN = [
  "Broker Demo",
  "Lender Demo",
  "Lender Plus Demo",
  "Cancelled",
];
export const SUB_TYPES = [
  "Lender",
  "Broker",
  "Lender Plus",
  ...SUB_TYPES_FOR_ADMIN,
];

export const INVITE_TYPES = {
  reset: "reset_password",
  email: "email_verification",
};

export const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 100,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export const DATE_FORMAT = "YYYY-MM-DD";

export const SHOW_DATE_FORMAT = "MM/DD/YYYY";

export const MONTH_FORMAT = "MM/YYYY";

export const ROLES = { adm: "admin", mem: "member" };
