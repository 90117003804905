import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import MDBox from "components/MDBox";
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";
import { useMaterialUIController } from "context";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDTypography from "components/MDTypography";
import { showCustomToast } from "utils";
import { uploadTransactionsReport, fetchTaskStatus } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { transactionState } from "store/states";
import { fetchTransactions } from "store/actions";

function TransitionsModal({ loading, reportId }) {
  const isLoading = loading || reportId;
  const getModalText = () => {
    if (loading) return "Uploading ";
    else if (reportId) return "Processing ";
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isLoading}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Fade in={isLoading}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              py: 3,
              border: "none",
              borderRadius: "12px",
              width: { xs: "370px", md: "520px" },
              outline: "none",
            }}
          >
            <MDBox px={3}>
              <MDTypography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#2C241C",
                  fontSize: { xs: "18px", md: "24px" },
                  fontWeight: 700,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                }}
              >
                {getModalText()}
                <CircularProgress color="inherit" sx={{ ml: 1 }} size={20} />
              </MDTypography>
            </MDBox>
          </MDBox>
        </Fade>
      </Modal>
    </div>
  );
}

function MDDropzone({ options, loading }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [selectedFile, setSelectedFile] = useState();
  const { uploadReportId, error } = useSelector(transactionState);
  const dropzoneRef = useRef();
  const dropZone = useRef();

  useEffect(() => {
    if (selectedFile) {
      dispatch(uploadTransactionsReport(selectedFile));
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFile && dropZone.current) {
      dropZone.current.removeFile(selectedFile);
    }
  }, [error]);

  useEffect(() => {
    let interval;
    if (uploadReportId) {
      interval = setInterval(() => {
        dispatch(fetchTaskStatus(uploadReportId));
      }, 3000);
    }
    return () => {
      if (!interval) return;

      clearInterval(interval);
      dispatch(fetchTransactions({ page: 1, per_page: 10 }));
    };
  }, [uploadReportId]);

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const dropzone = new Dropzone(dropzoneRef.current, options);
      dropzone.on("addedfile", (file) => {
        if (!file.name.endsWith(".csv")) {
          dropzone.removeFile(file);
          showCustomToast("Only CSV files are allowed.", { autoClose: 1500, isSuccess: false });
        } else setSelectedFile(file);
      });
      dropzone.on("maxfilesexceeded", (file) => {
        dropzone.removeFile(file);
        showCustomToast("File Limit exceeded!", { autoClose: 1500, isSuccess: false });
      });

      dropZone.current = dropzone;
    }

    function removeDropzone() {
      if (Dropzone.instances.length)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, []);

  return (
    <>
      <TransitionsModal loading={loading} reportId={!!uploadReportId} />
      <MDDropzoneRoot
        style={loading || uploadReportId ? { display: "none" } : {}}
        component="form"
        action="/file-upload"
        ref={dropzoneRef}
        className="form-control dropzone dropzone-centered-item"
        ownerState={{ darkMode }}
      >
        <MDBox className="fallback" bgColor="transparent">
          <MDBox component="input" name="file" type="file" />
        </MDBox>
      </MDDropzoneRoot>
    </>
  );
}

// Typechecking props for the MDDropzone
MDDropzone.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
};

export default MDDropzone;
