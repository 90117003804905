import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import PageLayout from "components/LayoutContainers/PageLayout";

function SimplePageLayout({ children }) {
  return (
    <>
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <PageLayout>
            <MDBox
              minHeight="100vh"
              overflowY="auto"
              sx={{
                position: "relative",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#C8B19C",
                  opacity: "81%",
                  zIndex: 0,
                },
              }}
            >
              <Grid
                container
                position="relative"
                zIndex={1}
                py={2}
                px={5}
              >
                {children}
              </Grid>
            </MDBox>
          </PageLayout>
        </Grid>
      </Grid>
    </>
  );
}

SimplePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SimplePageLayout;
