import { useEffect, useState } from "react";

import {
  useLocation,
  NavLink,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

import PropTypes from "prop-types";

import List from "@mui/material/List";
import Link from "@mui/material/Link";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavList from "components/Sidenav/SidenavList";
import SidenavItem from "components/Sidenav/SidenavItem";

import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";
import Caream_Box_Icon from "assets/Box_Icon.svg";
import Blue_Box_Icon from "assets/blue_box.svg";
import { getToken, decodeJwt, loggedInUser } from "utils";
import MDButton from "components/MDButton";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSavedSearches,
  setSaveSearch,
  deleteSearch,
  createPortalSession,
  toggleUpgradeModal,
} from "store/actions";
import { savedSearchState, authState } from "store/states";
import { isEmpty } from "radash";
import closeIcon from "assets/small_close.svg";
import { ROLES } from "context/constants";
import { Box } from "@mui/material";
import NavBarLogo from "assets/navBarLogo.svg";
import { isLenderPlus } from "utils";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const reduxDispatch = useDispatch();
  const { savedSearchData, curSaveSearch, delSearchId } =
    useSelector(savedSearchState);
  const { loading: subLoading } = useSelector(authState);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const savedSearchName = searchParams.get("saved_search");

  const curUserInfo = loggedInUser();
  const userRole = curUserInfo.role;
  const shouldPlanUpgrade =
    curUserInfo.job_title === "Lender" && !isLenderPlus();

  const [openCollapse, setOpenCollapse] = useState(false);
  const [rerender, setRerender] = useState(false);

  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  useEffect(() => {
    window.location.pathname !== "/authentication/sign-in" &&
      userRole &&
      !savedSearchData?.length &&
      reduxDispatch(fetchSavedSearches());

    const settingRouteIndex = routes.findIndex(
      (route) => route.key === "settings"
    );
    if (userRole !== ROLES.adm && settingRouteIndex !== -1) {
      const route = routes[settingRouteIndex];
      route.collapse = [
        {
          name: "Manage Subscription",
          key: "manage_subscription",
          route: "/",
          component: <></>,
          onClick: () => reduxDispatch(createPortalSession()),
        },
      ];
    }
  }, []);

  useEffect(() => {
    setRerender(!rerender);
  }, [delSearchId, savedSearchData]);

  useEffect(() => {
    const settingsIndex = routes.findIndex((route) => route.key === "settings");

    const searchSavedSearchesIndex = routes.findIndex(
      (route) => route.key === "saved_searches"
    );
    const isAdmin = curUserInfo.role === "admin";

    if (isAdmin && settingsIndex !== -1) {
      routes.splice(settingsIndex, 1);
    }
    routes[searchSavedSearchesIndex].hideInMenu =
      !isAdmin &&
      curUserInfo.job_title === "Lender" &&
      searchSavedSearchesIndex !== -1;
  }, []);

  useEffect(() => {
    const searchRouteIndex = routes.findIndex(
      (route) => route.key === "saved_searches"
    );

    if (searchRouteIndex !== -1) {
      if (
        isEmpty(curSaveSearch) &&
        savedSearchName &&
        savedSearchData?.length
      ) {
        const foundSearch = savedSearchData.find(
          (search) => search.name === savedSearchName
        );
        if (foundSearch) reduxDispatch(setSaveSearch(foundSearch));
        else if (window.location.pathname === "/") {
          navigate("/", { replace: true });
        }
      }
      const route = routes[searchRouteIndex];
      if (Array.isArray(savedSearchData)) {
        const nestedRoutes = savedSearchData.map((savedSearch) => ({
          name: savedSearch.name,
          key: savedSearch.id,
          route: "/",
          component: <></>,
          onClick: () => reduxDispatch(setSaveSearch(savedSearch)),
          deleteAction: () => reduxDispatch(deleteSearch(savedSearch.id)),
        }));
        route.collapse = nestedRoutes;
      }
    }
  }, [savedSearchData]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 800 ? false : transparentSidenav
      );
      setWhiteSidenav(dispatch, window.innerWidth < 800 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link key={key} href={href} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(
      ({ name, collapse, route, onClick, deleteAction, href, key }) => {
        let returnValue;

        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              color={color}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key &&
                currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link href={href} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName}
              />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName || curSaveSearch?.id === key}
                onClick={
                  onClick
                    ? () => {
                        onClick();
                        miniSidenav && setTimeout(() => {
                          closeSidenav();
                        }, 1000);
                      }
                    : () => null
                }

                deleteAction={deleteAction}
                loading={
                  delSearchId === key ||
                  (key === "manage_subscription" && subLoading)
                }
              />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      }
    );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes
    .filter(
      (route) =>
        !route.hideInMenu && (!route.roles || route.roles.includes(userRole))
    )
    .map(
      ({
        type,
        name,
        icon,
        title,
        identifier,
        collapse,
        noCollapse,
        key,
        href,
      }) => {
        let returnValue;

        if (type === "collapse") {
          const userObj = loggedInUser();
          const sideNavName =
            identifier === "logged_in_user"
              ? userObj.fullname ||
                userObj.email?.split?.("@")?.[0]?.toUpperCase() ||
                name
              : name;
          returnValue = href ? (
            <Link href={href} key={key} sx={{ textDecoration: "none" }}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                onClick={() => {
                  setTimeout(() => {
                    closeSidenav();
                  }, 1000);
                }}
              />
            </Link>
          ) : (
            <SidenavCollapse
              key={key}
              name={sideNavName}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <MDBox
              sx={{
                position: "relative",
                margin: "0.5rem",
                display: { xs: "none", md: "block" },
              }}
            >
              <hr key={key} className="divider" />
              <div className="dot-style-right"></div>
            </MDBox>
          );
        } else if (type === "dividerleft") {
          returnValue = (
            <MDBox
              sx={{
                position: "relative",
                margin: "0.5rem",
                display: { xs: "none", md: "block" },
              }}
            >
              <hr key={key} className="divider" />
              <div className="dot-style-left"></div>{" "}
            </MDBox>
          );
        }

        return returnValue;
      }
    );

  if (!userRole) return <></>;

  return (
    <MDBox
      sx={{
        width: miniSidenav ? "100%" : "240px",
        backgroundColor: "#E2CDBB",
        height: "100vh",
        position: "fixed",
        top: { xs: 0, sm: "72px" },
        padding: { sm: "0", md: "16px" },
        zIndex: miniSidenav && 5,
        display: {xs: miniSidenav ? "block" : "none", md : "block" }
      }}
      className={`${miniSidenav && "side-navbar"}`}
    >
      <MDBox
        textAlign="center"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={miniSidenav ? "16px" : "12px 16px"}
      >
        {miniSidenav && (
          <>
            <MDBox
              component={NavLink}
              to="/"
              display="flex"
              alignItems="center"
            >
              <MDBox
                width={!brandName && "100%"}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
              >
                <img
                  src={NavBarLogo}
                  style={{ display: "block", margin: "auto" }}
                />
              </MDBox>
            </MDBox>
            <MDBox
              display={{ xs: "flex", md: "none" }}
              onClick={closeSidenav}
              sx={{
                cursor: "pointer",
                height: "40px",
                width: "40px",
                backgroundColor: "#3C322D1A",
                borderRadius: "4px",
              }}
            >
              <img
                src={closeIcon}
                style={{
                  width: "20px",
                  height: "20px",
                  display: "block",
                  margin: "auto",
                }}
              />
            </MDBox>
          </>
        )}
      </MDBox>
      <List
        className="font-responsive"
        sx={
          miniSidenav && {
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }
        }
      >
        {renderRoutes}
      </List>
      {shouldPlanUpgrade && userRole !== ROLES.adm && (
        <MDBox
          sx={{
            position: "absolute",
            bottom: 72,
            width: { xs: "100%", sm: "max-content" },
            padding: { xs: "12px 16px", sm: "12px 16px 12px 40px" },
          }}
        >
          <MDButton
            className="no-hover-color-change"
            variant="gradient"
            color="baseColor"
            sx={{
              color: "#F2F7F2",
              fontSize: "14px",
              width: { xs: "100%" },
              borderRadius: "4px",
              textTransform: "none",
            }}
            onClick={() => reduxDispatch(toggleUpgradeModal(true))}
          >
            Upgrade Now!
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,
};

export default Sidenav;
