import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  delSearchId: -1,
  savedSearchData: [],
  curSaveSearch: {},
};

const savedSearchSlice = createSlice({
  name: "saved_search",
  initialState,
  reducers: {
    getSaveSearches: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSaveSearchesSuccess: (state, action) => {
      state.loading = false;
      state.savedSearchData = action.payload;
    },
    createSaveSearch: (state) => {
      state.loading = true;
      state.error = null;
    },
    createSaveSearchSuccess: (state, action) => {
      state.loading = false;
      state.curSaveSearch = action.payload;
      state.savedSearchData = [action.payload].concat(state.savedSearchData);
    },
    deleteSaveSearch: (state, action) => {
      state.delSearchId = action.payload;
      state.loading = true;
      state.error = null;
    },
    deleteSaveSearchSuccess: (state, action) => {
      const _index = state.savedSearchData?.findIndex(
        (row) => row.id === action.payload
      );
      if (_index !== -1) state.savedSearchData?.splice(_index, 1);

      state.loading = false;
      state.delSearchId = -1;
    },
    setSavedSearch: (state, action) => {
      state.curSaveSearch = action.payload;
    },
    error: (state, action) => {
      state.delSearchId = -1;
      state.loading = false;
      state.error = action.payload.message || "Internal Server Error";
    },
  },
});

export const actions = savedSearchSlice.actions;

export default savedSearchSlice.reducer;
