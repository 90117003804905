import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import LenderCardComponent from "components/LenderCardComponent";
import { useDispatch, useSelector } from "react-redux";
import { createCheckoutSession, resetFormsAndErrors } from "store/actions";
import { useNavigate } from "react-router-dom";
import { LocalActivity, Search, Email } from "@mui/icons-material";

const authState = (state) => state.Authentication;

function LenderCard({ prices, onClick }) {
  const navigate = useNavigate();
  const basicPrice = prices.find((price) => price.amount === 89) || {};
  const plusPrice = prices.find((price) => price.amount === 99) || {};
  const [selectedPlan, setSelectedPlan] = useState({});
  const dispatch = useDispatch();
  const { loading, error, subscriptionText } = useSelector(authState);

  const lenderData = [
    {
      icon: (
        <Search
          sx={{ width: "16px", height: "16px", marginRight: "5px" }}
          color="white"
        />
      ),
      title: "Aircraft Loan Identification",
      description: "Enhance Financial Oversight and Risk Management",
    },
    {
      icon: (
        <Email
          sx={{ width: "16px", height: "16px", marginRight: "5px" }}
          color="white"
        />
      ),
      title: "Monthly Email Notifications",
      description: "Check-in and Upload Aircraft Files to Stay on Track",
    },
  ];
  const lenderPlusData = [
    {
      icon: (
        <LocalActivity
          sx={{ width: "16px", height: "16px", marginRight: "5px" }}
          color="white"
        />
      ),
      title: "Above the Market",
      description:
        "Automatically Calculate and View Average Aircraft Prices Based on your Uploads",
    },
  ];

  const gotoLogin = (url) => {
    setTimeout(
      () => navigate(url || "/authentication/sign-in", { replace: true }),
      1000
    );
  };

  useEffect(() => {
    if (subscriptionText === "Trial started") {
      setTimeout(() => {
        dispatch(resetFormsAndErrors());
        gotoLogin("/");
      }, 1200);
    }
  }, [subscriptionText]);

  useEffect(() => {
    if (error) {
      gotoLogin();
    }
  }, [error]);

  return (
    <>
      <MDBox zIndex={10}>
        <Grid container mb={{ xs: 0, sm: 2 }} justifyContent="space-between">
          <Grid
            xs={12}
            sm={6}
            sx={{
              width: "100%",
            }}
          >
            <LenderCardComponent
              boxData={lenderData}
              size="large"
              badge={{ label: "Lender" }}
              price={{
                currency: "$",
                value: basicPrice.amount || 89,
                type: "mo",
              }}
              action={{
                onClick: () => {
                  setSelectedPlan(basicPrice);
                  onClick
                    ? onClick()
                    : dispatch(createCheckoutSession(basicPrice.price_id));
                },
                loading:
                  loading && selectedPlan.price_id === basicPrice.price_id,
                label: "Join Lender",
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            sx={{
              my: { xs: 2, sm: 0 },
              width: "100%",
            }}
          >
            <LenderCardComponent
              boxData={lenderPlusData}
              size="large"
              badge={{ label: "Lender Plus" }}
              price={{
                currency: "$",
                value: plusPrice.amount || 99,
                type: "mo",
              }}
              action={{
                onClick: () => {
                  setSelectedPlan(plusPrice);
                  onClick
                    ? onClick()
                    : dispatch(createCheckoutSession(plusPrice.price_id));
                },
                loading:
                  loading && selectedPlan.price_id === plusPrice.price_id,
                label: "Join Lender Plus",
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Typechecking props for the PricingCards
LenderCard.propTypes = {
  prices: PropTypes.instanceOf(Array).isRequired,
};

export default LenderCard;
