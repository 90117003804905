import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import SinglePageLayout from "layouts/SinglePageLayout";
import { Grid, Link } from "@mui/material";
import MDInput from "components/MDInput";
import eyeImg from "assets/Eye.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuthErr, resetPassword } from "store/actions";
import { MODAL_STYLE } from "context/constants";
import { verifyInvite, resetFormsAndErrors } from "store/actions";
import { showCustomToast } from "utils";
import { passwordValidate } from "utils";
import { INVITE_TYPES } from "context/constants";
import { West } from "@mui/icons-material";

const initialState = {
  password: false,
  cpassword: false,
};

const authState = (state) => state.Authentication;

function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { loading, error, verificationText } = useSelector(authState);
  const [resetToken, encodedEmail] = ["reset_token", "email"].map((key) =>
    searchParams.get(key)
  );

  const [passwordVisible, setPasswordVisible] = useState(initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errors, setErrors] = useState({
    password: "",
    cpassword: "",
  });

  const refInput = useRef({ password: null, cpassword: null });

  const togglePasswordVisibility = (passwordField) => {
    setPasswordVisible((oldState) => ({
      ...oldState,
      [passwordField]: !oldState[passwordField],
    }));
  };

  const gotoLogin = () => {
    setTimeout(
      () => navigate("/authentication/sign-in", { replace: true }),
      1000
    );
  };

  useEffect(() => {
    const markErr = () => {
      showCustomToast("Invalid link", { isSuccess: false });
      dispatch(setAuthErr({ message: "Invalid link" }));
      setIsModalOpen(true);
    };
    try {
      if (encodedEmail && resetToken) {
        const decodedEmail = atob(encodedEmail);
        dispatch(
          verifyInvite({
            email: decodedEmail,
            invite_link: resetToken,
            invite_type: INVITE_TYPES.reset,
          })
        );
        setIsModalOpen(true);
      } else {
        markErr();
      }
    } catch (error) {
      markErr();
    }
  }, [resetToken]);

  useEffect(() => {
    if (error) {
      setIsModalOpen(true);
      gotoLogin();
    }
  }, [error]);

  useEffect(() => {
    if (verificationText === "Token verified") {
      setTimeout(() => {
        setIsModalOpen(false);
        dispatch(resetFormsAndErrors());
      }, 500);
    } else if (verificationText === "Password updated") {
      gotoLogin();
      setTimeout(() => {
        dispatch(resetFormsAndErrors());
      }, 500);
    }
  }, [verificationText]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorsObj = validate({ password, cpassword });
    setErrors(errorsObj);
    if (Object.keys(errorsObj).length) {
      if (!password) refInput.current.password.focus();
      else if (!cpassword) refInput.current.cpassword.focus();
      return null;
    }

    if (password !== cpassword) {
      setErrors((oldState) => ({
        ...oldState,
        cpassword: "Passwords do not match",
      }));
    } else {
      setErrors({ password: "", cpassword: "" });

      dispatch(
        resetPassword({
          email: atob(encodedEmail),
          invite_link: resetToken,
          password,
        })
      );
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e;
    setErrors((oldState) => ({
      ...oldState,
      [field]: "",
    }));
    if (field === "password") {
      setPassword(value);
    } else if (field === "cpassword") {
      setCPassword(value);
    }
  };

  const validate = (values) => {
    const stateErrors = {};
    if (!values.password) {
      stateErrors.password = "Please enter a password";
    } else if (!passwordValidate(values.password)) {
      stateErrors.password =
        "Password must be 8 characters or more, including alphanumeric and special characters.";
      showCustomToast(stateErrors.password, { autoClose: 1200, isSuccess: false });
    }
    if (!values.cpassword) {
      stateErrors.cpassword = "Please enter a confirm password";
    }

    return stateErrors;
  };

  return (
    <SinglePageLayout>
      <Card
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          top: "20px",
          left: "16px",
        }}
      >
        <MDBox>
          <Link to="/authentication/sign-in">
            <MDBox
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <West sx={{ width: "16px", height: "16px", color: "#3C322D" }} />
              <MDTypography
                sx={{
                  color: "#3C322D",
                  textTransform: "none",
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Back to Login
              </MDTypography>
            </MDBox>
          </Link>
        </MDBox>
      </Card>
      <Modal open={isModalOpen}>
        <Box sx={MODAL_STYLE}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
          </Typography>
        </Box>
      </Modal>
      <Grid
        mx={{ sm: "auto" }}
        maxWidth={{ xs: "100%", sm: "446px" }}
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px", md: "30px" },
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <MDBox>
            <img
              src="https://15-rocks-resource.s3.amazonaws.com/15-rock-revised-logo.svg"
              style={{
                height: "120px",
                width: "245px",
              }}
            />
          </MDBox>
          <MDBox textAlign="center">
            <MDTypography
              variant="h4"
              color="#3B3229"
              sx={{ fontWeight: 400 }}
              fontSize="16px"
            >
              Enter a new password different from the <br /> previously used
              ones.
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              maxWidth: "446px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <MDBox>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                mb="10px"
              >
                New Password
              </MDTypography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MDInput
                  inputRef={(ref) =>
                    (refInput.current = { ...refInput.current, password: ref })
                  }
                  variant="standard"
                  type={passwordVisible.password ? "text" : "password"}
                  placeholder="Type Here"
                  fullWidth
                  value={password}
                  onChange={(e) => handleInputChange(e.target, "password")}
                  className={
                    errors.password !== "" ? "redBorder" : "transparentBorder"
                  }
                />
                <img
                  src={eyeImg}
                  onClick={() => togglePasswordVisibility("password")}
                  style={{
                    cursor: "pointer",
                    zIndex: 999,
                    position: "absolute",
                    right: "5%",
                  }}
                />
              </div>
            </MDBox>
            <MDBox>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 700 }}
                color="#3B3229"
                fontSize="14px"
                mb="10px"
              >
                Confirm Password
              </MDTypography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MDInput
                  inputRef={(ref) =>
                    (refInput.current = { ...refInput.current, cpassword: ref })
                  }
                  variant="standard"
                  type={passwordVisible.cpassword ? "text" : "password"}
                  placeholder="Type Here"
                  fullWidth
                  value={cpassword}
                  onChange={(e) => handleInputChange(e.target, "cpassword")}
                  className={
                    errors.cpassword !== "" ? "redBorder" : "transparentBorder"
                  }
                />
                <img
                  src={eyeImg}
                  onClick={() => togglePasswordVisibility("cpassword")}
                  style={{
                    cursor: "pointer",
                    zIndex: 999,
                    position: "absolute",
                    right: "5%",
                  }}
                />
              </div>
              <MDTypography
                variant="h2"
                sx={{ fontWeight: 400 }}
                color="#3B3229"
                fontSize="10px"
                mt="10px"
              >
                Must 12 character long minimum
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox>
            <MDBox component="form" role="form">
              <MDBox>
                <MDButton
                  variant="gradient"
                  className="no-hover-color-change"
                  color="baseColor"
                  sx={{
                    textTransform: "none",
                    padding: "13px 20px",
                    borderRadius: "4px",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                  onClick={handleSubmit}
                >
                  Reset Password
                  {loading && !isModalOpen ? (
                    <CircularProgress size={20} sx={{ ml: 1 }} color="white" />
                  ) : null}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </SinglePageLayout>
  );
}

export default Basic;
