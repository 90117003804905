import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Modal, Backdrop, Fade } from "@mui/material";
import MDButton from "components/MDButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  p: 3,
  border: "none",
  borderRadius: "12px",
  outline: "none",
};

const UserPrompt = ({ isOpen, onClose, onSubmit, isSubs = false }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
        style={{ backdropFilter: "blur(5px)" }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <MDBox sx={style}>
            <MDTypography
              variant="h4"
              id="transition-modal-description"
              sx={{
                mb: 4,
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                fontFamily: "Poppins, sans-serif",
                color: "#3C322D",
                textAlign: "center",
              }}
            >
              Are you sure you want to {isSubs ? "change" : "delete"}?
            </MDTypography>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={6}>
                <MDBox>
                  <MDButton
                    variant="gradient"
                    className="no-hover-color-change"
                    color="baseColor"
                    size="medium"
                    onClick={onSubmit}
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      width: "100%",
                      height: "42px",
                      pr: 0,
                      pl: 0,
                      borderRadius: "4px",
                    }}
                  >
                    Yes
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox>
                  <MDButton
                    variant="gradient"
                    color="baseColorSecondary"
                    size="medium"
                    onClick={onClose}
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      width: "100%",
                      height: "42px",
                      pr: 0,
                      pl: 0,
                      borderRadius: "4px",
                    }}
                  >
                    No
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Fade>
      </Modal>
    </div>
  );
};

export default UserPrompt;
