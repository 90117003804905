import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check"; // Import CheckIcon

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#3C322D",
      fontSize: "50px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#3C322D",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#3C322D",
    width: "100%",
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#968069",
  borderRadius: "50%",
  zIndex: 1,
  ...(ownerState.active && {
    color: "#3B3229",
    backgroundColor: "#3B3229",
    borderRadius: "50%",
    width: 16,
    height: 16,
    zIndex: 1,
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "white",
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "#3B3229",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .QontoStepIcon-circle": {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon">
          <CheckIcon sx={{ height: "12px", width: "12px" }} />{" "}
        </div>
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: "#3B3229 !important",
      fontSize: "13px",
      fontWeight: "400 !important",
    },
    [`&.${stepLabelClasses.active}`]: {
      color: "#3B3229 !important",
      fontSize: "13px",
      fontWeight: "700 !important",
    },
    color: "#786654",
    fontSize: "13px",
    fontWeight: 300,
  },
}));

const CustomStepper = ({ activeStep, labels }) => {
  return (
    <Stepper
      activeStep={activeStep}
      sx={{
        background: "transparent",
        boxShadow: "none",
        pt: 2,
      }}
      className="custom-label"
      alternativeLabel
      connector={<QontoConnector />}
    >
      {labels.map((label) => (
        <Step key={label}>
          <ColorlibStepLabel StepIconComponent={QontoStepIcon}>
            {label}
          </ColorlibStepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
